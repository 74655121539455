import {
  ArrowDownwardOutlined,
  ArrowUpwardOutlined,
  FacebookSharp,
  PlayCircleOutline,
  ScheduleSend,
} from "@mui/icons-material";
import {
  Box,
  Button,
  CardMedia,
  Typography,
  Grid,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import React from "react";

const AddCampaign = ({ data, screenWidth, state, setState }) => {
  const [hide, setHide] = React.useState(
    localStorage.getItem("hide_broadcast_header") ? true : false
  );
  return (
    <div>
      <Box p={2} bgcolor={"white"} borderRadius={2}>
        {hide ? (
          <Stack direction={"row"} spacing={2}>
            <Button
              onClick={() => setState({ ...state, dialogAdd: true })}
              fullWidth
              startIcon={<FacebookSharp />}
              sx={{
                boxShadow: 0,
                borderRadius: 2,
                maxWidth: screenWidth > 893 ? 300 : "100%",
              }}
              variant="contained"
            >
              {data.addNewTemplet}
            </Button>

            <Tooltip title={data.expandThis}>
              <IconButton
                onClick={() => {
                  setHide(false);
                  // localStorage.removeItem("hide_broadcast_header");
                  // window.location.reload();
                }}
              >
                <ArrowDownwardOutlined />
              </IconButton>
            </Tooltip>
          </Stack>
        ) : (
          <Grid
            container
            spacing={4}
            alignItems={"center"}
            direction={"row"}
            justifyContent={"space-between"}
          >
            <Grid xs={12} sm={12} md={6} lg={6} item>
              <Stack direction={"column"} spacing={3}>
                <CardMedia
                  sx={{ height: "100%", width: 200 }}
                  src="/assets/broadcast_header.svg"
                  component={"img"}
                />
                <Typography color={"gray"} variant="body2">
                  {data.addBroadcastDes}
                </Typography>

                <Stack direction={"row"} spacing={2}>
                  <Button
                    onClick={() => setState({ ...state, dialogAdd: true })}
                    fullWidth
                    startIcon={<ScheduleSend />}
                    sx={{
                      boxShadow: 0,
                      borderRadius: 2,
                      maxWidth: screenWidth > 893 ? 300 : "100%",
                    }}
                    variant="contained"
                  >
                    {data.addNewBroadcast}
                  </Button>

                  <Tooltip title={data.hideThis}>
                    <IconButton
                      onClick={() => {
                        setHide(true);
                        // localStorage.setItem('hide_broadcast_header', "YES")
                        // window.location.reload()
                      }}
                    >
                      <ArrowUpwardOutlined />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Stack>
            </Grid>

            <Grid xs={12} sm={12} md={6} lg={6} item>
              <Stack alignItems={"center"}>
                <Tooltip title={data.playTutorial}>
                  <Box
                    sx={{ cursor: "pointer" }}
                    p={4}
                    bgcolor={"#F3F3F3"}
                    borderRadius={"50%"}
                  >
                    <PlayCircleOutline
                      sx={{ height: 50, width: 50, color: "gray" }}
                    />
                  </Box>
                </Tooltip>
              </Stack>
            </Grid>
          </Grid>
        )}
      </Box>
    </div>
  );
};

export default AddCampaign;
