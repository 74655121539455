import {
  ArrowDownwardOutlined,
  ArrowUpwardOutlined,
  FacebookSharp,
  PlayCircleOutline,
} from "@mui/icons-material";
import {
  Box,
  Button,
  CardMedia,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import AddTempletDialog from "./AddTempletDialog";

const AddTemplet = ({
  setMenu,
  menu,
  state,
  setState,
  data,
  screenWidth,
  getTemplets,
}) => {
  const [hide, setHide] = React.useState(
    localStorage.getItem("hide_templet_header") ? true : false
  );
  return (
    <div>
      <Box p={2} bgcolor={"white"} borderRadius={2}>
        {hide ? (
          <Stack direction={"row"} spacing={2}>
            <Button
              onClick={() => setState({ ...state, addDialog: true })}
              fullWidth
              startIcon={<FacebookSharp />}
              sx={{
                boxShadow: 0,
                borderRadius: 2,
                maxWidth: screenWidth > 893 ? 300 : "100%",
              }}
              variant="contained"
            >
              {data.addNewTemplet}
            </Button>

            <Tooltip title={data.expandThis}>
              <IconButton
                onClick={() => {
                  setHide(false);
                  localStorage.removeItem("hide_templet_header");
                  //   localStorage.clear();
                  //   window.location.reload();
                }}
              >
                <ArrowDownwardOutlined />
              </IconButton>
            </Tooltip>
          </Stack>
        ) : (
          <Grid
            container
            spacing={4}
            alignItems={"center"}
            direction={"row"}
            justifyContent={"space-between"}
          >
            <Grid xs={12} sm={12} md={6} lg={6} item>
              <Stack direction={"column"} spacing={3}>
                <CardMedia
                  sx={{ height: "100%", width: 200 }}
                  src="/assets/create_templet.svg"
                  component={"img"}
                />
                <Typography color={"gray"} variant="body2">
                  {data.createTempletDes}
                </Typography>

                <Stack direction={"row"} spacing={2}>
                  <Button
                    onClick={() => setState({ ...state, addDialog: true })}
                    fullWidth
                    startIcon={<FacebookSharp />}
                    sx={{
                      boxShadow: 0,
                      borderRadius: 2,
                      maxWidth: screenWidth > 893 ? 300 : "100%",
                    }}
                    variant="contained"
                  >
                    {data.addNewTemplet}
                  </Button>

                  <Tooltip title={data.hideThis}>
                    <IconButton
                      onClick={() => {
                        setHide(true);
                        localStorage.setItem("hide_templet_header", "YES");
                        // window.location.reload()
                      }}
                    >
                      <ArrowUpwardOutlined />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Stack>
            </Grid>

            <Grid xs={12} sm={12} md={6} lg={6} item>
              <Stack alignItems={"center"}>
                <Tooltip title={data.playTutorial}>
                  <Box
                    sx={{ cursor: "pointer" }}
                    p={4}
                    bgcolor={"#F3F3F3"}
                    borderRadius={"50%"}
                  >
                    <PlayCircleOutline
                      sx={{ height: 50, width: 50, color: "gray" }}
                    />
                  </Box>
                </Tooltip>
              </Stack>
            </Grid>
          </Grid>
        )}
      </Box>

      <AddTempletDialog
        screenWidth={screenWidth}
        setMenu={setMenu}
        menu={menu}
        state={state}
        setState={setState}
        data={data}
        getTemplets={getTemplets}
      />
    </div>
  );
};

export default AddTemplet;
