import {
  Box,
  Dialog,
  Divider,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import CloseBtn from "../../../context/CloseBtn";
import TextFieldNew from "../../../components/TextFieldNew";
import { GlobalContext } from "../../../context/GlobalContext";
import { ContactPageOutlined, Send } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LoadingButton } from "@mui/lab";

const AddBroadDialog = ({ data, state, setState }) => {
  const [temp, setTemp] = React.useState({
    schedule: false,
    scheduleTimestamp: Date.now(),
  });
  const [contactData, setContactData] = React.useState([]);
  const [templets, setTemplets] = React.useState([]);
  const { hitAxios } = React.useContext(GlobalContext);
  const CON = React.useContext(GlobalContext);

  async function getContacts() {
    const res = await hitAxios({
      path: "/api/phonebook/get_by_uid",
      post: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.data.success) {
      setContactData(res.data.data);
    }
  }

  async function getTemplets() {
    const res = await hitAxios({
      path: "/api/user/get_my_meta_templets",
      post: true,
      admin: false,
      obj: { type: "BROADCAST" },
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.data.success) {
      const newArrMan = res.data?.data?.length > 0 ? res.data?.data : [];
      const findApproved = newArrMan?.filter((i) => i.status === "APPROVED");
      setTemplets(findApproved);
    }
  }

  async function addBroadcast() {
    const res = await hitAxios({
      path: "/api/broadcast/add_new",
      post: true,
      admin: false,
      obj: {
        title: temp.title,
        templet: temp.templet,
        phonebook: temp.phonebook,
        scheduleTimestamp: temp.scheduleTimestamp,
      },
    });
    if (res.data.success) {
      setState({ ...state, dialogAdd: false });
    }
  }

  React.useEffect(() => {
    getTemplets();
    getContacts();
  }, []);

  return (
    <Dialog
      PaperProps={{
        style: { borderRadius: 10 },
      }}
      fullWidth
      open={state.dialogAdd}
      onClose={() => setState({ ...state, dialogAdd: false })}
    >
      {" "}
      <Box p={2}>
        {CON.data.loading ? (
          <LinearProgress />
        ) : (
          <Stack direction={"column"} spacing={2}>
            <Stack
              alignItems={"center"}
              direction={"row"}
              justifyContent={"space-between"}
            >
              <Typography fontWeight={600}>{data.addNewBroadcast}</Typography>
              <CloseBtn
                onClick={() => setState({ ...state, dialogAdd: false })}
              />
            </Stack>

            <Divider />

            <TextFieldNew
              value={temp.title}
              onChange={(e) => setTemp({ ...temp, title: e.target.value })}
              label={data.broadcastTitle}
            />

            <FormControl fullWidth>
              <InputLabel size="small" id="templet">
                {data.selTemplet}
              </InputLabel>
              <Select
                sx={{ borderRadius: 2 }}
                size="small"
                labelId="templet"
                id="demo-simple-templet"
                label={data.selTemplet}
              >
                {templets.map((i, key) => {
                  return (
                    <MenuItem
                      onClick={() => setTemp({ ...temp, templet: i })}
                      key={key}
                      value={i?.name}
                    >
                      {i?.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel size="small" id="contact">
                {data.selPhonebook}
              </InputLabel>
              <Select
                sx={{ borderRadius: 2 }}
                size="small"
                labelId="contact"
                id="demo-simple-contact"
                label={data.selTemplet}
              >
                {contactData.map((i, key) => {
                  return (
                    <MenuItem
                      onClick={() => setTemp({ ...temp, phonebook: i })}
                      key={key}
                      value={i?.name}
                    >
                      <Stack
                        alignItems={"center"}
                        direction={"row"}
                        spacing={1}
                      >
                        <ContactPageOutlined
                          sx={{ height: 18, width: 18, color: "gray" }}
                        />
                        <Typography>{i?.name}</Typography>
                      </Stack>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <Stack alignItems={"center"} direction={"row"} spacing={1}>
              <Switch
                checked={temp.schedule}
                onChange={(e) =>
                  setTemp({ ...temp, schedule: e.target.checked })
                }
              />
              <Typography variant="body2" color={"gray"}>
                ⏱️ {data.schedule}
              </Typography>
            </Stack>

            {temp.schedule && (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  disablePast
                  renderInput={(props) => (
                    <TextField
                      size="small"
                      InputProps={{ style: { borderRadius: 10 } }}
                      {...props}
                    />
                  )}
                  label={data.selectDateAndTime}
                  value={temp.scheduleTimestamp}
                  onChange={(newValue) => {
                    setTemp({ ...temp, scheduleTimestamp: newValue });
                  }}
                />
              </LocalizationProvider>
            )}

            <LoadingButton
              loading={CON.data.loading}
              onClick={addBroadcast}
              startIcon={<Send />}
              variant="contained"
              sx={{ borderRadius: 2, boxShadow: 0 }}
            >
              {data.addBroadCast}
            </LoadingButton>
          </Stack>
        )}
      </Box>
    </Dialog>
  );
};

export default AddBroadDialog;
