import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const TotalEngagement = ({ data, totalEngagementData }) => {
  const [series, setState] = useState([0, 0, 0]);
  const options = {
    chart: {
      width: 380,
      type: "pie",
    },
    labels: ["USER", "BOT", "AGENT"],
    title: {
      text: "Total Engagement",
      align: "left",
    },
  };
  useEffect(() => {
    if (totalEngagementData.array.length != 0) {
      if (totalEngagementData.array[0]._id) {
        setState([
          totalEngagementData.array[1].count
            ? totalEngagementData.array[1].count
            : 0,
          totalEngagementData.array[0].count
            ? totalEngagementData.array[0].count
            : 0,
          totalEngagementData.totalOutgoingAgentMessages
            ? totalEngagementData?.totalOutgoingAgentMessages
            : 0,
        ]);
      } else {
        setState([
          totalEngagementData.array[0].count,
          totalEngagementData.array[1].count,
          totalEngagementData.totalOutgoingAgentMessages,
        ]);
      }
    }
  }, [totalEngagementData]);
  return (
    <Chart
      id="basic-line-chart"
      height={"100%"}
      width={"100%"}
      options={options}
      series={series}
      labels={{ name: "count", data: series }}
      type="pie"
    />
  );
};

export default TotalEngagement;
