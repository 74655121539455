import { SmartToy, AddCardOutlined } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import CustomToggleSwitch from "../../components/CustomToggleSwitch";
import BotInfoComponent from "./BotInfoComponent";
import PaymentModeComponent from "./PaymentModeComponent";

const ChatbotDetailsView = ({ data, state, setState, hitAxios, userData }) => {
  const [isBotInfoSelected, setIsBotInfoSelected] = React.useState(true);

  return (
    <div>
      <Box m={3} p={2} bgcolor={"white"} borderRadius={2}>
        <Stack direction={"column"} spacing={2}>
          <Stack direction={"row"} spacing={2} alignItems={"center"}>
            {isBotInfoSelected ? (
              <SmartToy sx={{ color: "#7C7C7C" }} />
            ) : (
              <AddCardOutlined sx={{ color: "#7C7C7C" }} />
            )}
            <Typography variant="h6" color={"#7C7C7C"} fontWeight={600}>
              {isBotInfoSelected ? "Add Custom QNA" : "Select Payment Modes"}
            </Typography>
            <CustomToggleSwitch
              sx={{ m: 1 }}
              checked={isBotInfoSelected}
              onChange={() => {
                setIsBotInfoSelected((prv) => !prv);
              }}
            />
            <Typography color={"#7C7C7C"}>
              Switch to{" "}
              {!isBotInfoSelected ? "Add Custom QNA" : "Select Payment Modes"}
            </Typography>
          </Stack>
          <Typography color={"#7C7C7C"}>
            {!isBotInfoSelected
              ? "* Here, you can add the payment methods you accept for orders generated by Ulai."
              : "* Here, you can add CSV file of Pre-Filled question and answer of about you company or products  "}
          </Typography>
          {isBotInfoSelected ? (
            <BotInfoComponent
              data={data}
              state={state}
              setState={setState}
              hitAxios={hitAxios}
              userData={userData}
            />
          ) : (
            <PaymentModeComponent
              data={data}
              state={state}
              setState={setState}
              hitAxios={hitAxios}
              userData={userData}
            />
          )}
        </Stack>
      </Box>
    </div>
  );
};

export default ChatbotDetailsView;
