import React from "react";

const CounterNumber = ({ number }) => {
  const [num, setNum] = React.useState(0);
  function animateValue(obj, start, end, duration) {
    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      if (number - Math.floor(number))
        setNum((progress * (end - start) + start).toFixed(2));
      else setNum(Math.floor(progress * (end - start) + start));
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  }

  React.useEffect(() => {
    animateValue({}, 0, number, 1000);
  }, [number]);
  return num ? Number(num).toLocaleString() : 0;
};

export default CounterNumber;
