import { ScheduleSend, SettingsOutlined } from "@mui/icons-material";
import { Box, CardActionArea, Stack, Typography } from "@mui/material";
import React from "react";
const SideBar = ({ data, screenWidth, setState, state, menu, setMenu }) => {
  return (
    <div>
      {screenWidth > 899 && (
        <Box width={250} minHeight={"92vh"} height={"100%"} bgcolor={"white"}>
          <Box p={1}>
            <Stack direction={"column"} spacing={2}>
              <CardActionArea
                onClick={() => setMenu({ ...menu, selected: "Configration" })}
                sx={{
                  p: 1.3,
                  borderRadius: 2,
                  bgcolor:
                    menu.selected === "Configration" ? "action.hover" : "",
                }}
              >
                <Stack direction={"row"} spacing={2} alignItems={"center"}>
                  <SettingsOutlined
                    sx={{ color: "gray", height: 20, width: 20 }}
                  />
                  <Typography
                    sx={{
                      color: (t) =>
                        menu.selected === "Configration"
                          ? t.palette.primary.main
                          : "",
                    }}
                    variant="body2"
                    fontWeight={600}
                  >
                    {"Configration"}
                  </Typography>
                </Stack>
              </CardActionArea>

              <CardActionArea
                onClick={() =>
                  setMenu({ ...menu, selected: "Action_Needed_Orders" })
                }
                sx={{
                  p: 1.3,
                  borderRadius: 2,
                  bgcolor:
                    menu.selected === "Action_Needed_Orders"
                      ? "action.hover"
                      : "",
                }}
              >
                <Stack direction={"row"} spacing={2} alignItems={"center"}>
                  <ScheduleSend sx={{ color: "gray", height: 20, width: 20 }} />
                  <Typography
                    sx={{
                      color: (t) =>
                        menu.selected === "A" ? t.palette.primary.main : "",
                    }}
                    variant="body2"
                    fontWeight={600}
                  >
                    {"Action Needed Orders"}
                  </Typography>
                </Stack>
              </CardActionArea>
              {/* <CardActionArea
                onClick={() => setMenu({ ...menu, selected: "History" })}
                sx={{
                  p: 1.3,
                  borderRadius: 2,
                  bgcolor: menu.selected === "History" ? "action.hover" : "",
                }}
              >
                <Stack direction={"row"} spacing={2} alignItems={"center"}>
                  <ScheduleSend sx={{ color: "gray", height: 20, width: 20 }} />
                  <Typography
                    sx={{
                      color: (t) =>
                        menu.selected === "History"
                          ? t.palette.primary.main
                          : "",
                    }}
                    variant="body2"
                    fontWeight={600}
                  >
                  History
                  </Typography>
                </Stack>
              </CardActionArea> */}
            </Stack>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default SideBar;
