import {
  Box,
  Dialog,
  Divider,
  Stack,
  Typography,
  TextField,
} from "@mui/material";
import Switch from "@mui/material/Switch";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { dateFormate } from "../../../../utils/DateFormatter";
import React, { useEffect, useState } from "react";
import CloseBtn from "../../../../context/CloseBtn";
import TextFieldNew from "../../../../components/TextFieldNew";
import { GlobalContext } from "../../../../context/GlobalContext";
import { LoadingButton } from "@mui/lab";
import { QueryBuilder, formatQuery } from "react-querybuilder";
import { QueryBuilderMaterial } from "@react-querybuilder/material";
import { fields } from "../fields";

const AddSegmentDialog = ({ getSegmentList, state, setState, data }) => {
  const [query, setQuery] = React.useState({ combinator: "and", rules: [] });
  const CON = React.useContext(GlobalContext);
  const [isViewOnly, setViewOnly] = React.useState(false);
  const [key, setKey] = useState(0); // Key to force re-mount
  const [states, setStates] = React.useState({
    isActive: true,
    from: "",
    to: "",
    name: "",
  });
  const myData = JSON.parse(localStorage.getItem("myData"));
  const saveSegment = async () => {
    if (!states.name || !states.from || !states.to || query.rules.length == 0) {
      CON.setData({
        ...CON.data,
        snack: true,
        snack_msg: "Please fill require details",
        snack_success: false,
      });
      return;
    }
    const res = await CON.hitAxios({
      path: "/api/segments/create",
      post: true,
      admin: false,
      obj: {
        ...states,
        actualQuery: query,
        startDate: new Date(states.from).toISOString(),
        endDate: new Date(states.to).toISOString(),
        orgId: myData?._id,
        query: formatQuery(query, "mongodb"),
        storeName: myData.store.name,
      },
    });
    if (res.data.success) {
      await getSegmentList();
      setState({ ...state, segmentDialog: false });
    }
  };
  useEffect(() => {
    const newQuery = { ...state.segmentData.actualQuery };
    setQuery(newQuery);
    if (state.segmentData && state.segmentData.actualQuery) {
      const newQuery = { ...state.segmentData.actualQuery };
      if (JSON.stringify(newQuery) !== JSON.stringify(query)) {
        setQuery(newQuery);
        setState({ ...state });
        console.log("Updated query:", newQuery);
        setKey((prevKey) => prevKey + 1); // Change key to force re-mount
      }
    }
    setStates(state.segmentData);
    setViewOnly(state.segmentData.isViewOnly);
  }, [state]);
  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={state.segmentDialog}
      onClose={() => setState({ ...state, segmentDialog: false })}
      PaperProps={{
        style: { borderRadius: 5, padding: "10px" },
      }}
    >
      <Box>
        <Stack mb={1} direction={"column"} spacing={3}>
          <Stack mb={1} direction={"column"} spacing={3} marginTop={"15px"}>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Box display={"flex"} alignItems={"center"} gap="30px">
                {" "}
                <>
                  {" "}
                  {/* <Typography fontWeight={600}>Add new segment</Typography> */}
                  <TextFieldNew
                    // fullWidth
                    value={states?.name}
                    onChange={(e) => {
                      CON.setData({
                        ...CON.data,
                        snack: false,
                        snack_msg: "",
                        snack_success: false,
                      });
                      setStates({ ...states, name: e.target.value });
                    }}
                    label="Name"
                  />
                </>
              </Box>
              <Box
                display={"flex"}
                gap="30px"
                alignItems={"center"}
                fontWeight={600}
              >
                <Typography>
                  Select Order placed date<sup style={{ color: "red" }}>*</sup>
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Box
                    components={["DatePicker", "DatePicker"]}
                    display={"flex"}
                    justifyContent={"flex-end"}
                    gap="5px"
                  >
                    <DatePicker
                      label="From"
                      inputFormat="YYYY-MM-DD"
                      renderInput={(props) => (
                        <TextField
                          size="small"
                          InputProps={{ style: { borderRadius: 10 } }}
                          {...props}
                        />
                      )}
                      value={states.from}
                      onChange={(newValue) => {
                        CON.setData({
                          ...CON.data,
                          snack: false,
                          snack_msg: "",
                          snack_success: false,
                        });
                        setStates({
                          ...states,
                          from: dateFormate(newValue.$d),
                        });
                        // getData(dateFormate(newValue.$d), date.endDate);
                      }}
                    />
                    <DatePicker
                      label="To"
                      inputFormat="YYYY-MM-DD"
                      renderInput={(props) => (
                        <TextField
                          size="small"
                          InputProps={{ style: { borderRadius: 10 } }}
                          {...props}
                        />
                      )}
                      value={states.to}
                      onChange={(newValue) => {
                        console.log("newValue.$d", newValue.$d);
                        CON.setData({
                          ...CON.data,
                          snack: false,
                          snack_msg: "",
                          snack_success: false,
                        });
                        setStates({
                          ...states,
                          to: dateFormate(newValue.$d),
                        });
                      }}
                    />
                  </Box>
                </LocalizationProvider>
                <CloseBtn
                  onClick={() => {
                    CON.setData({
                      ...CON.data,
                      snack: false,
                      snack_msg: "",
                      snack_success: false,
                    });
                    setState({ ...state, segmentDialog: false });
                  }}
                />
              </Box>
            </Stack>
          </Stack>
        </Stack>
        <Divider variant="middle" />
        <QueryBuilderMaterial>
          <Box borderRadius={1} bgcolor={"white"}>
            <Box marginTop={"10px"}>
              <QueryBuilder
                key={key} // Use key to force re-mount
                fields={fields}
                query={query}
                onQueryChange={setQuery}
                controlClassnames={{ queryBuilder: "queryBuilder-branches" }}
              />
            </Box>
            <Box display={"flex"} justifyContent={"center"} marginTop={"10px"}>
              {!isViewOnly && (
                <LoadingButton variant="contained" onClick={saveSegment}>
                  Create
                </LoadingButton>
              )}
            </Box>
          </Box>
        </QueryBuilderMaterial>
      </Box>
    </Dialog>
  );
};

export default AddSegmentDialog;
