import { HourglassTopOutlined } from "@mui/icons-material";
import React from "react";
import { LoadingButton } from "@mui/lab";
import { GlobalContext } from "../../../../context/GlobalContext";

const AddButton = ({ addTemplate }) => {
  const CON = React.useContext(GlobalContext);
  return (
    <div>
      <LoadingButton
        loading={CON.data?.loading}
        onClick={addTemplate}
        startIcon={<HourglassTopOutlined />}
        sx={{ boxShadow: 0, borderRadius: 2 }}
        fullWidth
        variant="contained"
      >
        {"Create"}
      </LoadingButton>
    </div>
  );
};

export default AddButton;
