import { Box } from "@mui/material";
import React from "react";
import TextMsg from "./TextMsg";
import ImageMsg from "./ImageMsg";
import AudioMsg from "./AudioMsg";
import DocMsg from "./DocMsg";
import VideoMsg from "./VideoMsg";
import TextMsgOutgoing from "./outgoing/TextMsgOutgoing";
import AudioMsgOutgoing from "./outgoing/AudioMsgOutgoing";
import DocMsgOutgoing from "./outgoing/DocMsgOutgoing";
import VideoMsgOutgoing from "./outgoing/VideoMsgOutgoing";
import ImageMsgOutgoing from "./outgoing/ImageMsgOutgoing";
import ButtonMsgOutgoing from "./outgoing/ButtonMsgOutgoing";
import ListMsgOutgoing from "./outgoing/ListMsgOutgoing";
import InteractiveMsgOutgoing from "./outgoing/InteractiveMsgOutgoing";

const OneChat = ({ i, data, chatConvo, key, bgColor, bgText }) => {
  return (
    <div style={{ background: bgColor }}>
      {i?.route === "INCOMING" && (
        <Box>
          {i?.type === "text" && (
            <TextMsg chatConvo={chatConvo} i={i} data={data} bgText={bgText} />
          )}
          {i?.type === "image" && (
            <ImageMsg chatConvo={chatConvo} i={i} data={data} bgText={bgText} />
          )}
          {i?.type === "audio" && (
            <AudioMsg chatConvo={chatConvo} i={i} data={data} bgText={bgText} />
          )}
          {i?.type === "document" && (
            <DocMsg chatConvo={chatConvo} i={i} data={data} bgText={bgText} />
          )}
          {i?.type === "video" && (
            <VideoMsg chatConvo={chatConvo} i={i} data={data} bgText={bgText} />
          )}
        </Box>
      )}

      {i?.route === "OUTGOING" && (
        <Box>
          {i?.type === "text" && (
            <TextMsgOutgoing
              chatConvo={chatConvo}
              i={i}
              data={data}
              outgoing={true}
              bgText={bgText}
            />
          )}
          {i?.type === "image" && (
            <ImageMsgOutgoing
              chatConvo={chatConvo}
              i={i}
              data={data}
              outgoing={true}
              bgText={bgText}
            />
          )}
          {i?.type === "audio" && (
            <AudioMsgOutgoing
              chatConvo={chatConvo}
              i={i}
              data={data}
              outgoing={true}
              bgText={bgText}
            />
          )}
          {i?.type === "document" && (
            <DocMsgOutgoing
              chatConvo={chatConvo}
              i={i}
              data={data}
              outgoing={true}
              bgText={bgText}
            />
          )}
          {i?.type === "video" && (
            <VideoMsgOutgoing
              chatConvo={chatConvo}
              i={i}
              data={data}
              outgoing={true}
              bgText={bgText}
            />
          )}
          {i?.type === "button" && (
            <ButtonMsgOutgoing
              chatConvo={chatConvo}
              i={i}
              data={data}
              outgoing={true}
              bgText={bgText}
            />
          )}
          {i?.type === "list" && (
            <ListMsgOutgoing
              chatConvo={chatConvo}
              i={i}
              data={data}
              outgoing={true}
              bgText={bgText}
            />
          )}
          {i?.type === "interactive" &&
            (i?.msgContext?.interactive?.type == "list" ? (
              <ListMsgOutgoing
                chatConvo={chatConvo}
                i={i}
                data={data}
                outgoing={true}
                bgText={bgText}
              />
            ) : (
              <InteractiveMsgOutgoing
                chatConvo={chatConvo}
                i={i}
                data={data}
                outgoing={true}
                bgText={bgText}
              />
            ))}
        </Box>
      )}
    </div>
  );
};

export default OneChat;
