import React, { useEffect } from "react";
import { Typography, Grid, Box } from "@mui/material";
import { GlobalContext } from "../../context/GlobalContext";
import CounterNumber from "../../components/CounterNumber";

function OrderSummary() {
  const [orderSummaryData, setOrderSummaryData] = React.useState([]);
  const userData = JSON.parse(localStorage.getItem("myData"));

  const G = React.useContext(GlobalContext);

  return (
    <Grid container spacing={5}>
      <Grid item xs={12} sm={12}>
        <Typography variant="h6" gutterBottom>
          Performance
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Box
          border="1px solid #d2d1d1"
          height="150px"
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography
            variant="body1"
            align="center"
            gutterBottom
            fontSize={"20px"}
          >
            Total Abandoned Amount
          </Typography>
          <Typography
            variant="body1"
            display="flex"
            align="center"
            fontSize={"45px"}
          >
            {/* ₹ {G.dashboardData["total-abandonedcart-amount"][0]?.totalSum?.toLocaleString() ?? 0} */}
            ₹{" "}
            <CounterNumber
              number={
                G.dashboardData["total-abandonedcart-amount"][0]?.totalSum
              }
            />
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Box
          border="1px solid #d2d1d1"
          height="150px"
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography
            variant="body1"
            align="center"
            gutterBottom
            fontSize={"20px"}
          >
            Total New Customers
          </Typography>
          <Typography variant="body1" align="center" fontSize={"45px"}>
            {/* {G.dashboardData["total-new-customer"][0]?.count?.toLocaleString() ?? 0} */}
            <CounterNumber
              number={G.dashboardData["total-new-customer"][0]?.count}
            />
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12} sm={4}>
        {/* <Typography variant="body1" align="center" gutterBottom>
          Total Order Value
        </Typography>
        <Typography variant="body1" align="center">
          {totalOrderValue?.toLocaleString() ?? 0}
        </Typography> */}
        <Box
          border="1px solid #d2d1d1"
          height="150px"
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography
            variant="body1"
            align="center"
            gutterBottom
            fontSize={"20px"}
          >
            Total Orders
          </Typography>
          <Typography variant="body1" align="center" fontSize={"45px"}>
            {/* {G.dashboardData["orders-till-now"][0]?.noOfOrders?.toLocaleString() ?? 0} */}
            <CounterNumber
              number={G.dashboardData["orders-till-now"][0]?.noOfOrders}
            />
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Box
          border="1px solid #d2d1d1"
          height="150px"
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography
            variant="body1"
            align="center"
            gutterBottom
            fontSize={"20px"}
          >
            Total Order Value
          </Typography>
          <Typography variant="body1" align="center" fontSize={"45px"}>
            {/* ₹ {G.dashboardData["orders-till-now"][0]?.totalOrderValue?.toLocaleString() ?? 0} */}
            ₹{" "}
            <CounterNumber
              number={G.dashboardData["orders-till-now"][0]?.totalOrderValue}
            />
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Box
          border="1px solid #d2d1d1"
          height="150px"
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography
            variant="body1"
            align="center"
            gutterBottom
            fontSize={"20px"}
          >
            Average Order Value
          </Typography>
          <Typography variant="body1" align="center" fontSize={"45px"}>
            {/* ₹ {G.dashboardData["orders-till-now"][0]?.averageOrderValue?.toLocaleString() ?? 0} */}
            ₹{" "}
            <CounterNumber
              number={G.dashboardData["orders-till-now"][0]?.averageOrderValue}
            />
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}

export default OrderSummary;
