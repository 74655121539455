import React from "react";
import { Box, CardActionArea, Chip, Stack, Typography } from "@mui/material";
import {
  FeaturedPlayList,
  FeaturedPlayListOutlined,
  FormatListNumbered,
  PlaylistAddOutlined,
  SmartToy,
  SmartToyOutlined,
  Toys,
} from "@mui/icons-material";
import SavedTemplet from "./SavedTemplet";
import CreateChatbot from "./CreateChatbot";
import { GlobalContext } from "../../context/GlobalContext";
import ChatbotList from "./ChatbotList";
import ChatbotDetailsView from "./ChatbotDetailsView";

const Chatbot = ({ data }) => {
  const [chatbotList, setChatbotList] = React.useState([]);
  const [selChat, setSelChat] = React.useState([]);
  const [selFlow, setSelFlow] = React.useState("");
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  const [chatFlow, setChatFlow] = React.useState([]);
  const [chats, serChats] = React.useState([]);
  const { hitAxios } = React.useContext(GlobalContext);
  const userData = JSON.parse(localStorage.getItem("myData"));

  const [state, setState] = React.useState({
    menuSelected: "BOT",
    for_all: false,
    add: false,
    botsView: "list",
  });

  async function getChatbots() {
    const res = await hitAxios({
      path: "/api/chatbot/get_chatbot",
      post: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.data.success) {
      setChatbotList(res.data.data);
    }
  }

  async function getChatFlow() {
    const res = await hitAxios({
      path: "/api/chat_flow/get_mine",
      post: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.data.success) {
      setChatFlow(res.data.data);
    }
  }

  async function getChats() {
    const res = await hitAxios({
      path: "/api/inbox/get_chats",
      post: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.data.success) {
      serChats(res.data.data);
    }
  }

  //   React.useEffect(() => {
  //     getChats();
  //     getChatFlow();
  //     getChatbots();
  //   }, []);

  React.useEffect(() => {
    const updateScreenWidth = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", updateScreenWidth);
    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);

  return (
    <div>
      <Stack direction={"row"}>
        {screenWidth > 1000 && (
          <Box
            p={1}
            width={200}
            bgcolor={"white"}
            minHeight={"100vh"}
            overflow={"auto"}
          >
            <Stack direction={"column"} spacing={2}>
              <CardActionArea
                onClick={() => setState({ ...state, menuSelected: "BOT" })}
                sx={{
                  p: 1.3,
                  borderRadius: 2,
                  bgcolor: state.menuSelected === "BOT" ? "action.hover" : "",
                }}
              >
                <Stack direction={"row"} spacing={2} alignItems={"center"}>
                  <SmartToyOutlined
                    sx={{ color: "gray", height: 20, width: 20 }}
                  />
                  <Typography
                    sx={{
                      color: (t) =>
                        state.menuSelected === "BOT"
                          ? t.palette.primary.main
                          : "",
                    }}
                    variant="body2"
                    fontWeight={600}
                  >
                    {data.chatBot}
                  </Typography>
                </Stack>
              </CardActionArea>

              {/* <CardActionArea
                onClick={() => setState({ ...state, menuSelected: "TEMPLET" })}
                sx={{
                  p: 1.3,
                  borderRadius: 2,
                  bgcolor:
                    state.menuSelected === "TEMPLET" ? "action.hover" : "",
                }}
              >
                <Stack direction={"row"} spacing={2} alignItems={"center"}>
                  <FeaturedPlayListOutlined
                    sx={{ color: "gray", height: 20, width: 20 }}
                  />
                  <Typography
                    sx={{
                      color: (t) =>
                        state.menuSelected === "TEMPLET"
                          ? t.palette.primary.main
                          : "",
                    }}
                    variant="body2"
                    fontWeight={600}
                  >
                    {data.savedTemplets}
                  </Typography>
                </Stack>
              </CardActionArea> */}
            </Stack>
          </Box>
        )}

        <Box width={"100%"}>
          {screenWidth < 999 && (
            <Box m={3}>
              <Stack direction={"row"} spacing={2} alignItems={"center"}>
                <Chip
                  icon={
                    <SmartToyOutlined
                      sx={{ color: "gray", height: 20, width: 20 }}
                    />
                  }
                  onClick={() => setState({ ...state, menuSelected: "BOT" })}
                  variant={state.menuSelected === "BOT" ? "filled" : "outlined"}
                  label={data.chatBot}
                />
                {/* <Chip
                  icon={
                    <FeaturedPlayListOutlined
                      sx={{ color: "gray", height: 20, width: 20 }}
                    />
                  }
                  onClick={() =>
                    setState({ ...state, menuSelected: "TEMPLET" })
                  }
                  variant={
                    state.menuSelected === "TEMPLET" ? "filled" : "outlined"
                  }
                  label={data.savedTemplets}
                /> */}
              </Stack>
            </Box>
          )}

          {state.menuSelected === "TEMPLET" && (
            <SavedTemplet data={data} screenWidth={screenWidth} />
          )}
          {state.menuSelected === "BOT" && (
            <CreateChatbot
              setSelChat={setSelChat}
              selChat={selChat}
              selFlow={selFlow}
              hitAxios={hitAxios}
              setSelFlow={setSelFlow}
              chatFlow={chatFlow}
              chats={chats}
              data={data}
              screenWidth={screenWidth}
              state={state}
              setState={setState}
              getChatbots={getChatbots}
              hideAddButton={userData.bots.length > 0}
            />
          )}

          {state.menuSelected === "BOT" &&
            state.botsView === "list" &&
            userData.bots.length > 0 && (
              <ChatbotList
                data={data}
                state={state}
                setState={setState}
                hitAxios={hitAxios}
                userData={userData}
              />
            )}
          {state.menuSelected === "BOT" && state.botsView === "details" && (
            <ChatbotDetailsView
              data={data}
              state={state}
              setState={setState}
              hitAxios={hitAxios}
              userData={userData}
            />
          )}
        </Box>
      </Stack>
    </div>
  );
};

export default Chatbot;
