import {
  Check,
  EmailOutlined,
  FaceOutlined,
  Fingerprint,
  MeetingRoom,
  Visibility,
  VisibilityOff,
  VpnKey,
  LanguageOutlined,
  ApartmentOutlined,
} from "@mui/icons-material";
import {
  Box,
  Checkbox,
  Container,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { TranslateContext } from "../context/TranslateContext";
import { GlobalContext } from "../context/GlobalContext";
import TextFieldNew from "../components/TextFieldNew";
import ButtonComp from "../context/ButtonComp";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Header from "../frontend/components/Header";
import UserLoginForget from "./UserLoginForget";
import OtpInputWithValidation from "../components/OtpInputWithValidation";
import CountryCodeDropDown from "../components/CountryCodeDropDown";

const UserLogin = () => {
  const { data } = React.useContext(TranslateContext);
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  const history = useHistory();
  const { hitAxios, state, setState } = React.useContext(GlobalContext);
  const CON = React.useContext(GlobalContext);
  // async function signUP() {
  //   const res = await hitAxios({
  //     path: "/api/user/signup",
  //     post: true,
  //     admin: false,
  //     obj: state,
  //   });
  //   // if (res.data.success) {
  //   setState({ ...state, isLogin: true });
  //   // }
  // }

  async function getOTPUsingEmail() {
    const res = await hitAxios({
      path: process.env.REACT_APP_BACKEND_BASE_URL + "/backend/auth/sendOtp",
      post: true,
      admin: false,
      obj: { email: state.email },
    });
    if (res.data.id) {
      setState({ ...state, validateOTP: true, requestId: res.data.id });
    }
  }

  async function validateOTPandSignUp() {
    const prepareReqObject = {
      id: state.requestId,
      otp: otp.join(""),
      companyName: state.companyName,
      name: state.name,
      lastName: state.lastName,
      email: state.email,
      password: state.password,
      mobile_with_country_code: state.mobile_with_country_code,
      mobileCodeObj: state?.mobileCodeObj,
      websiteLink: state.websiteLink,
      acceptPolicy: state.acceptPolicy,
      from: "frontend",
    };

    const res = await hitAxios({
      path: "/api/user/signup",
      post: true,
      admin: false,
      obj: prepareReqObject,
    });
    if (res.data.success) {
      setState({ ...state, isLogin: true });
      history.push("/user/login");
    }
  }

  React.useEffect(() => {
    const updateScreenWidth = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", updateScreenWidth);
    if (window && localStorage.getItem(process.env.REACT_APP_TOKEN + "_user")) {
      history.push("/user");
    }
    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);

  async function handleLogin() {
    const res = await hitAxios({
      path: "/api/user/login",
      post: true,
      admin: false,
      obj: state,
    });

    if (res.data.success) {
      localStorage.setItem(
        process.env.REACT_APP_TOKEN + "_user",
        res.data.token
      );
      localStorage.setItem(
        process.env.REACT_APP_TOKEN_ULAI + "_user",
        res.data.data.token
      );
      if (res.data.data.user) {
        localStorage.setItem(
          "myData",
          JSON.stringify({
            ...res.data.data.user,
            userType: res.data.data?.userType,
            allagent: res.data.data?.allagent ? res.data.data?.allagent : [],
          })
        );
      }
      history.push("/user");
    }
  }
  const [otp, setOtp] = React.useState(new Array(4).fill(""));

  const checkForAllValidEntryForLogin = () => {
    // email validation
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!regex.test(state?.email)) return true;

    // Password Validation
    if (!state.password) return true;

    return false;
  };
  const checkOTPValidEntry = () => {
    if (otp.join("").length != 4) {
      return true;
    }
  };
  const checkForAllValidEntryForRegistration = () => {
    // email validation
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!regex.test(state?.email)) return true;

    // Password Validation
    if (!state.password) return true;
    if (state?.password.length < 5) return true;

    // Name Validation
    if (!state.name) return true;

    // Phone validation
    if (
      !state.mobile_with_country_code ||
      !/^\d{12}$/.test(state.mobile_with_country_code)
    ) {
      return true;
    }
    // Company Name Validation
    if (!state.companyName) return true;

    // Website Validation
    // Test the URL against the regex pattern
    const urlRegex =
      /^(https?:\/\/)?([\w\-]+(\.[\w\-]+)+)([\/\w\-\.\?\&\=\%]*)?$/;
    if (!state.websiteLink || !urlRegex.test(state.websiteLink)) {
      return true;
    }

    // Policy Validation
    if (!state.acceptPolicy) return true;

    return false;
  };

  return (
    <Box
      sx={{
        background: "#f3fff7",
        background: "linear-gradient(to bottom right, #f3fff7, #fbfbfc)",
      }}
      minHeight={"100vh"}
      bgcolor={"#f1f1f1"}
    >
      <Header screenWidth={screenWidth} data={data} />

      <Container maxWidth="lg">
        <Box mb={4} mt={4}>
          <Grid
            spacing={6}
            container
            minHeight={"90vh"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Grid item xs={12} sm={6} lg={6}>
              <Container maxWidth="lg">
                <Stack direction={"column"}>
                  <Typography variant="h4" fontWeight={"bold"}>
                    {data.userLoginMsg}{" "}
                    <a style={{ color: data.color_theme }}>{data.whatsapp}</a>
                  </Typography>

                  <Stack spacing={2} direction={"column"} mt={4}>
                    <Stack spacing={1} alignItems={"center"} direction={"row"}>
                      <Check />
                      <Typography
                        fontWeight={500}
                        sx={{ color: (t) => "gray" }}
                      >
                        {data.point1}
                      </Typography>
                    </Stack>
                    <Stack spacing={1} alignItems={"center"} direction={"row"}>
                      <Check />
                      <Typography
                        fontWeight={500}
                        sx={{ color: (t) => "gray" }}
                      >
                        {data.point2}
                      </Typography>
                    </Stack>
                    <Stack spacing={1} alignItems={"center"} direction={"row"}>
                      <Check />
                      <Typography
                        fontWeight={500}
                        sx={{ color: (t) => "gray" }}
                      >
                        {data.point3}
                      </Typography>
                    </Stack>
                    <Stack spacing={1} alignItems={"center"} direction={"row"}>
                      <Check />
                      <Typography
                        fontWeight={500}
                        sx={{ color: (t) => "gray" }}
                      >
                        {data.point4}
                      </Typography>
                    </Stack>
                    <Stack spacing={1} alignItems={"center"} direction={"row"}>
                      <Check />
                      <Typography
                        fontWeight={500}
                        sx={{ color: (t) => "gray" }}
                      >
                        {data.point5}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Container>
            </Grid>

            <Grid item xs={12} sm={6} lg={6}>
              <Container maxWidth="lg">
                <Box
                  border={0.5}
                  borderColor={"#E1E1E1"}
                  bgcolor={"white"}
                  p={3}
                  borderRadius={4}
                >
                  <Stack direction={"column"} spacing={3}>
                    <Stack direction={"column"}>
                      <Typography fontWeight={"bold"} variant="h6">
                        {data.startYourTrial}
                      </Typography>
                      <Typography
                        fontWeight={"bold"}
                        variant="body2"
                        color={"gray"}
                      >
                        {data.starstYourTrialDes}
                      </Typography>
                    </Stack>

                    <Box display="flex" alignItems="center">
                      <Box
                        flex="1"
                        borderBottom={1}
                        borderColor="#E3E3E3"
                        marginRight={2}
                      />
                      <Typography
                        variant="body2"
                        fontWeight={500}
                        color={"gray"}
                      >
                        {data.loginOrSignup}
                      </Typography>
                      <Box
                        flex="1"
                        borderBottom={1}
                        borderColor="#E3E3E3"
                        marginLeft={2}
                      />
                    </Box>

                    {state.isLogin ? (
                      <Stack direction={"column"} spacing={2}>
                        <TextFieldNew
                          onChange={(e) =>
                            setState({ ...state, email: e.target.value })
                          }
                          startIcon={
                            <EmailOutlined
                              sx={{
                                color: (t) => t.palette.primary.main,
                                height: 20,
                                width: 20,
                              }}
                            />
                          }
                          label={data.yourEmail}
                        />

                        <TextFieldNew
                          onChange={(e) =>
                            setState({ ...state, password: e.target.value })
                          }
                          type={state.isPassEnter ? "password" : "text"}
                          // endIcon={
                          //   <IconButton
                          //     onClick={() =>
                          //       setState({
                          //         ...state,
                          //         isPassEnter: !state.isPassEnter,
                          //       })
                          //     }
                          //   >
                          //     {state.isPassEnter ? (
                          //       <Visibility />
                          //     ) : (
                          //       <VisibilityOff />
                          //     )}
                          //   </IconButton>
                          // }
                          startIcon={
                            <Fingerprint
                              sx={{
                                color: (t) => t.palette.primary.main,
                                height: 20,
                                width: 20,
                              }}
                            />
                          }
                          label={data.password}
                        />
                      </Stack>
                    ) : (
                      <Stack direction={"column"} spacing={2}>
                        {state.validateOTP ? (
                          <>
                            {" "}
                            <Typography>
                              We have sent an OTP to your email. Please check
                              your inbox.{" "}
                            </Typography>
                            <React.Fragment>
                              <OtpInputWithValidation
                                length={4}
                                onOtpSubmit={() => {}}
                                otp={otp}
                                setOtp={setOtp}
                              />
                            </React.Fragment>
                          </>
                        ) : (
                          <React.Fragment>
                            <TextFieldNew
                              onChange={(e) =>
                                setState({ ...state, email: e.target.value })
                              }
                              startIcon={
                                <EmailOutlined
                                  sx={{
                                    color: (t) => t.palette.primary.main,
                                    height: 20,
                                    width: 20,
                                  }}
                                />
                              }
                              label={data.yourEmail}
                            />

                            <TextFieldNew
                              type={state.isPassEnter ? "password" : "text"}
                              endIcon={
                                <IconButton
                                  onClick={() =>
                                    setState({
                                      ...state,
                                      isPassEnter: !state.isPassEnter,
                                    })
                                  }
                                >
                                  {state.isPassEnter ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              }
                              onChange={(e) =>
                                setState({ ...state, password: e.target.value })
                              }
                              startIcon={
                                <Fingerprint
                                  sx={{
                                    color: (t) => t.palette.primary.main,
                                    height: 20,
                                    width: 20,
                                  }}
                                />
                              }
                              helperText={
                                "The password must be longer than 6 characters."
                              }
                              label={data.password}
                            />

                            <TextFieldNew
                              onChange={(e) =>
                                setState({
                                  ...state,
                                  name: e.target.value,
                                })
                              }
                              startIcon={
                                <FaceOutlined
                                  sx={{
                                    color: (t) => t.palette.primary.main,
                                    height: 20,
                                    width: 20,
                                  }}
                                />
                              }
                              label="Name"
                            />

                            <CountryCodeDropDown
                              state={state}
                              setState={setState}
                              helperText={data.mobileHelper}
                              label={data.mobile}
                            />

                            <TextFieldNew
                              onChange={(e) =>
                                setState({
                                  ...state,
                                  companyName: e.target.value,
                                })
                              }
                              startIcon={
                                <ApartmentOutlined
                                  sx={{
                                    color: (t) => t.palette.primary.main,
                                    height: 20,
                                    width: 20,
                                  }}
                                />
                              }
                              label="Company Name"
                            />

                            <TextFieldNew
                              onChange={(e) =>
                                setState({
                                  ...state,
                                  websiteLink: e.target.value,
                                })
                              }
                              startIcon={
                                <LanguageOutlined
                                  sx={{
                                    color: (t) => t.palette.primary.main,
                                    height: 20,
                                    width: 20,
                                  }}
                                />
                              }
                              label="Website Link"
                              error={
                                state?.websiteLink
                                  ? !/^(https?:\/\/)?([\w\-]+(\.[\w\-]+)+)([\/\w\-\.\?\&\=\%]*)?$/.test(
                                      state?.websiteLink
                                    )
                                  : false
                              }
                            />

                            <Stack
                              alignItems={"center"}
                              direction={"row"}
                              spacing={0}
                            >
                              <Checkbox
                                onChange={(e) =>
                                  setState({
                                    ...state,
                                    acceptPolicy: e.target.checked,
                                  })
                                }
                                checked={state.acceptPolicy}
                              />
                              <Typography variant="caption" color={"gray"}>
                                {data.byClickPrivacy}{" "}
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    window.open("/view/privacy-policy")
                                  }
                                >
                                  {data.privacyPlicy}
                                </a>
                                ,{" "}
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    window.open("/view/terms-and-conditions")
                                  }
                                >
                                  {data.termsCondition}
                                </a>
                              </Typography>
                            </Stack>
                          </React.Fragment>
                        )}
                      </Stack>
                    )}
                  </Stack>

                  {state.isLogin ? (
                    <Stack mt={4}>
                      <ButtonComp
                        onClick={handleLogin}
                        bgcolor={checkForAllValidEntryForLogin() && "#22a454a6"}
                        disabled={checkForAllValidEntryForLogin()}
                        startIcon={<VpnKey />}
                        title={data.login}
                      />
                    </Stack>
                  ) : (
                    <Stack mt={4}>
                      <ButtonComp
                        onClick={() => {
                          state.validateOTP
                            ? validateOTPandSignUp()
                            : getOTPUsingEmail();
                          setOtp(new Array(4).fill(""));
                        }}
                        bgcolor={
                          (state.validateOTP
                            ? checkOTPValidEntry()
                            : checkForAllValidEntryForRegistration()) &&
                          "#22a454a6"
                        }
                        disabled={
                          state.validateOTP
                            ? checkOTPValidEntry()
                            : checkForAllValidEntryForRegistration()
                        }
                        startIcon={<MeetingRoom />}
                        title={state.validateOTP ? "Verify" : data.signUp}
                      />
                    </Stack>
                  )}

                  {state.isLogin && (
                    <UserLoginForget
                      CON={CON}
                      state={state}
                      data={data}
                      hitAxios={hitAxios}
                    />
                  )}

                  {state.isLogin ? (
                    <Stack alignItems={"center"} mt={3}>
                      <Typography
                        color={"gray"}
                        fontWeight={700}
                        variant="body2"
                      >
                        {data.dontHaveAnAccount}
                        <a
                          onClick={() =>
                            setState({ ...state, isLogin: !state.isLogin })
                          }
                          style={{ color: data.color_theme, cursor: "pointer" }}
                        >
                          {" "}
                          {data.signUp}
                        </a>
                      </Typography>
                    </Stack>
                  ) : (
                    <Stack alignItems={"center"} mt={3}>
                      {state.validateOTP ? (
                        <>
                          {" "}
                          <Typography>
                            Didn't received otp?
                            <a
                              onClick={() =>
                                setState({
                                  ...state,
                                  isLogin: !state.isLogin,
                                  validateOTP: false,
                                })
                              }
                              style={{
                                color: data.color_theme,
                                cursor: "pointer",
                              }}
                            >
                              {" "}
                              {data.login}
                            </a>
                          </Typography>
                        </>
                      ) : (
                        <Typography
                          color={"gray"}
                          fontWeight={700}
                          variant="body2"
                        >
                          {data.havingAndAccountAlready}
                          <a
                            onClick={() =>
                              setState({ ...state, isLogin: !state.isLogin })
                            }
                            style={{
                              color: data.color_theme,
                              cursor: "pointer",
                            }}
                          >
                            {" "}
                            {data.login}
                          </a>
                        </Typography>
                      )}
                    </Stack>
                  )}
                </Box>
              </Container>
            </Grid>
          </Grid>
        </Box>
      </Container>

      {/* <Footer screenWidth={screenWidth} data={data} /> */}
    </Box>
  );
};

export default UserLogin;
