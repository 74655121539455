import { Box } from "@mui/material";
import React from "react";
import PlanComponent from "../../components/PlanComponent";

const PlanList = ({ data, state, setState, getPlan, planList }) => {
  return (
    <Box mt={4}>
      <PlanComponent admin={true} state={state} setState={setState} />
    </Box>
  );
};

export default PlanList;
