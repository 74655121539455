import { KeyOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  CardActionArea,
  CardMedia,
  Stack,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import ConversationalApi from "./ConversationalApi";
import { GlobalContext } from "../../context/GlobalContext";

const LeftSide = ({
  setMenu,
  menu,
  state,
  setState,
  data,
  screenWidth,
  hitAxios,
}) => {
  const { getMyDetails } = useContext(GlobalContext);
  const [profile, setProfile] = React.useState({});

  async function genAPI() {
    const res = await hitAxios({
      path: "/api/user/generate_api_keys",
      post: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.data.success) {
      getProfile();
    }
  }

  async function getProfile() {
    // const res = await hitAxios({
    //   path: "/api/user/get_me",
    //   post: true,
    //   admin: false,
    //   obj: {
    //     email: JSON.parse(localStorage.getItem("myData"))?.email,
    //   },
    //   token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    // });
    // if (res.data.success) {
    //   setProfile({ ...res.data.data });
    // }
  }

  React.useEffect(() => {
    getProfile();
  }, []);

  return (
    <div>
      <Box borderRadius={2} bgcolor={"white"} m={2} p={2}>
        <Stack alignItems={"center"} direction={"column"} spacing={1}>
          <CardMedia
            sx={{ height: "100%", width: 100 }}
            component={"img"}
            src="/assets/api_key.svg"
          />

          {getMyDetails?.api_key && (
            <Box sx={{ overflowWrap: "break-word" }}>
              <CardActionArea
                onClick={() => {
                  navigator.clipboard
                    .writeText(getMyDetails.api_key)
                    .then(() => {
                      alert(data.apiCopied);
                    });
                }}
                sx={{ borderRadius: 2, p: 2 }}
              >
                <Typography
                  variant="body2"
                  align="center"
                  fontStyle={"italic"}
                  sx={{ wordBreak: "break-all" }}
                >
                  {getMyDetails.api_key}
                </Typography>
              </CardActionArea>
            </Box>
          )}
          <Button startIcon={<KeyOutlined />} onClick={genAPI}>
            {data.genAPIKeys}
          </Button>
        </Stack>
      </Box>

      <Box mt={2} borderRadius={2} bgcolor={"white"} m={2} p={2}>
        <ConversationalApi hitAxios={hitAxios} data={data} />
      </Box>
    </div>
  );
};

export default LeftSide;
