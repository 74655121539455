import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, IconButton, Tooltip } from "@mui/material";
import { DeleteOutline, HourglassTopOutlined } from "@mui/icons-material";
import { GlobalContext } from "../../../../context/GlobalContext";
import EditIcon from "@mui/icons-material/Edit";
import { convertToYYYYDDMMFormate } from "../../../../utils/DateFormatter";
const SegmentList = ({
  data,
  screenWidth,
  segmentList,
  setSegmentList,
  getSegmentList,
  state,
  setState,
}) => {
  const [hide, setHide] = React.useState(
    localStorage.getItem("hide_templet_header") ? true : false
  );
  const { hitAxios } = React.useContext(GlobalContext);
  async function delTemplet(name) {
    if (window.confirm(data.AUS)) {
      const res = await hitAxios({
        path: "/api/segments/delete",
        post: true,
        admin: false,
        obj: { segmentId: name },
      });
      if (res.data.success) {
        getSegmentList();
      }
    }
  }

  return (
    <div>
      <Box mt={2} borderRadius={4} bgcolor={"white"}>
        {segmentList.length != 0 ? (
          <Box height={screenWidth > 899 ? "66vh" : "35vh"}>
            <DataGrid
              getRowId={(row) => row._id}
              rows={segmentList}
              columns={[
                {
                  headerName: data.name,
                  field: "name",
                  flex: 1,
                  renderCell: (dataa) => {
                    return (
                      <Tooltip title={dataa.row?.name}>
                        {dataa.row?.name}
                      </Tooltip>
                    );
                  },
                },
                {
                  headerName: "Total Audiance",
                  field: "language",
                  flex: 1,
                  align: "center",
                  renderCell: (dataa) => {
                    return (
                      <>
                        {" "}
                        {dataa.row.isRunning == true ? (
                          <Tooltip title={"Take 2 mints - 20 mints"}>
                            <HourglassTopOutlined
                              sx={{ color: "orange", height: 20, width: 20 }}
                            />
                          </Tooltip>
                        ) : (
                          <Tooltip title={dataa.row?.totalTargetedAudience}>
                            {dataa.row?.totalTargetedAudience}
                          </Tooltip>
                        )}
                      </>
                    );
                  },
                },
                {
                  headerName: "Action",
                  field: "Action",
                  flex: 1,
                  renderCell: (dataa) => {
                    return (
                      <>
                        {/* <EditIcon /> */}
                        <IconButton
                          onClick={() => {
                            setState({
                              ...state,
                              segmentDialog: true,
                              segmentData: {
                                ...dataa.row,
                                from: convertToYYYYDDMMFormate(
                                  dataa.row.startDate
                                ),
                                to: convertToYYYYDDMMFormate(dataa.row.endDate),
                                isViewOnly: true,
                              },
                            });
                          }}
                          color="successs"
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => delTemplet(dataa.row?._id)}
                          color="error"
                        >
                          <DeleteOutline />
                        </IconButton>
                      </>
                    );
                  },
                },
              ]}
              pageSize={50}
              rowsPerPageOptions={[50]}
              checkboxSelection={false}
              // components={{
              //     Toolbar: CustomToolbar
              // }}
              sx={{
                boxShadow: 0,
                borderRadius: 4,
                border: 0,
                fontSize: 13,
                padding: 2,
              }}
            />
          </Box>
        ) : (
          ""
        )}
      </Box>
    </div>
  );
};

export default SegmentList;
