import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { GlobalContext } from "../../context/GlobalContext";
import dateFormatter, { dateFormate } from "../../utils/DateFormatter";
import { Box, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import getRandomColor from "../../utils/RandomColorGenerator.js"

const Orders = ({ data, orderData }) => {
  const G = React.useContext(GlobalContext);
  const userData = JSON.parse(localStorage.getItem("myData"));
  const [date, setDate] = useState({ startDate: "", endDate: "" });
  const [chatState, setChartState] = useState({
    option: {
      chart: {
        id: "order-bar-chart",
      },
      xaxis: {
        categories: orderData?.map((i) => dateFormatter(i._id)),
      },
      title: {
        text: "No of Orders. (per day)",
        align: "left",
      },
      colors: [() => getRandomColor()]
    },
    series: [
      {
        name: "No of Order",
        data: orderData?.map((i) => i.count),
      },
    ],
  });
  async function getData(startDate, endDate) {
    const res = await G.hitAxiosOnly({
      path:
        process.env.REACT_APP_BACKEND_BASE_URL +
        "/backend/analytics/" +
        userData?._id +
        `/no-orders-for-each-day/data?startDate=${startDate}&endDate=${endDate}`,
      post: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.status === 200) {
      setChartState({
        option: {
          chart: {
            id: "order-bar-chart",
          },
          xaxis: {
            categories: res?.data?.data?.map((i) => dateFormatter(i._id)),
          },
          title: {
            text: "No of Orders. (per day)",
            align: "left",
          },
          colors: [() => getRandomColor()]
        },
        series: [
          {
            name: "No of Order",
            data: res?.data?.data?.map((i) => i.count),
          },
        ],
      });
    }
  }
  useEffect(() => {
    setDate({
      startDate: dateFormate(
        new Date(new Date() - 1000 * 60 * 60 * 24 * parseInt(30))
      ),
      endDate: dateFormate(new Date()),
    });
    setChartState({
      option: {
        chart: {
          id: "order-bar-chart",
        },
        xaxis: {
          categories: orderData?.map((i) => dateFormatter(i._id)),
        },
        title: {
          text: "No of Orders. (per day)",
          align: "left",
        },
        colors: [() => getRandomColor()]
      },
      series: [
        {
          name: "No of Order",
          data: orderData?.map((i) => i.count),
        },
      ],
    });
  }, [G.dashboardData["no-orders-for-each-day"]]);
  const options = {
    chart: {
      id: "order-bar-chart",
    },
    xaxis: {
      categories: orderData?.map((i) => dateFormatter(i._id)),
    },
    title: {
      text: "No of Orders. (per day)",
      align: "left",
    },
  };

  const series = [
    {
      name: "No of Order",
      data: orderData?.map((i) => i.count),
    },
  ];

  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box
          components={["DatePicker", "DatePicker"]}
          display={"flex"}
          justifyContent={"flex-end"}
          gap="5px"
        >
          <DatePicker
            label="Start Date"
            inputFormat="YYYY-MM-DD"
            renderInput={(props) => (
              <TextField
                size="small"
                InputProps={{ style: { borderRadius: 10 } }}
                {...props}
              />
            )}
            value={date.startDate}
            onChange={(newValue) => {
              setDate({ ...date, startDate: dateFormate(newValue.$d) });
              getData(dateFormate(newValue.$d), date.endDate);
            }}
          />
          <DatePicker
            label="End Date"
            inputFormat="YYYY-MM-DD"
            renderInput={(props) => (
              <TextField
                size="small"
                InputProps={{ style: { borderRadius: 10 } }}
                {...props}
              />
            )}
            value={date.endDate}
            onChange={(newValue) => {
              setDate({ ...date, endDate: dateFormate(newValue.$d) });
              getData(date.startDate, dateFormate(newValue.$d));
            }}
          />
        </Box>
      </LocalizationProvider>
      <Chart
        id="basic-line-chart"
        // height={"30vh"}
        width={"100%"}
        options={chatState.option}
        series={chatState.series}
        type="bar"
      />
    </Box>
  );
};

export default Orders;
