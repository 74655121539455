import { Box } from "@mui/material";

const History = ({ data }) => {
  return (
    <Box mt={2} borderRadius={4} bgcolor={"white"} p={2}>
      {" "}
      History
    </Box>
  );
};
export default History;
