// OtpInput.jsx
import { styled } from "@mui/material";
import { useEffect, useRef, useState } from "react";

const StyledInput = styled((props) => (
  <input
    key={props.index}
    type="text"
    ref={(input) => (props.inputRefs.current[props.index] = input)}
    value={props.value}
    onChange={(e) => props.handleChange(props.index, e)}
    onClick={() => props.handleClick(props.index)}
    onKeyDown={(e) => props.handleKeyDown(props.index, e)}
    {...props}
  />
))({
  width: "40px",
  height: "40px",
  margin: "5px",
  textAlign: "center",
  fontSize: "1.2em",
  boxShadow: "inset 2px 2px 6px #22a454",
  borderColor: (theme) => theme.palette.primary.main,
  ":focus-visible": {
    outline: "none",
  },
});

const OtpInputWithValidation = ({ length = 4, onOtpSubmit = () => {} , otp, setOtp}) => {
  
  const inputRefs = useRef([]);

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  const handleChange = (index, e) => {
    const value = e.target.value;
    if (isNaN(value)) return;

    const newOtp = [...otp];
    // allow only one input
    newOtp[index] = value.substring(value.length - 1);
    setOtp(newOtp);

    // submit trigger
    const combinedOtp = newOtp.join("");
    if (combinedOtp.length === length) onOtpSubmit(combinedOtp);

    // Move to next input if current field is filled
    if (value && index < length - 1 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleClick = (index) => {
    inputRefs.current[index].setSelectionRange(1, 1);

    // optional
    if (index > 0 && !otp[index - 1]) {
      inputRefs.current[otp.indexOf("")].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (
      e.key === "Backspace" &&
      !otp[index] &&
      index > 0 &&
      inputRefs.current[index - 1]
    ) {
      // Move focus to the previous input field on backspace
      inputRefs.current[index - 1].focus();
    }
  };

  return (
    <div>
      {otp.map((value, index) => {
        return (
          <StyledInput
            index={index}
            inputRefs={inputRefs}
            value={value}
            handleChange={handleChange}
            handleClick={handleClick}
            handleKeyDown={handleKeyDown}
          />
        );
      })}
    </div>
  );
};
export default OtpInputWithValidation;
