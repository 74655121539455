import React from "react";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { DeleteOutline } from "@mui/icons-material";
import { Box, IconButton, Stack, Typography, Grid } from "@mui/material";
import ButtonComp from "../../context/ButtonComp";
import { GlobalContext } from "../../context/GlobalContext";

const SavedTemplet = ({ data }) => {
  const [selected, setSelected] = React.useState([]);
  const { hitAxios } = React.useContext(GlobalContext);
  const [templets, setTemplets] = React.useState("");
  const history = useHistory();

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Box width={"100%"}>
          <Grid
            container
            alignItems={"center"}
            justifyContent={"flex-end"}
            spacing={2}
          >
            <Grid item>
              <Stack spacing={2} direction={"row"} alignItems={"center"}>
                <Typography variant="h5">{data.contactList}</Typography>
                <GridToolbarExport />
              </Stack>
            </Grid>
            <Grid item>
              <Stack direction={"row"} alignItems={"center"}>
                {selected.length > 0 && (
                  <Typography color={"gray"} variant="caption">
                    ({selected.length})
                  </Typography>
                )}
                <IconButton
                  onClick={delContact}
                  color="error"
                  disabled={selected.length < 1 ? true : false}
                >
                  <DeleteOutline />
                </IconButton>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </GridToolbarContainer>
    );
  }

  async function delContact(e) {
    if (window.confirm(data.AUS)) {
      const res = await hitAxios({
        path: "/api/templet/del_templets",
        admin: false,
        post: true,
        obj: { selected },
      });
      if (res.data.success) {
        getTemplets();
      }
    }
  }

  async function getTemplets() {
    const res = await hitAxios({
      path: "/api/templet/get_templets",
      post: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.data.success) {
      setTemplets(res.data.data);
    }
  }

  const handleSelectionChange = (newSelection) => {
    setSelected(newSelection);
  };

  React.useEffect(() => {
    getTemplets();
  }, []);

  return (
    <div>
      <Box m={3}>
        <Stack
          alignItems={"center"}
          direction={"row"}
          justifyContent={"space-between"}
          spacing={2}
        >
          <Typography variant="h6" fontWeight={600}>
            {data.templets}
          </Typography>
          <ButtonComp
            onClick={() => history.push("/user?page=chatbot-flow")}
            title={data.addNew}
          />
        </Stack>

        <Box mt={3}>
          {templets ? (
            <Box p={2} height={"74vh"} bgcolor={"white"} borderRadius={2}>
              <DataGrid
                getRowId={(row) => row.id}
                rows={[...templets].reverse()}
                columns={[
                  { headerName: data.id, field: "id", flex: 1 },
                  { headerName: data.title, field: "title", flex: 1 },
                  { headerName: data.type, field: "type", flex: 1 },
                  {
                    headerName: data.createdAt,
                    field: "createdAt",
                    flex: 1,
                    renderCell: (column) => {
                      return moment(column.row.createdAt).fromNow();
                    },
                  },
                ]}
                pageSize={50}
                rowsPerPageOptions={[50]}
                checkboxSelection={true}
                components={{
                  Toolbar: CustomToolbar,
                }}
                sx={{
                  boxShadow: 0,
                  borderRadius: 4,
                  border: 0,
                  fontSize: 13,
                  padding: 2,
                }}
                onRowSelectionModelChange={handleSelectionChange}
              />
            </Box>
          ) : (
            ""
          )}
        </Box>
      </Box>
    </div>
  );
};

export default SavedTemplet;
