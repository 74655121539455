// Adapted from https://en.wikipedia.org/wiki/List_of_musical_instruments
export const IndianStates = [
  {
    instruments: [
      "Andaman and Nicobar Islands",
      "Andhra Pradesh",
      "Arunachal Pradesh",
      "Assam",
      "Bihar",
      "Chandigarh",
      "Chhattisgarh",
      "Dadra and Nagar Haveli",
      "Daman and Diu",
      "Delhi",
      "Goa",
      "Gujarat",
      "Haryana",
      "Himachal Pradesh",
      "Jammu and Kashmir",
      "Jharkhand",
      "Karnataka",
      "Kerala",
      "Ladakh",
      "Lakshadweep",
      "Madhya Pradesh",
      "Maharashtra",
      "Manipur",
      "Meghalaya",
      "Mizoram",
      "Nagaland",
      "Odisha",
      "Puducherry",
      "Punjab",
      "Rajasthan",
      "Sikkim",
      "Tamil Nadu",
      "Telangana",
      "Tripura",
      "Uttar Pradesh",
      "Uttarakhand",
      "West Bengal",
    ],
  },
].map(({ instruments }) => ({
  label: "Indian States",
  options: instruments.map((s) => ({
    name: s,
    label: s,
    value: s,
  })),
}));

export const Orderdate = [
  {
    instruments: [
      {
        name: `new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setHours(0, 0, 0, 0))`,
        label: "After a month",
        value: `new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setHours(0, 0, 0, 0))`,
      },
      {
        name: `new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setHours(24, 0, 0, 0))`,
        label: "Less than a month",
        value: `new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setHours(24, 0, 0, 0))`,
      },
    ],
  },
].map(({ instruments }) => ({
  label: "Custom Dates",
  options: instruments.map((s) => s),
}));
export const PaymentMode = [
  {
    instruments: [
      {
        name: `COD`,
        label: "Cash on delivery",
        value: `COD`,
      },
      {
        name: `Online`,
        label: "Online",
        value: `Online`,
      },
    ],
  },
].map(({ instruments }) => ({
  label: "Payment Mode",
  options: instruments.map((s) => s),
}));
