import {
  CircleNotifications,
  MonetizationOn,
  MonetizationOnOutlined,
  Stars,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Chip,
  Divider,
  Grid,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import TextFieldNew from "../../components/TextFieldNew";

const UpdateWhatsappPricing = ({
  state,
  setState,
  data,
  getUsers,
  hitAxios,
  CON,
}) => {
  const [pricing, setWapricing] = React.useState({
    marketing: 0,
    utility: 0,
    services: 0,
    currency: "INR",
    marketRegion: "India",
  });
  const currencyArray = [{ name: "INR" }, { name: "USD" }];
  const marketRegeionArray = [{ name: "India" }, { name: "North America" }];
  async function getPlans(uid) {
    const res = await hitAxios({
      path: "/api/admin/get_users_whatsapp_billing_details",
      post: true,
      admin: true,
      obj: { uid },
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_admin"),
    });
    if (res.data.success) {
      setWapricing(res.data.data);
    }
  }

  async function updateUserPlan(uid) {
    if (window.confirm(data.AUS)) {
      const res = await hitAxios({
        path: "/api/admin/update_users_whatsapp_billing",
        post: true,
        admin: true,
        obj: { ...pricing, uid },
      });
      if (res.data.success) {
        getPlans(uid);
      }
    }
  }

  React.useEffect(() => {
    getPlans(state?.dialogEditData?.sqlId);
  }, [state?.dialogEditData?.sqlId]);

  return (
    <Box mt={2}>
      <Divider />
      <Stack mt={2} direction={"column"} spacing={2}>
        <Stack spacing={1} direction={"row"}>
          <CircleNotifications sx={{ color: "orange" }} />
          <Stack direction={"column"}>
            <Typography fontWeight={600}>
              {"Update Whatsapp Pricing"}
            </Typography>
          </Stack>
        </Stack>

        <Box>
          <Grid container spacing={2}>
            <Grid xs={12} sm={12} md={"2.4"} lg={"2.4"} item>
              <TextFieldNew
                fullWidth
                label={"Marketing"}
                type="number"
                value={Number(pricing.marketing)}
                onChange={(e) => {
                  setWapricing({
                    ...pricing,
                    marketing: Number(e.target.value),
                  });
                }}
              />
            </Grid>
            <Grid xs={12} sm={12} md={"2.4"} lg={"2.4"} item>
              <TextFieldNew
                fullWidth
                label={"Utility"}
                type="number"
                value={Number(pricing.utility)}
                onChange={(e) => {
                  setWapricing({ ...pricing, utility: Number(e.target.value) });
                }}
              />
            </Grid>
            <Grid xs={12} sm={12} md={"2.4"} lg={"2.4"} item>
              <TextFieldNew
                label={"Services"}
                type="number"
                fullWidth
                value={Number(pricing.services)}
                onChange={(e) => {
                  setWapricing({
                    ...pricing,
                    services: Number(e.target.value),
                  });
                }}
              />
            </Grid>
            <Grid xs={12} sm={12} md={"2.4"} lg={"2.4"} item>
              <Select
                size="small"
                fullWidth
                value={pricing?.marketRegion}
                onChange={(e) => {
                  setWapricing({ ...pricing, marketRegion: e.target.value });
                }}
                sx={{ borderRadius: "10px" }}
              >
                {marketRegeionArray?.map((elm) => (
                  <MenuItem value={elm.name}>{elm?.name}</MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid xs={12} sm={12} md={"2.4"} lg={"2.4"} item>
              <Select
                size="small"
                fullWidth
                value={pricing?.currency}
                onChange={(e) => {
                  setWapricing({ ...pricing, currency: e.target.value });
                }}
                sx={{ borderRadius: "10px" }}
              >
                {currencyArray?.map((elm) => (
                  <MenuItem value={elm.name}>{elm?.name}</MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </Box>
        <LoadingButton
          loading={CON.data?.loading}
          onClick={() => {
            updateUserPlan(state?.dialogEditData?.sqlId);
          }}
          variant="contained"
          sx={{ boxShadow: 0, borderRadius: 2 }}
        >
          {"Update Whatsapp Pricing"}
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default UpdateWhatsappPricing;
