import {
  Alert,
  Box,
  Button,
  Dialog,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import React from "react";
import CloseBtn from "../context/CloseBtn";
import TextFieldNew from "../components/TextFieldNew";
import { Check } from "@mui/icons-material";

const UserLoginForget = ({ data, hitAxios, CON }) => {
  const [state, setState] = React.useState({
    dialog: false,
  });

  async function sendRecovery() {
    const res = await hitAxios({
      path: "/api/user/send_resovery",
      post: true,
      admin: false,
      obj: { email: state.email },
    });
    if (res.data.success) {
      setState({ ...state, msg: res.data.msg });
    } else {
      setState({ ...state, msg: "" });
    }
  }

  return (
    <div>
      <Dialog
        fullWidth
        PaperProps={{
          style: { borderRadius: 10 },
        }}
        open={state.dialog}
        onClose={() => setState({ ...state, dialog: false })}
      >
        <Box p={2}>
          <Stack direction={"column"} spacing={2}>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Typography fontWeight={"bold"} variant="h6">
                {data.sendRecoverLink}
              </Typography>
              <CloseBtn onClick={() => setState({ ...state, dialog: false })} />
            </Stack>
            <Divider />

            <TextFieldNew
              onChange={(e) => setState({ ...state, email: e.target.value })}
              label={data.enterYOurEmail}
            />

            <LoadingButton
              loading={CON?.data?.loading}
              onClick={sendRecovery}
              disabled={state.email ? false : true}
              sx={{ borderRadius: 2, boxShadow: 0 }}
              variant="contained"
            >
              {data.submit}
            </LoadingButton>

            {state?.msg && (
              <Alert icon={<Check fontSize="inherit" />} severity="success">
                {state.msg}
              </Alert>
            )}
          </Stack>
        </Box>
      </Dialog>

      <Box mt={2}>
        <Stack direction={"row"} justifyContent={"flex-end"}>
          <Typography
            onClick={() => {
              setState({ ...state, dialog: true });
              localStorage.clear();
            }}
            sx={{ color: data.color_theme, cursor: "pointer" }}
            color={"gray"}
            fontWeight={700}
            variant="body2"
          >
            {data.forgotPass}
          </Typography>
        </Stack>
      </Box>
    </div>
  );
};

export default UserLoginForget;
