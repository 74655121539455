import {
  Box,
  Button,
  CardActionArea,
  Chip,
  Dialog,
  Drawer,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import CloseBtn from "../../context/CloseBtn";
import TextFieldNew from "../../components/TextFieldNew";
import { Add, Download, PublishOutlined, Save } from "@mui/icons-material";
import ButtonComp from "../../context/ButtonComp";
import { useRef } from "react";

const AddContacts = ({
  data,
  state,
  setState,
  getContacts,
  screenWidth,
  phoneBookData,
  hitAxios,
  getPhoneBooks,
}) => {
  const [states, setStates] = React.useState({
    show: "CSV",
    file: null,
  });
  async function insertContact() {
    const res = await hitAxios({
      path: "/api/phonebook/add_single_contact",
      post: true,
      admin: false,
      obj: {
        ...states,
        id: state?.dialogContactData?.id,
        phonebook_name: state?.dialogContactData?.name,
      },
    });
    if (res.data.success) {
      setState({ ...state, dialogContact: false });
      getContacts();
    }
  }

  async function uploadCSV() {
    const fd = new FormData();
    fd.append("file", states.file);
    fd.append("id", state?.dialogContactData?.id);
    fd.append("phonebook_name", state?.dialogContactData?.name);
    const res = await hitAxios({
      path: "/api/phonebook/import_contacts",
      post: true,
      admin: false,
      obj: fd,
    });
    if (res.data.success) {
      setState({ ...state, dialogContact: false });
      setStates({ ...states, file: null, fileName: "" });
      getContacts();
    }
  }

  return (
    <Dialog
      sx={{ backdropFilter: "blur(10px)" }}
      open={state.dialogContact}
      onClose={() => {
        setStates({ ...states, file: null, fileName: "" });
        setState({ ...state, dialogContact: false });
      }}
      PaperProps={{
        style: { borderRadius: 10 },
      }}
      fullWidth
    >
      <Box p={3}>
        <Stack
          justifyContent="space-between"
          alignItems={"center"}
          direction="row"
        >
          <Typography variant="h6">
            {data.addContacts} ({state?.dialogContactData?.name})
          </Typography>
          <CloseBtn
            onClick={() => {
              setStates({ ...states, file: null, fileName: "" });
              setState({ ...state, dialogContact: false });
            }}
          />
        </Stack>

        <Stack justifyContent={"flex-end"} spacing={2} mt={3} direction={"row"}>
          <Chip
            onClick={() => {
              setStates({ ...states, file: null, fileName: "", show: "PASTE" });
            }}
            variant={states.show === "PASTE" ? "filled" : "outlined"}
            label={data.uploadByPaste}
          />
          <Chip
            onClick={() =>
              setStates({ ...states, file: null, fileName: "", show: "CSV" })
            }
            variant={states.show === "CSV" ? "filled" : "outlined"}
            label={data.uploadByCsv}
          />
        </Stack>

        <Stack direction={"column"} spacing={2} mt={2}>
          {states.show === "PASTE" && (
            <>
              <Stack direction={"row"} spacing={2}>
                <TextFieldNew
                  onChange={(e) =>
                    setStates({ ...states, name: e.target.value })
                  }
                  fullWidth
                  label={data.contactName}
                />

                <TextFieldNew
                  onChange={(e) =>
                    setStates({ ...states, mobile: e.target.value })
                  }
                  type="number"
                  fullWidth
                  label={data.contactMobile}
                />
              </Stack>
              <Stack direction={"row"} spacing={2}>
                <TextFieldNew
                  onChange={(e) =>
                    setStates({ ...states, var1: e.target.value })
                  }
                  fullWidth
                  label={data.var1}
                />
                <TextFieldNew
                  onChange={(e) =>
                    setStates({ ...states, var2: e.target.value })
                  }
                  fullWidth
                  label={data.var2}
                />
                <TextFieldNew
                  onChange={(e) =>
                    setStates({ ...states, var3: e.target.value })
                  }
                  fullWidth
                  label={data.var3}
                />
              </Stack>
              <Stack direction={"row"} spacing={2}>
                <TextFieldNew
                  onChange={(e) =>
                    setStates({ ...states, var4: e.target.value })
                  }
                  fullWidth
                  label={data.var4}
                />
                <TextFieldNew
                  onChange={(e) =>
                    setStates({ ...states, var5: e.target.value })
                  }
                  fullWidth
                  label={data.var5}
                />
              </Stack>

              <Stack direction={"column"} alignItems={"center"}>
                <ButtonComp
                  onClick={insertContact}
                  startIcon={<Save sx={{ height: 20, width: 20 }} />}
                  title={data.saveContact}
                />
              </Stack>
            </>
          )}

          {states.show === "CSV" && (
            <>
              <Stack
                sx={{ cursor: "pointer" }}
                onClick={() => window.open("/csv-sample.csv")}
                direction={"row"}
                spacing={1}
                alignItems={"center"}
              >
                <Download
                  sx={{
                    height: 20,
                    width: 20,
                    color: (t) => t.palette.primary.main,
                  }}
                />
                <Typography
                  variant="body2"
                  fontWeight={500}
                  sx={{ color: (t) => t.palette.primary.main }}
                >
                  Download sample CSV
                </Typography>
              </Stack>

              <CardActionArea
                component="label"
                sx={{ bgcolor: "action.hover", p: 4 }}
              >
                <input
                  accept=".csv"
                  type="file"
                  hidden
                  files={states.file}
                  onChange={(e) =>
                    setStates({
                      ...states,
                      file: e.target.files[0],
                      fileName: e.target.files[0]?.name,
                    })
                  }
                />
                <Stack alignItems={"center"}>
                  <Add />
                  <Typography variant="caption" color="gray">
                    {states?.fileName || data.selectCsv}
                  </Typography>
                </Stack>
              </CardActionArea>

              <Stack direction={"column"} alignItems={"center"}>
                <ButtonComp
                  onClick={uploadCSV}
                  startIcon={<PublishOutlined sx={{ height: 20, width: 20 }} />}
                  title={data.importCSV}
                />
              </Stack>
            </>
          )}
        </Stack>
      </Box>
    </Dialog>
  );
};

export default AddContacts;
