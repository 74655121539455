import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Button, IconButton, Switch, Tooltip } from "@mui/material";
import {
  CheckCircle,
  CheckCircleOutline,
  CheckOutlined,
  Delete,
  DeleteOutline,
  HourglassTopOutlined,
  ThumbDownOffAlt,
} from "@mui/icons-material";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import { GlobalContext } from "../../../context/GlobalContext";
import moment from "moment";
import { useHistory } from "react-router-dom";

const WhatsappNumberList = ({ data }) => {
  const history = useHistory();
  const [hide, setHide] = React.useState(
    localStorage.getItem("hide_templet_header") ? true : false
  );
  const { hitAxios, getMyDetails, setMyDetails } =
    React.useContext(GlobalContext);

  async function delPhoneNumber(name) {
    if (window.confirm(data.AUS)) {
      const res = await hitAxios({
        path: "/api/user/whatsapp/deleteNumber",
        post: true,
        admin: false,
        obj: { phone: name },
      });
      if (res.data.success) {
        setMyDetails({
          ...getMyDetails,
          whatsappPhoneNumbers: res.data.data,
        });
      }
    }
  }
  async function changeActiveStatus(phone, status) {
    if (window.confirm(data.AUS)) {
      const res = await hitAxios({
        path: "/api/user/whatsapp/changeActiveStatus",
        post: true,
        admin: false,
        obj: { phone, status },
      });
      if (res.data.success) {
        setMyDetails({
          ...getMyDetails,
          whatsappPhoneNumbers: res.data.data,
        });
      }
    }
  }
  async function refreshPhoneStatus(phone) {
    if (window.confirm(data.AUS)) {
      const res = await hitAxios({
        path: "/api/user/whatsapp/refreshNumber",
        post: true,
        admin: false,
        obj: { phone },
      });
      if (res.data.success) {
        setMyDetails({
          ...getMyDetails,
          whatsappPhoneNumbers: res.data.data,
        });
      }
    }
  }

  return (
    <div>
      <Box display={"flex"} justifyContent={"flex-end"}>
        <Button
          onClick={() => {
            history.push("user?page=whatsappIntegration");
          }}
        >
          Add Number
        </Button>
      </Box>
      <Box borderRadius={4} bgcolor={"white"}>
        {getMyDetails?.whatsappPhoneNumbers?.length > 0 ? (
          <Box height={hide ? "76vh" : "48vh"}>
            <DataGrid
              getRowId={(row) => row.whatsappNumberWithCountryCode}
              rows={getMyDetails?.whatsappPhoneNumbers}
              columns={[
                {
                  headerName: "Number",
                  field: "Number",
                  flex: 1,
                  renderCell: (dataa) => {
                    return (
                      <Tooltip title={dataa.row?.whatsappNumberWithCountryCode}>
                        {dataa.row?.whatsappNumberWithCountryCode}
                      </Tooltip>
                    );
                  },
                },
                {
                  headerName: "Meta Status Remark",
                  field: "Meta Status",
                  flex: 1,
                  renderCell: (dataa) => {
                    return (
                      <Tooltip title={dataa.row?.whatsappMetaStatus}>
                        {dataa.row?.whatsappMetaStatus}
                      </Tooltip>
                    );
                  },
                },
                {
                  headerName: "Meta Status",
                  field: "status",
                  flex: 1,
                  renderCell: (dataa) => {
                    return (
                      <>
                        {dataa?.row?.actualMetaTemplet?.status ===
                        "REJECTED" ? (
                          <Tooltip title={data.rejected}>
                            <ThumbDownOffAlt
                              sx={{ color: "red", height: 20, width: 20 }}
                            />
                          </Tooltip>
                        ) : dataa?.row?.whatsappMetaStatus === "FINISHED" ? (
                          <Tooltip title={data.approved}>
                            <CheckCircle
                              sx={{ color: "green", height: 20, width: 20 }}
                            />
                          </Tooltip>
                        ) : (
                          <Tooltip title={data.pendingUppercase}>
                            <HourglassTopOutlined
                              sx={{ color: "orange", height: 20, width: 20 }}
                            />
                          </Tooltip>
                        )}
                      </>
                    );
                  },
                },
                {
                  headerName: "Active",
                  field: "Active",
                  flex: 1,
                  renderCell: (dataa) => {
                    return (
                      <>
                        <Tooltip title={dataa.row?.isActive}>
                          <Switch
                            {...{
                              inputProps: { "aria-label": "Switch demo" },
                            }}
                            checked={dataa.row?.isActive}
                            name="search"
                            onChange={(e) => {
                              changeActiveStatus(
                                dataa.row?.whatsappNumberWithCountryCode,
                                e.target.checked
                              );
                            }}
                          />
                        </Tooltip>
                      </>
                    );
                  },
                },
                {
                  headerName: "Update Time",
                  field: "Update Time",
                  flex: 1,
                  renderCell: (dataa) => {
                    return (
                      <Tooltip title={dataa.row?.update_time}>
                        {moment(dataa.row.update_time).format(
                          "DD-MM-YY | hh:mmA"
                        )}
                      </Tooltip>
                    );
                  },
                },
                {
                  headerName: "Action",
                  field: "Action",
                  flex: 1,
                  renderCell: (dataa) => {
                    return (
                      <>
                        {" "}
                        <Tooltip title={"Refresh"}>
                          <IconButton
                            onClick={() =>
                              refreshPhoneStatus(
                                dataa.row?.whatsappNumberWithCountryCode
                              )
                            }
                          >
                            <RefreshOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={"Delete"}>
                          <IconButton
                            onClick={() =>
                              delPhoneNumber(
                                dataa.row?.whatsappNumberWithCountryCode
                              )
                            }
                            color="error"
                          >
                            <DeleteOutline />
                          </IconButton>
                        </Tooltip>
                      </>
                    );
                  },
                },
              ]}
              pageSize={50}
              rowsPerPageOptions={[50]}
              checkboxSelection={false}
              // components={{
              //     Toolbar: CustomToolbar
              // }}
              sx={{
                boxShadow: 0,
                borderRadius: 4,
                border: 0,
                fontSize: 13,
                padding: 2,
              }}
            />
          </Box>
        ) : (
          ""
        )}
      </Box>
    </div>
  );
};

export default WhatsappNumberList;
