import { Box, Button, Container, Switch, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import AgentList from "./AgentList";
import AddAgentDialog from "./createAgent/AddAgentDialog";

const Agents = ({ data, userData }) => {
  const initalPermission = [
    { moduleName: "dashboard", permission: 0 },
    { moduleName: "automation", permission: 0 },
    { moduleName: "pay-history", permission: 0 },
    { moduleName: "analytics", permission: 0 },
    { moduleName: "phonebook", permission: 0 },
    { moduleName: "inbox", permission: 0 },
    { moduleName: "chatbot", permission: 0 },
    { moduleName: "broadcast", permission: 0 },
    { moduleName: "channels", permission: 0 },
    { moduleName: "api-access", permission: 0 },
  ];
  const CON = useContext(GlobalContext);
  const [allgents, setAllagents] = useState([]);
  const [state, setstate] = useState({
    addAgentDialog: false,
    agentData: {
      firstName: "",
      email: "",
      password: "",
      isPassEnter: true,
      isViewOnly: false,
      permissionArray: initalPermission,
    },
  });
  const getAllagents = async () => {
    const res = await CON.hitAxios({
      path: "/api/agent/getallagents",
      post: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.data.success) {
      setAllagents(res.data.data);
      // getAllagents();
    }
  };
  useEffect(() => {
    getAllagents();
  }, []);
  return (
    <Container maxWidth="lg">
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box>
          {" "}
          {/* <Typography sx={{ mt: 2, mb: 1 }}>
            {" "}
            Agent notification
            <Switch
              {...{ inputProps: { "aria-label": "Switch demo" } }}
              // checked={whatsappBrandSetting?.newCustomerMessageShow}
              name="askPhone"
              onChange={(e) => {
                // setWhatsappBrandSetting({
                //   ...whatsappBrandSetting,
                //   newCustomerMessageShow: e.target.checked,
                // });
              }}
            />
          </Typography>
          <Box>asdds</Box> */}
        </Box>
        <Button
          onClick={() => {
            setstate({
              ...state,
              addAgentDialog: true,
              agentData: {
                firstName: "",
                email: "",
                password: "",
                isPassEnter: true,
                isViewOnly: false,
                permissionArray: initalPermission,
              },
            });
          }}
        >
          Add new agent
        </Button>
      </Box>
      <AddAgentDialog
        getAllagents={getAllagents}
        state={state}
        allgents={allgents}
        setState={setstate}
        data={data}
      />
      <AgentList
        data={data}
        setState={setstate}
        state={state}
        allgents={allgents}
        getAllagents={getAllagents}
      />
    </Container>
  );
};
export default Agents;
