import React, { useContext } from "react";
import { FileCopyOutlined } from "@mui/icons-material";
import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import { GlobalContext } from "../../context/GlobalContext";

const WebSDKShare = ({ botId }) => {
  const CON = useContext(GlobalContext);

  const textareaValue = `<script defer="defer" src="https://www.ulai.in/file-server/uploads/Ulai/web-sdk-init.js"></script> <div id="chatBot" data-value=${botId}></div>`;

  const handleCopyClick = () => {
    navigator.clipboard.writeText(textareaValue);
    CON.setData({
      ...CON.data,
      snack: true,
      snack_msg: "Copied !",
      snack_success: true,
    });
    setTimeout(() => {
      CON.setData({
        ...CON.data,
        snack: false,
        snack_msg: "",
        snack_success: false,
      });
    }, 4000);
  };

  return (
    <Box>
      <Typography variant="h6">Embed the bot</Typography>
      <Typography variant="body1">
        Paste the code snippet below in your HTML code where you want to display
        the Ulai chatbot.
      </Typography>
      {/* ToDo
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <Button variant="contained" color="primary">
          JavaScript
        </Button>
        <Button variant="contained" color="primary">
          Manage Allowed Hosts
        </Button>
      </Box> */}
      <Grid item xs={12} p={1}>
        <Stack
          direction={"row"}
          spacing={2}
          sx={{
            background: "whitesmoke",
            borderRadius: "5px",
            marginTop: "10px",
            padding: "2px",
          }}
        >
          <Typography>{textareaValue}</Typography>
          <IconButton
            aria-label="copy"
            onClick={handleCopyClick}
            sx={{
              borderRadius: "90px",
              backgroundColor: "white",
              padding: "8px",
            }}
          >
            <FileCopyOutlined />
          </IconButton>
        </Stack>
      </Grid>
    </Box>
  );
};

export default WebSDKShare;
