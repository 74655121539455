import { AccountCircle, DarkMode, Home, LightMode, Logout, Close } from '@mui/icons-material'
import { Dialog, IconButton, Typography, Divider, TextField, Button } from '@mui/material'
import { Box, Stack } from '@mui/system'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { TranslateContext } from '../context/TranslateContext'
import { GlobalContext } from '../context/GlobalContext'

const HeaderAdmin = ({ title }) => {
    const history = useHistory()
    const { data } = React.useContext(TranslateContext)
    const [state, setState] = React.useState({
        dialog: false
    })
    const [admin, setAdmin] = React.useState("")

    const inputPorps = { style: { borderRadius: 10 } }
    const G = React.useContext(GlobalContext)

    async function getAdmin() {
        const res = await G.hitAxios({
            path: "/api/admin/get_admin",
            post: false,
            admin: false,
            token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_admin")
        })
        if (res.data.success) {
            setAdmin(res.data.data)
        }
    }

    async function updateAdmin() {
        const res = await G.hitAxios({
            path: "/api/admin/update-admin",
            post: true,
            admin: true,
            obj: admin
        })
    }

    const logout = () => {
        if (window.confirm(data.AUS)) {
            localStorage.clear();
            history.push('/')
        }
    }

    React.useEffect(() => {
        getAdmin()
    }, [])

    return (
        <Box p={2}>
            <Dialog
                fullWidth
                onClose={(e) => setState({ ...state, dialog: false })}
                open={state.dialog}
                PaperProps={{
                    style: {
                        borderRadius: 10
                    }
                }} >
                <Box p={2}  >
                    <Stack mb={1} direction={'row'} alignItems='center' justifyContent={'space-between'}>
                        <IconButton onClick={() => setState({ ...state, dialog: false })} >
                            <Close />
                        </IconButton>
                        <Typography>{data.accountManage}</Typography>
                    </Stack>
                    <Divider />

                    {admin &&
                        <Stack direction={'column'} spacing={2} mt={2}>
                            <TextField
                                onChange={(e) => setAdmin({ ...admin, email: e.target.value })}
                                value={admin.email}
                                InputProps={inputPorps}
                                size='small' label={data.email} fullWidth />

                            <TextField
                                onChange={(e) => setAdmin({ ...admin, newpass: e.target.value })}
                                InputProps={inputPorps}
                                size='small' label={data.password} fullWidth />

                            <Button
                                onClick={updateAdmin}
                                variant='contained'
                                sx={{ borderRadius: 2, textTransform: 'none', boxShadow: 0 }} fullWidth >{data.submit}</Button>
                        </Stack>}

                </Box>

            </Dialog>


            <Stack alignItems={'center'} direction={'row'} justifyContent='space-between'>
                <IconButton onClick={() => history.push("/")} >
                    <Home />
                </IconButton>

                <Stack direction={'row'} spacing={2}>

                    <IconButton onClick={() => setState({ ...state, dialog: true })} >
                        <AccountCircle />
                    </IconButton>

                    <IconButton onClick={logout} >
                        <Logout />
                    </IconButton>
                </Stack>

            </Stack>
        </Box>
    )
}
export default HeaderAdmin