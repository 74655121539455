import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, IconButton, Tooltip } from "@mui/material";
import {
  CheckCircle,
  CheckCircleOutline,
  CheckOutlined,
  Delete,
  DeleteOutline,
  HourglassTopOutlined,
  ThumbDownOffAlt,
  Visibility,
} from "@mui/icons-material";
import { GlobalContext } from "../../../../context/GlobalContext";
import EditIcon from "@mui/icons-material/Edit";
import { convertISODatetoStringDate } from "../../../../utils/DateFormatter";
import ViewBroadcast from "./ViewBroadcast";

const CampaignList = ({
  data,
  screenWidth,
  campaignsList,
  setCampaignsList,
  getCampaignsList,
  state,
  setState,
}) => {
  const [dialog, setDialog] = React.useState({
    open: false,
  });
  const [logs, setLogs] = React.useState([]);
  const [logStatus, setLogStatus] = React.useState({});
  async function getLogs(log, title) {
    const res = await hitAxios({
      path: "/api/broadcast/get_campaigns_logs",
      post: true,
      admin: false,
      obj: { id: log },
    });
    if (res.data.success) {
      setLogs(res.data.data);
      setLogStatus({ ...res.data, data: "" });
      setDialog({
        open: true,
        openData: log,
        title,
      });
    }
  }
  const { hitAxios } = React.useContext(GlobalContext);
  const CON = React.useContext(GlobalContext);
  async function delTemplet(name) {
    if (window.confirm(data.AUS)) {
      const res = await hitAxios({
        path: "/api/campaigns/delete",
        post: true,
        admin: false,
        obj: { cammpaignId: name },
      });
      if (res.data.success) {
        getCampaignsList();
      }
    }
  }
  const findTime = (startDate, endDate) => {
    const parseDate = (dateString) => {
      const [day, month, yearTime] = dateString.split("/");
      const [year, time] = yearTime.split(", ");
      return new Date(`${year}-${month}-${day}T${time}`);
    };

    const inputDateStartDate = parseDate(startDate);
    const inputDateEndDate = endDate ? parseDate(endDate) : null;
    const now = new Date();

    if (inputDateEndDate) {
      if (inputDateStartDate > now && inputDateEndDate > now) {
        return "UPCOMING";
      } else if (inputDateStartDate < now && inputDateEndDate > now) {
        return "RUNNING";
      } else if (inputDateStartDate < now && inputDateEndDate < now) {
        return "END";
      }
    } else {
      if (inputDateStartDate > now) {
        return "UPCOMING";
      } else if (inputDateStartDate < now) {
        return "END";
      }
    }
    return "RUNNING";
  };
  return (
    <div>
      <Box mt={2} borderRadius={4} bgcolor={"white"}>
        {campaignsList.length != 0 ? (
          <Box height={screenWidth > 899 ? "66vh" : "35vh"}>
            <DataGrid
              getRowId={(row) => row._id}
              rows={campaignsList}
              columns={[
                {
                  headerName: data.name,
                  field: "name",
                  flex: 1,
                  renderCell: (dataa) => {
                    return (
                      <Tooltip title={dataa.row?.name}>
                        {dataa.row?.name}
                      </Tooltip>
                    );
                  },
                },
                {
                  headerName: "Type",
                  field: "campaignType",
                  flex: 1,
                  renderCell: (dataa) => {
                    return (
                      <Tooltip title={dataa.row?.campaignType}>
                        {dataa.row?.campaignType}
                      </Tooltip>
                    );
                  },
                },
                {
                  headerName: data.status,
                  field: "status",
                  flex: 1,
                  renderCell: (dataa) => {
                    return (
                      <>
                        {dataa.row.isComplete ? (
                          <Tooltip title={"END"}>END</Tooltip>
                        ) : (
                          <>
                            {findTime(
                              dataa.row?.startTime,
                              dataa.row?.endTime
                            ) === "END" && <Tooltip title={"END"}>END</Tooltip>}
                            {findTime(
                              dataa.row?.startTime,
                              dataa.row?.endTime
                            ) === "UPCOMING" && (
                              <Tooltip title={"UPCOMING"}>UPCOMING</Tooltip>
                            )}
                            {findTime(
                              dataa.row?.startTime,
                              dataa.row?.endTime
                            ) === "RUNNING" && (
                              <Tooltip title={"RUNNING"}>RUNNING</Tooltip>
                            )}
                          </>
                        )}
                      </>
                    );
                  },
                },
                // {
                //   headerName: "Start Time",
                //   field: "category",
                //   flex: 1,
                //   renderCell: (dataa) => {
                //     return (
                //       <Tooltip title={dataa.row?.category}>
                //         {dataa.row?.category}
                //       </Tooltip>
                //     );
                //   },
                // },
                // {
                //   headerName: "End Time",
                //   field: "endtime",
                //   flex: 1,
                //   renderCell: (dataa) => {
                //     return (
                //       <Tooltip title={dataa.row?.category}>
                //         {dataa.row?.category}
                //       </Tooltip>
                //     );
                //   },
                // },
                {
                  headerName: "Action",
                  field: "Action",
                  flex: 1,
                  renderCell: (dataa) => {
                    return (
                      <>
                        <IconButton
                          onClick={() => {
                            getLogs(dataa?.row._id, dataa?.row.name);
                          }}
                        >
                          <Visibility />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setState({
                              ...state,
                              addCampaignsDialog: true,
                              campaignDate: {
                                ...dataa.row,
                                startTime: convertISODatetoStringDate(
                                  dataa.row.startTime
                                ),
                                endTime: convertISODatetoStringDate(
                                  dataa.row.endTime
                                ),
                                isViewOnly: true,
                              },
                            });
                          }}
                          color="successs"
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => delTemplet(dataa.row?._id)}
                          color="error"
                        >
                          <DeleteOutline />
                        </IconButton>
                      </>
                    );
                  },
                },
              ]}
              pageSize={50}
              rowsPerPageOptions={[50]}
              checkboxSelection={false}
              // components={{
              //     Toolbar: CustomToolbar
              // }}
              sx={{
                boxShadow: 0,
                borderRadius: 4,
                border: 0,
                fontSize: 13,
                padding: 2,
              }}
            />
          </Box>
        ) : (
          ""
        )}
      </Box>
      <ViewBroadcast
        logs={logs}
        setLogs={setLogs}
        dialog={dialog}
        setDialog={setDialog}
        data={data}
        states={state}
        setState={setState}
        hitAxios={hitAxios}
        getBroadList={getCampaignsList}
        CON={CON}
        logStatus={logStatus}
      />
    </div>
  );
};

export default CampaignList;
