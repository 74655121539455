import React from "react";
import { DeleteOutline, Edit, SmartToy } from "@mui/icons-material";
import { Box, IconButton, Stack, Switch, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

const ChatbotList = ({ data, state, setState, hitAxios, userData }) => {
  async function changeBotStatus(id, status) {
    // const res = await hitAxios({
    //   path: "/api/chatbot/change_bot_status",
    //   post: true,
    //   admin: false,
    //   obj: { id, status },
    // });
    // if (res.data.success) {
    //   getChatbots();
    // }
  }

  async function delChatbot(botId) {
    if (window.confirm(data.AUS)) {
      const res = await hitAxios({
        path: process.env.REACT_APP_BACKEND_BASE_URL + "/backend/bot/" + botId,
        post: false,
        admin: false,
        del: true,
      });
      if (res.data.message === "Bot deleted successfully") {
        const resp = await hitAxios({
          path:
            process.env.REACT_APP_BACKEND_BASE_URL +
            "/backend/users/" +
            userData._id,
          post: false,
          admin: false,
          token_user: "",
        });

        if (resp.data._id === userData._id) {
          localStorage.setItem("myData", JSON.stringify({ ...resp.data }));
        }
      }
    }
  }
  async function getBotsForUser(botId) {
    const res = await hitAxios({
      path: process.env.REACT_APP_BACKEND_BASE_URL + "/backend/bot/" + botId,
      post: false,
      admin: false,
      token_user: "",
    });

    if (res.status === 200) {
      localStorage.setItem(
        "myData",
        JSON.stringify({
          ...userData,
          bots: [
            {
              ...userData.bots[0],
              botName: res.data.botName,
            },
          ],
        })
      );
    }
  }
  const fetchDataforBotsDetailedView = (botId) => {
    getBotsForUser(botId);
  };

  const [chatBotsList, setChatBotsList] = React.useState(
    userData.bots ? userData.bots : null
  );
  React.useEffect(() => {
    // console.log("userData", userData);
    const botsList = userData?.bots.map((item, index) => ({
      id: index + 1,
      title: item?.botName ? item?.botName : "--",
      ...item,
      companyName: userData.companyName,
    }));
    setChatBotsList(botsList);
    // ToDo: Making one api call as only one bot as of now
    getBotsForUser(botsList[0]?.botId);
  }, []);
  // setChatbotList(userData.bots);
  return (
    <div>
      <Box m={3} p={2} bgcolor={"white"} borderRadius={2}>
        <Stack direction={"column"} spacing={2}>
          <Stack direction={"row"} spacing={2} alignItems={"center"}>
            <SmartToy sx={{ color: "#7C7C7C" }} />
            <Typography variant="h6" color={"#7C7C7C"} fontWeight={600}>
              {data.chatbotList}
            </Typography>
          </Stack>

          {chatBotsList ? (
            <Box height="80vh">
              <DataGrid
                getRowId={(row) => row.botId}
                rows={[...chatBotsList].reverse()}
                columns={[
                  { headerName: data.id, field: "id", flex: 1 },
                  { headerName: data.title, field: "botName", flex: 1 },
                  {
                    headerName: data.isActive,
                    field: "active",
                    flex: 1,
                    renderCell: (dataa) => {
                      return (
                        <Switch
                          onChange={(e) =>
                            changeBotStatus(dataa.row?.id, e.target.checked)
                          }
                          size="small"
                          // checked={dataa.row.active} Makinbg default true for now
                          checked
                        />
                      );
                    },
                  },
                  {
                    headerName: data.edit,
                    field: "edit",
                    flex: 1,
                    renderCell: (dataa) => {
                      return (
                        <IconButton
                          onClick={() => {
                            setState({
                              ...state,
                              title: dataa?.row?.title,
                              botId: dataa?.row?.id,
                              for_all: dataa?.row?.for_all > 0 ? true : false,
                              add: true,
                              editing: true,
                              botName: dataa?.row?.botName ?? "--",
                              companyName: userData?.companyName,
                              botsView: "details",
                            });
                            // setSelChat(JSON.parse(dataa?.row?.chats));
                            // setSelFlow(JSON.parse(dataa?.row?.flow));
                            fetchDataforBotsDetailedView(dataa?.row?.botId);
                          }}
                          color="info"
                        >
                          <Edit />
                        </IconButton>
                      );
                    },
                  },
                  {
                    headerName: data.delete,
                    field: "del",
                    flex: 1,
                    renderCell: (dataa) => {
                      return (
                        <IconButton
                          onClick={() => {
                            delChatbot(dataa.row?.botId);
                          }}
                          color="error"
                        >
                          <DeleteOutline />
                        </IconButton>
                      );
                    },
                  },
                ]}
                pageSize={50}
                rowsPerPageOptions={[50]}
                checkboxSelection={false}
                // components={{
                //     Toolbar: CustomToolbar
                // }}
                sx={{
                  boxShadow: 0,
                  borderRadius: 4,
                  border: 0,
                  fontSize: 13,
                  padding: 2,
                }}
              />
            </Box>
          ) : (
            ""
          )}
        </Stack>
      </Box>
    </div>
  );
};

export default ChatbotList;
