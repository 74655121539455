import React from "react";
import { Box, CardActionArea, Chip, Stack, Typography } from "@mui/material";
import { AppShortcutOutlined } from "@mui/icons-material";
import { FaWhatsapp } from "react-icons/fa";
import { GlobalContext } from "../../context/GlobalContext";
import WebSDK from "./WebSDK";
import Whatsapp from "./Whatsapp";

const Channels = ({ data }) => {
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  const { hitAxios } = React.useContext(GlobalContext);
  const userData = JSON.parse(localStorage.getItem("myData"));
  const [state, setState] = React.useState({
    menuSelected: "WEBSDK",
    for_all: false,
    add: false,
  });
  React.useEffect(() => {
    const updateScreenWidth = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", updateScreenWidth);
    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);

  return (
    <div>
      <Stack direction={"row"}>
        {screenWidth > 800 && (
          <Box
            p={1}
            width={200}
            bgcolor={"white"}
            minHeight={"100vh"}
            overflow={"auto"}
          >
            <Stack direction={"column"} spacing={2}>
              <CardActionArea
                onClick={() => {
                  setState({ ...state, menuSelected: "WEBSDK" });
                  // getBotsForUser();
                }}
                sx={{
                  p: 1.3,
                  borderRadius: 2,
                  bgcolor:
                    state.menuSelected === "WEBSDK" ? "action.hover" : "",
                }}
              >
                <Stack direction={"row"} spacing={2} alignItems={"center"}>
                  <AppShortcutOutlined
                    sx={{ color: "gray", height: 20, width: 20 }}
                  />
                  <Typography
                    sx={{
                      color: (t) =>
                        state.menuSelected === "WEBSDK"
                          ? t.palette.primary.main
                          : "",
                    }}
                    variant="body2"
                    fontWeight={600}
                  >
                    WebSDK
                  </Typography>
                </Stack>
              </CardActionArea>

              <CardActionArea
                onClick={() => {
                  setState({ ...state, menuSelected: "WHATSAPP" });
                  // getwidgetCustomization();
                }}
                sx={{
                  p: 1.3,
                  borderRadius: 2,
                  bgcolor:
                    state.menuSelected === "WHATSAPP" ? "action.hover" : "",
                }}
              >
                <Stack direction={"row"} spacing={2} alignItems={"center"}>
                  <FaWhatsapp
                    style={{ color: "gray", height: "20px", width: "20px" }}
                  />
                  <Typography
                    sx={{
                      color: (t) =>
                        state.menuSelected === "WHATSAPP"
                          ? t.palette.primary.main
                          : "",
                    }}
                    variant="body2"
                    fontWeight={600}
                  >
                    Whatsapp
                  </Typography>
                </Stack>
              </CardActionArea>
            </Stack>
          </Box>
        )}

        <Box width={"100%"}>
          {screenWidth < 799 && (
            <Box m={3}>
              <Stack direction={"row"} spacing={2} alignItems={"center"}>
                <Chip
                  icon={
                    <AppShortcutOutlined
                      sx={{ color: "gray", height: 20, width: 20 }}
                    />
                  }
                  onClick={() => {
                    setState({ ...state, menuSelected: "WEBSDK" });
                    // getBotsForUser();
                  }}
                  variant={
                    state.menuSelected === "WEBSDK" ? "filled" : "outlined"
                  }
                  label="WebSDK"
                />
                <Chip
                  icon={
                    <FaWhatsapp
                      style={{ color: "gray", height: "20px", width: "20px" }}
                    />
                  }
                  onClick={() => {
                    setState({ ...state, menuSelected: "WHATSAPP" });
                    // getwidgetCustomization();
                  }}
                  variant={
                    state.menuSelected === "WHATSAPP" ? "filled" : "outlined"
                  }
                  label="Whatsapp"
                />
              </Stack>
            </Box>
          )}

          {state.menuSelected === "WEBSDK" && (
            <WebSDK userData={userData} hitAxios={hitAxios} />
          )}
          {state.menuSelected === "WHATSAPP" && (
            <Whatsapp userData={userData} hitAxios={hitAxios} data={data} />
          )}
        </Box>
      </Stack>
    </div>
  );
};

export default Channels;
