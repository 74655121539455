import React from "react";
import WordCloud from "react-d3-cloud";
import { scaleOrdinal } from "d3-scale";
import { schemeCategory10 } from "d3-scale-chromatic";

function WordcloudComponent({ userIntentData, setHoverdValue }) {
  const schemeCategory10ScaleOrdinal = scaleOrdinal(schemeCategory10);

  return React.useCallback(
    <WordCloud
      width={100}
      height={50}
      font="Roboto"
      fill={(d, i) => {
        return schemeCategory10ScaleOrdinal(i);
      }}
      fontWeight="bold"
      data={userIntentData}
      rotate={React.useCallback((word) => word.value % 360, [])}
      padding={0}
      random={React.useCallback(() => Math.random(), [])}
      // To-do: Implement some static tooltip on click
      // onWordClick={(event, d) => {
      //   console.log(`onWordClick: ${d.text}`);
      // }}
      onWordMouseOver={(event, d) => {
        setHoverdValue({ text: d.text, value: d.value });
      }}
      onWordMouseOut={(event, d) => {
        setHoverdValue(null);
      }}
    />,
    [userIntentData]
  );
}
export default WordcloudComponent;
