import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, IconButton, Tooltip } from "@mui/material";
import {
  CheckCircle,
  CheckCircleOutline,
  CheckOutlined,
  Delete,
  DeleteOutline,
  HourglassTopOutlined,
  ThumbDownOffAlt,
} from "@mui/icons-material";
import { GlobalContext } from "../../../context/GlobalContext";

const TempletList = ({
  data,
  screenWidth,
  templetList,
  setTempletList,
  getTemplets,
}) => {
  const [hide, setHide] = React.useState(
    localStorage.getItem("hide_templet_header") ? true : false
  );
  const { hitAxios } = React.useContext(GlobalContext);

  async function delTemplet(name) {
    if (window.confirm(data.AUS)) {
      const res = await hitAxios({
        path: "/api/user/del_meta_templet",
        post: true,
        admin: false,
        obj: { name },
      });
      if (res.data.success) {
        getTemplets();
      }
    }
  }

  return (
    <div>
      <Box mt={2} borderRadius={4} bgcolor={"white"}>
        {templetList ? (
          <Box height={hide ? "76vh" : "48vh"}>
            <DataGrid
              getRowId={(row) => row._id}
              rows={templetList}
              columns={[
                {
                  headerName: data.id,
                  field: "id",
                  flex: 1,
                  renderCell: (dataa) => {
                    return (
                      <Tooltip title={dataa.row?.id}>
                        {dataa.row?.actualMetaTemplet?.id}
                      </Tooltip>
                    );
                  },
                },
                {
                  headerName: data?.name,
                  field: "name",
                  flex: 1,
                  renderCell: (dataa) => {
                    return (
                      <Tooltip title={dataa.row?.actualMetaTemplet?.name}>
                        {dataa.row?.actualMetaTemplet?.name}
                      </Tooltip>
                    );
                  },
                },
                {
                  headerName: data?.language,
                  field: "language",
                  flex: 1,
                  renderCell: (dataa) => {
                    return (
                      <Tooltip title={dataa.row?.actualMetaTemplet?.language}>
                        {dataa.row?.actualMetaTemplet?.language}
                      </Tooltip>
                    );
                  },
                },
                {
                  headerName: data.category,
                  field: "category",
                  flex: 1,
                  renderCell: (dataa) => {
                    return (
                      <Tooltip title={dataa.row?.actualMetaTemplet?.category}>
                        {dataa.row?.actualMetaTemplet?.category}
                      </Tooltip>
                    );
                  },
                },
                {
                  headerName: data.status,
                  field: "status",
                  flex: 1,
                  renderCell: (dataa) => {
                    return (
                      <>
                        {dataa?.row?.actualMetaTemplet?.status ===
                          "APPROVED" && (
                          <Tooltip title={data.approved}>
                            <CheckCircle
                              sx={{ color: "green", height: 20, width: 20 }}
                            />
                          </Tooltip>
                        )}
                        {dataa?.row?.actualMetaTemplet?.status ===
                          "PENDING" && (
                          <Tooltip title={data.pendingUppercase}>
                            <HourglassTopOutlined
                              sx={{ color: "orange", height: 20, width: 20 }}
                            />
                          </Tooltip>
                        )}
                        {dataa?.row?.actualMetaTemplet?.status ===
                          "REJECTED" && (
                          <Tooltip title={data.rejected}>
                            <ThumbDownOffAlt
                              sx={{ color: "red", height: 20, width: 20 }}
                            />
                          </Tooltip>
                        )}
                      </>
                    );
                  },
                },
                {
                  headerName: data.delete,
                  field: "delete",
                  flex: 1,
                  renderCell: (dataa) => {
                    return (
                      <IconButton
                        onClick={() => delTemplet(dataa.row?.name)}
                        color="error"
                      >
                        <DeleteOutline />
                      </IconButton>
                    );
                  },
                },
              ]}
              pageSize={50}
              rowsPerPageOptions={[50]}
              checkboxSelection={false}
              // components={{
              //     Toolbar: CustomToolbar
              // }}
              sx={{
                boxShadow: 0,
                borderRadius: 4,
                border: 0,
                fontSize: 13,
                padding: 2,
              }}
            />
          </Box>
        ) : (
          ""
        )}
      </Box>
    </div>
  );
};

export default TempletList;
