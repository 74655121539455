import React, { useContext, useEffect, useState } from "react";
import { Box, Typography, Button, Tooltip, Divider } from "@mui/material";
import { GlobalContext } from "../../context/GlobalContext";
import {
  CheckCircle,
  ElectricScooterSharp,
  HourglassTopOutlined,
  ThumbDownOffAlt,
} from "@mui/icons-material";
import TextFieldNew from "../../components/TextFieldNew";
import CountryCodeDropDown from "../../components/CountryCodeDropDown";
import { PopupModal } from "react-calendly";

const WhatsappIntegration = () => {
  const CON = useContext(GlobalContext);
  const [calendlyOpen, setCalendlyOpen] = useState(false);
  const [dataa, setStatus] = useState({
    status: "OPEN",
    mobile_with_country_code: "",
    registredTime: "",
    userId: "",
    registredTime: "",
  });

  const tryingToRegistred = async () => {
    setStatus({
      ...dataa,
      status: "PENDING",
      userId: JSON.parse(localStorage.getItem("myData"))._id,
      registredTime: Date.now(),
    });

    const res = await CON.hitAxios({
      path: "/api/user/meta-registered",
      post: true,
      admin: false,
      obj: {
        ...dataa,
        status: "PENDING",
        userId: JSON.parse(localStorage.getItem("myData"))._id,
        registredTime: Date.now(),
      },
    });
    if (res.data.success) {
      CON.fetchMyDetails();
    }
  };
  const launchFacebookPopup = (mobile_with_country_code) => {
    // fbq &&
    //   fbq("trackCustom", "WhatsAppOnboardingStart", {
    //     appId: "your-facebook-app-id",
    //     feature: "whatsapp_embedded_signup",
    //   });

    window.FB.login(
      function (response) {
        console.log("response-FB", response);
        if (response.authResponse) {
          const accessToken = response.authResponse.accessToken;
          // fetchUserData(response.authResponse.accessToken);
          // Use this token to call the debug_token API and get the shared WABA's ID
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      },
      {
        config_id: "709854517281965", // configuration ID obtained in the previous step goes here
        response_type: "code", // must be set to 'code' for System User access token
        override_default_response_type: true,
        scope:
          "business_management, whatsapp_business_management, whatsapp_business_messaging",
        extras: {
          feature: "whatsapp_embedded_signup",
          version: 1,
          sessionInfoVersion: 3, //  Receive Session Logging Info
          setup: {
            solutionID: "806195184600854",
            // business: {
            //   // name: 'Acme Inc.',
            //   email: "johndoe@acme.com",
            //   phone: {
            //     code: 1,
            //     number: "6505551234",
            //   },
            //   website: "https://www.acme.com",
            //   // address: {
            //   //   streetAddress1: '1 Acme Way',
            //   //   city: 'Acme Town',
            //   //   state: 'CA',
            //   //   zipPostal: '94000',
            //   //   country: 'US'
            //   // },
            //   timezone: "UTC-08:00",
            // },
            // phone: {
            //   displayName: "Acme Inc",
            //   category: "ENTERTAIN",
            //   description: "Acme Inc. is a leading entertainment company.",
            // },
            // business: {
            //   phone: {
            //     code: 1,
            //     number: mobile_with_country_code,
            //   },
            // },
          },
        },
      }
    );
  };
  const getTryingToLoginStatus = async () => {
    const res = await CON.hitAxios({
      path: "/api/user/get/meta-registered",
      post: true,
      admin: false,
      obj: {
        userId: JSON.parse(localStorage.getItem("myData"))._id,
      },
    });
    if (res.data.success) {
      if (res.data.data) {
        setStatus(res.data.data);
      }
    }
  };
  const sessionInfoListener = (event) => {
    if (event.origin == null) {
      return;
    }

    // Make sure the data is coming from facebook.com
    if (!event.origin.endsWith("facebook.com")) {
      return;
    }

    try {
      const data = JSON.parse(event.data);
      if (data.type === "WA_EMBEDDED_SIGNUP") {
        // if user finishes the Embedded Signup flow
        if (data.event === "FINISH") {
          const { phone_number_id, waba_id } = data.data;
          console.log(
            "Phone number ID ",
            phone_number_id,
            " WhatsApp business account ID ",
            waba_id
          );
        }
        // if user reports an error during the Embedded Signup flow
        else if (data.event === "ERROR") {
          const { error_message } = data.data;
          console.error("error ", error_message);
        }
        // if user cancels the Embedded Signup flow
        else {
          const { current_step } = data.data;
          console.warn("Cancel at ", current_step);
        }
      }
    } catch {
      // Don’t parse info that’s not a JSON
      console.log("Non JSON Response", event.data);
    }
  };
  const refreshStatus = async () => {
    let myData = JSON.parse(localStorage.getItem("myData"));
    const res = await CON.hitAxios({
      path: "/api/user/checkMetaRegistration",
      post: true,
      admin: false,
      obj: {
        userId: myData._id,
      },
    });
    if (res.data.success) {
      setStatus(res.data.data);
      if (res.data.data.status == "FINISHED") {
        localStorage.setItem(
          "myData",
          JSON.stringify({ ...myData, isMetaRegistred: true })
        );
      }
    }
  };
  useEffect(() => {
    // Load the JavaScript SDK asynchronously
    window.addEventListener("message", sessionInfoListener);
    getTryingToLoginStatus();
  }, []);

  return (
    <Box>
      <Box display={"flex"} justifyContent={"flex-end"}>
        {" "}
        <Box display={"flex"} gap="10px" alignItems={"center"}>
          {" "}
          <>
            {dataa?.status === "APPROVED" && (
              <Tooltip title={"APPROVED"}>
                <CheckCircle sx={{ color: "green", height: 20, width: 20 }} />
              </Tooltip>
            )}
            {dataa?.status === "PENDING" && (
              <Tooltip title={"PENDING"}>
                <HourglassTopOutlined
                  sx={{ color: "orange", height: 20, width: 20 }}
                />
              </Tooltip>
            )}
            {dataa?.status === "REJECTED" && (
              <Tooltip title={"FAILED"}>
                <ThumbDownOffAlt sx={{ color: "red", height: 20, width: 20 }} />
              </Tooltip>
            )}
          </>
          {dataa.status != "OPEN" && (
            <>
              {" "}
              {dataa?.status}
              <Button
                variant="contained"
                color="primary"
                onClick={refreshStatus}
              >
                Refresh Status
              </Button>
            </>
          )}
        </Box>
      </Box>
      <Typography variant="h6">Integrate Whatsapp Business Api</Typography>
      <Typography variant="body1">
        Integrating the WhatsApp Business API can be highly beneficial for
        businesses, depending on their specific needs and communication
        strategies.
      </Typography>
      <Box
        sx={{
          display: "flex",
          marginTop: "20px",
          gap: "10px",
        }}
      >
        <CountryCodeDropDown
          state={dataa}
          setState={setStatus}
          width="38%"
          helperText={
            "Enter a phone number that is not registered with any WhatsApp account."
          }
          label={"Whatsapp number"}
        />

        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            tryingToRegistred();
            launchFacebookPopup(dataa.mobile_with_country_code);
          }}
        >
          Login with facebook
        </Button>
      </Box>
      <Box marginTop={2}>
        <Divider />
      </Box>
      <Box padding={2} className="popup-button-container">
        {" "}
        Need help? Schedule a meeting with us.{" "}
        <Button
          variant="contained"
          color="info"
          onClick={() => {
            setCalendlyOpen(true);
          }}
        >
          Click Here to Schedule
        </Button>
      </Box>
      <PopupModal
        url="https://calendly.com/ulai/30min?hide_landing_page_details=1&hide_gdpr_banner=1'"
        onModalClose={() => setCalendlyOpen(!calendlyOpen)}
        open={calendlyOpen}
        rootElement={document.getElementById("root")}
        text="Book Demo"
        textColor="#ffffff"
      />{" "}
    </Box>
  );
};

export default WhatsappIntegration;
