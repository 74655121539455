import {
  CardActionArea,
  Chip,
  Box,
  Container,
  Divider,
  Grid,
  Skeleton,
  Stack,
  Typography,
  Tooltip,
} from "@mui/material";
import { FcAlarmClock } from "react-icons/fc";
import React from "react";
import { TranslateContext } from "../../context/TranslateContext";
import { GlobalContext } from "../../context/GlobalContext";
import NoDataFound from "../../components/NoDataFound";
import TotalProductAddedToCart from "./TotalProductAddedToCart";
import ProductAddedToCartPerDay from "./ProductAddedToCartPerDay";
import AbandonedCart from "./AbandonedCart";
import AbandonedPayment from "./AbandonedPayment";
import TotalEngagement from "./TotalEngagement";
import BotVsUser from "./Botvsuser";
import TotalBounceRate from "./TotalBounce";
import GoalCompletion from "./GoalCompletion";
import UserIntentsComponent from "./UserIntentsComponent";
import ChatInsightsComponent from "./ChatInsightsComponent";
import UrlAccessedComponent from "./UrlAccessedComponent";
import CounterNumber from "../../components/CounterNumber";

const Dashboard = (props) => {
  const userData = JSON.parse(localStorage.getItem("myData"));

  const { data } = React.useContext(TranslateContext);
  const G = React.useContext(GlobalContext);

  const [userEngagementData, setUserEngagementData] = React.useState([]);
  const [totalProductAddedToCart, setTotalProductAddedToCart] = React.useState(
    []
  );
  const [productAddedToCartPerDay, setProductAddedToCartPerDay] =
    React.useState([]);
  const [totalAbandonedCart, setTotalAbandonedCart] = React.useState([]);
  const [abandonedCartPerDay, setAbandonedCartPerDay] = React.useState([]);
  const [totalAbandonedPayment, setTotalAbandonedPayment] = React.useState([]);
  const [totalUserEngagementData, setTotalUserEngagement] = React.useState({
    array: [],
    totalOutgoingAgentMessages: 0,
  });
  const [goalCompletionData, setGoalCompletionData] = React.useState({
    whatsapp_order_select_cashOnDelivery: 0,
    whatsapp_cart: 0,
    whatsapp_checkout_init: 0,
    totalSession: 0,
    remainingSession: 0,
  });
  const [totalBotVsUser, setTotalBotVsUser] = React.useState([]);
  const [totalBounce, setTotalBounce] = React.useState({});
  const [abandonedPaymentPerDay, setAbandonedPaymentPerDay] = React.useState(
    []
  );
  const [userIntentData, setUserIntentData] = React.useState([]);
  const [chatInsightsData, setChatInsightsData] = React.useState([]);
  const [urlAccessedData, setUrlAccessedData] = React.useState([]);
  const [usedTokenData, setUsedTokenData] = React.useState({})

  async function getUserEngagementData() {
    const res = await G.hitAxios({
      path:
        process.env.REACT_APP_BACKEND_BASE_URL +
        "/backend/analytics/" +
        userData?._id +
        "/average-chat-duration-bot-engagement/data",
      post: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.status === 200) {
      setUserEngagementData(res?.data?.data[0]);
    }
  }

  const getTotalProductAddedToCart = async () => {
    const res = await G.hitAxios({
      path:
        process.env.REACT_APP_BACKEND_BASE_URL +
        "/backend/analytics/" +
        userData?._id +
        "/products-added-to-cart-total/data",
      post: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.status === 200) {
      setTotalProductAddedToCart(res?.data?.data);
    }
  };

  const getProductAddedToCartPerDay = async () => {
    const res = await G.hitAxios({
      path:
        process.env.REACT_APP_BACKEND_BASE_URL +
        "/backend/analytics/" +
        userData?._id +
        "/products-added-to-per-day/data?days=30",
      post: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.status === 200) {
      setProductAddedToCartPerDay(res?.data?.data);
    }
  };

  const getTotalAbandonedCart = async () => {
    const res = await G.hitAxios({
      path:
        process.env.REACT_APP_BACKEND_BASE_URL +
        "/backend/analytics/" +
        userData?._id +
        "/abandoned-carts-total/data",
      post: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.status === 200) {
      setTotalAbandonedCart(res?.data?.data);
    }
  };

  const getAbandonedCartPerDay = async () => {
    const res = await G.hitAxios({
      path:
        process.env.REACT_APP_BACKEND_BASE_URL +
        "/backend/analytics/" +
        userData?._id +
        "/abandoned-carts-per-day/data?days=30",
      post: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.status === 200) {
      setAbandonedCartPerDay(res?.data?.data);
    }
  };

  const getTotalAbandonedPayment = async () => {
    const res = await G.hitAxios({
      path:
        process.env.REACT_APP_BACKEND_BASE_URL +
        "/backend/analytics/" +
        userData?._id +
        "/abandoned-payments-total/data",
      post: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.status === 200) {
      setTotalAbandonedPayment(res?.data?.data);
    }
  };
  const getTotalUserEngagement = async () => {
    const res = await G.hitAxios({
      path:
        process.env.REACT_APP_BACKEND_BASE_URL +
        "/backend/analytics/" +
        userData?._id +
        "/total-engagement/data?days=30",
      post: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    const ress = await G.hitAxios({
      path:
        process.env.REACT_APP_BACKEND_BASE_URL +
        "/backend/analytics/" +
        userData?._id +
        "/total-msg-agent/data",
      post: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.status === 200) {
      if (res.data.data.length != 0) {
        setTotalUserEngagement({
          array: res?.data?.data,
          totalOutgoingAgentMessages: ress.data.data[0]
            ?.totalOutgoingAgentMessages
            ? ress.data.data[0]?.totalOutgoingAgentMessages
            : 0,
        });
      }
    }
  };
  const getGoalCompletion = async () => {
    const res = await G.hitAxios({
      path:
        process.env.REACT_APP_BACKEND_BASE_URL +
        "/backend/analytics/" +
        userData?._id +
        "/goal-completion-rate/data",
      post: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.status === 200) {
      console.log("data", res.data);
      setGoalCompletionData(res?.data?.data);
    }
  };
  const getTotalBotVsUser = async () => {
    const res = await G.hitAxios({
      path:
        process.env.REACT_APP_BACKEND_BASE_URL +
        "/backend/analytics/" +
        userData?._id +
        "/total-agent-interaction-conversations/data?days=30",
      post: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.status === 200) {
      if (res?.data?.data.length > 0) {
        setTotalBotVsUser(res?.data?.data);
      }
    }
  };
  const getTotalBounce = async () => {
    const res = await G.hitAxios({
      path:
        process.env.REACT_APP_BACKEND_BASE_URL +
        "/backend/analytics/" +
        userData?._id +
        "/total-bounce-rate/data",
      post: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.status === 200) {
      setTotalBounce(res?.data?.data);
    }
  };

  const getAbandonedPaymentPerDay = async () => {
    const res = await G.hitAxios({
      path:
        process.env.REACT_APP_BACKEND_BASE_URL +
        "/backend/analytics/" +
        userData?._id +
        "/abandoned-payments-per-day/data?days=30",
      post: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.status === 200) {
      setAbandonedPaymentPerDay(res?.data?.data);
    }
  };

  const getUserIntentData = async () => {
    const res = await G.hitAxios({
      path:
        process.env.REACT_APP_BACKEND_BASE_URL +
        "/backend/analytics/" +
        userData?._id +
        "/user-intents/data",
      post: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.status === 200) {
      setUserIntentData(res.data.data);
    }
  };

  const getChatInsightsData = async () => {
    const res = await G.hitAxios({
      path:
        process.env.REACT_APP_BACKEND_BASE_URL +
        "/backend/analytics/" +
        userData?._id +
        "/chat-insights/data",
      post: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.status === 200) {
      setChatInsightsData(res.data.data);
    }
  };

  const getUrlAccessedData = async () => {
    const res = await G.hitAxios({
      path:
        process.env.REACT_APP_BACKEND_BASE_URL +
        "/backend/url/analytics/" +
        userData?._id,
      post: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.status === 200) {
      setUrlAccessedData(res.data.data);
    }
  };

  const getUsedTokenData = async () => {
    const res = await G.hitAxios({
      path: process.env.REACT_APP_BACKEND_BASE_URL + "/backend/tokens/usages/" + userData?._id ,
      post: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    })
    if (res.status === 200) {
      setUsedTokenData(res.data.data);
    }
  }

  React.useEffect(() => {
    getUserIntentData();
    getChatInsightsData();
    getGoalCompletion();
    getTotalBounce();
    getTotalBotVsUser();
    getUserEngagementData();
    getTotalProductAddedToCart();
    getProductAddedToCartPerDay();
    getTotalAbandonedCart();
    getAbandonedCartPerDay();
    getTotalAbandonedPayment();
    getAbandonedPaymentPerDay();
    getTotalUserEngagement();
    getUrlAccessedData();
    getUsedTokenData();
  }, []);

  function convertMsToTime(ms) {
    let seconds = Math.floor((ms / 1000) % 60);
    let minutes = Math.floor((ms / (1000 * 60)) % 60);
    let hours = Math.floor((ms / (1000 * 60 * 60)) % 24);

    // Padding the numbers with leading zeros if needed
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    let timeString = hours + " : ";
    timeString += minutes ? minutes + " : " : "";
    timeString += seconds + "";

    return timeString;
  }

  return (
    <Container maxWidth="lg">
      <Grid container minHeight={"4vh"} mt={2} borderRadius={2} bgcolor={"white"} p={2}>
        {/* // Apply loader */}
        <Grid
          item xs={12} sm={6}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          gap="10px"
          fontSize={"18px"}
          flexDirection={"column"}
        >
          Average User Engagement Time
          <Box
            padding={"5px"}
            fontSize={"24px"}
            borderRadius={"10px"}
            border={"5px solid transparent"}
            id="clock"
          >
            {userEngagementData?.average_user_engagement_time_ms ? convertMsToTime(
              userEngagementData?.average_user_engagement_time_ms
            ) : <><FcAlarmClock style={{animation: 'nudge .4s linear infinite'}} /> : <FcAlarmClock style={{animation: 'nudge .4s linear infinite'}} /> : <FcAlarmClock style={{animation: 'nudge .4s linear infinite'}} /> </> }
          </Box>
        </Grid>
        <Grid
          item xs={12} sm={6}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          gap="10px"
          fontSize={"18px"}
          flexDirection={"column"}
        >
          Total Used Tokens
          <Typography variant="body1" align="center" fontSize={"25px"}>
            <CounterNumber number={usedTokenData?.total} />
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid xs={12} lg={6} sm={6} item>
          <Box
            minHeight={"40vh"}
            mt={2}
            borderRadius={2}
            bgcolor={"white"}
            p={2}
          >
            {G.data.loading ? (
              <Stack height={"40vh"}>
                {Array(5)
                  .fill(null)
                  .map((_, key) => (
                    <Skeleton height={53} key={key} animation="wave" />
                  ))}
              </Stack>
            ) : totalUserEngagementData.array.length > 0 ? (
              <TotalEngagement
                data={data}
                totalEngagementData={totalUserEngagementData}
              />
            ) : (
              <NoDataFound title="Total User Engagement (Total : 0)" />
            )}
          </Box>
        </Grid>
        <Grid xs={12} lg={6} sm={6} item>
          <Box
            minHeight={"40vh"}
            mt={2}
            borderRadius={2}
            bgcolor={"white"}
            p={2}
          >
            {G.data.loading ? (
              <Stack height={"40vh"}>
                {Array(5)
                  .fill(null)
                  .map((_, key) => (
                    <Skeleton height={53} key={key} animation="wave" />
                  ))}
              </Stack>
            ) : totalBotVsUser.length > 0 ? (
              <BotVsUser data={data} totalBotVsUser={totalBotVsUser} />
            ) : (
              <NoDataFound title="Query Resolve Bot Vs User" />
            )}
          </Box>
        </Grid>
      </Grid>
      <Box minHeight={"40vh"} mt={2} borderRadius={2} bgcolor={"white"} p={2}>
        {G.data.loading ? (
          <Stack height={"40vh"}>
            {Array(5)
              .fill(null)
              .map((_, key) => (
                <Skeleton height={53} key={key} animation="wave" />
              ))}
          </Stack>
        ) : totalProductAddedToCart.length > 0 ? (
          <TotalProductAddedToCart
            data={data}
            totalProductAddedToCart={totalProductAddedToCart}
          />
        ) : (
          <NoDataFound title="Total Product added to cart" />
        )}
      </Box>

      <Grid container spacing={2}>
        <Grid xs={12} lg={6} sm={6} item>
          {" "}
          <Box
            minHeight={"40vh"}
            mt={2}
            borderRadius={2}
            bgcolor={"white"}
            p={2}
          >
            {G.data.loading ? (
              <Stack height={"40vh"}>
                {Array(5)
                  .fill(null)
                  .map((_, key) => (
                    <Skeleton height={53} key={key} animation="wave" />
                  ))}
              </Stack>
            ) : productAddedToCartPerDay.length > 0 ? (
              <ProductAddedToCartPerDay
                data={data}
                productAddedToCartPerDay={productAddedToCartPerDay}
              />
            ) : (
              <NoDataFound title="Product added to cart per day" />
            )}
          </Box>
        </Grid>
        <Grid xs={12} lg={6} sm={6} item>
          <Box
            minHeight={"45vh"}
            mt={2}
            borderRadius={2}
            bgcolor={"white"}
            p={2}
          >
            {G.data.loading ? (
              <Stack height={"40vh"}>
                {Array(5)
                  .fill(null)
                  .map((_, key) => (
                    <Skeleton height={53} key={key} animation="wave" />
                  ))}
              </Stack>
            ) : goalCompletionData.totalSession > 0 ? (
              <GoalCompletion
                data={data}
                goalCompletionData={goalCompletionData}
              />
            ) : (
              <NoDataFound title="Goal Completion" />
            )}
          </Box>
        </Grid>
      </Grid>
      <Box minHeight={"40vh"} mt={2} borderRadius={2} bgcolor={"white"} p={2}>
        {G.data.loading ? (
          <Stack height={"40vh"}>
            {Array(5)
              .fill(null)
              .map((_, key) => (
                <Skeleton height={53} key={key} animation="wave" />
              ))}
          </Stack>
        ) : abandonedCartPerDay.length > 0 ? (
          <AbandonedCart
            data={data}
            totalAbandonedCart={totalAbandonedCart}
            abandonedCartPerDay={abandonedCartPerDay}
          />
        ) : (
          <NoDataFound title="Abandoned carts per day (Total : 0)" />
        )}
      </Box>
      <Grid container spacing={2}>
        <Grid xs={12} lg={6} sm={6} item>
          <Box
            minHeight={"40vh"}
            mt={2}
            borderRadius={2}
            bgcolor={"white"}
            p={2}
          >
            {G.data.loading ? (
              <Stack height={"40vh"}>
                {Array(5)
                  .fill(null)
                  .map((_, key) => (
                    <Skeleton height={53} key={key} animation="wave" />
                  ))}
              </Stack>
            ) : abandonedPaymentPerDay.length > 0 ? (
              <AbandonedPayment
                data={data}
                totalAbandonedPayment={totalAbandonedPayment}
                abandonedPaymentPerDay={abandonedPaymentPerDay}
              />
            ) : (
              <NoDataFound title="Abandoned payment per day (Total : 0)" />
            )}
          </Box>
        </Grid>
        <Grid xs={12} lg={6} sm={6} item>
          <Box
            minHeight={"40vh"}
            mt={2}
            borderRadius={2}
            bgcolor={"white"}
            p={2}
          >
            {G.data.loading ? (
              <Stack height={"40vh"}>
                {Array(5)
                  .fill(null)
                  .map((_, key) => (
                    <Skeleton height={53} key={key} animation="wave" />
                  ))}
              </Stack>
            ) : totalBotVsUser.length > 0 ? (
              <TotalBounceRate data={data} totalBounce={totalBounce} />
            ) : (
              <NoDataFound title="Bounce Rate" />
            )}
          </Box>
        </Grid>
      </Grid>

      <Box minHeight={"40vh"} mt={2} borderRadius={2} bgcolor={"white"} p={2}>
        {G.data.loading ? (
          <Stack height={"40vh"}>
            {Array(5)
              .fill(null)
              .map((_, key) => (
                <Skeleton height={53} key={key} animation="wave" />
              ))}
          </Stack>
        ) : chatInsightsData?.length > 0 ? (
          <ChatInsightsComponent chatInsightsData={chatInsightsData} />
        ) : (
          <NoDataFound title="Top Chat Insights" />
        )}
      </Box>
      <Box minHeight={"40vh"} mt={2} borderRadius={2} bgcolor={"white"} p={2}>
        {G.data.loading ? (
          <Stack height={"40vh"}>
            {Array(5)
              .fill(null)
              .map((_, key) => (
                <Skeleton height={53} key={key} animation="wave" />
              ))}
          </Stack>
        ) : urlAccessedData?.length > 0 ? (
          <UrlAccessedComponent urlAccessedData={urlAccessedData} />
        ) : (
          <NoDataFound title="Url Accessed Data" subTitle="Url shortner is not used" />
        )}
      </Box>
      <Box minHeight={"40vh"} mt={2} borderRadius={2} bgcolor={"white"} p={2}>
        {G.data.loading ? (
          <Stack height={"40vh"}>
            {Array(5)
              .fill(null)
              .map((_, key) => (
                <Skeleton height={53} key={key} animation="wave" />
              ))}
          </Stack>
        ) : userIntentData?.length > 0 ? (
          <UserIntentsComponent userIntentData={userIntentData} />
        ) : (
          <NoDataFound title="Chat Keywords by Volume" />
        )}
      </Box>
    </Container>
  );
};

export default Dashboard;
