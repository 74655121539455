import React, { useState } from "react";
import {
  Settings,
  Share,
  IntegrationInstructionsOutlined,
} from "@mui/icons-material";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { FaWhatsapp } from "react-icons/fa";
import CustomToggleButton from "../../components/CustomToggleButton";
import WhatsappSetting from "./WhatsappSetting";
import WhatsappShare from "./WhatsappShare";
import WhatsappIntegration from "./WhatsappIntegration";
import { GlobalContext } from "../../context/GlobalContext";

const Whatsapp = ({ hitAxios, userData, data }) => {
  const CON = React.useContext(GlobalContext);
  const buttonValues = [
    { value: "Settings", icon: <Settings /> },
    { value: "Share", icon: <Share /> },
    { value: "Integration", icon: <IntegrationInstructionsOutlined /> },
  ];
  const [selectedOption, setSelectedOption] = React.useState(
    buttonValues[0].value ?? null
  );

  const [webWidgetCustomization, setWidgetCustomization] = React.useState();
  const [whatsappBrandSetting, setWhatsappBrandSetting] = React.useState();
  const [whatsappChatButtonSetting, setWhatsappChatButtonSetting] =
    React.useState();

  const getWidgetCustomization = async () => {
    const res = await hitAxios({
      path:
        process.env.REACT_APP_BACKEND_BASE_URL +
        "/backend/widget-customization/" +
        userData?._id,
      post: false,
      admin: false,
      token_user: "",
    });
    if (res.status === 200) {
      setWidgetCustomization(res?.data?.data);
      setWhatsappBrandSetting(res?.data?.data?.brandSetting);
      setWhatsappChatButtonSetting(res?.data?.data?.chatButtonSetting);
    }
  };

  const handleSubmitUpdateWhatsapp = async () => {
    if (
      whatsappBrandSetting.brandName == "" ||
      whatsappBrandSetting.brandSubTitle == "" ||
      whatsappBrandSetting.welcomeText == "" ||
      whatsappBrandSetting.messageText == "" ||
      whatsappBrandSetting.backgroundColor == "" ||
      whatsappChatButtonSetting.backgroundColor == "" ||
      whatsappChatButtonSetting.marginLeft == "" ||
      whatsappChatButtonSetting.marginRight == "" ||
      whatsappChatButtonSetting.marginBottom == "" ||
      whatsappChatButtonSetting.marginRightMobile == "" ||
      whatsappChatButtonSetting.marginBottomMobile == "" ||
      whatsappBrandSetting.ctaText == "" ||
      whatsappChatButtonSetting.ctaText == ""
    ) {
      CON.setData({
        ...CON.data,
        snack: true,
        snack_msg: "Required Parameter is Missing !",
        snack_success: false,
      });
      setTimeout(() => {
        CON.setData({
          ...CON.data,
          snack: false,
          snack_msg: "",
          snack_success: false,
        });
      }, 3000);
      return;
    }
    const res = await hitAxios({
      path:
        process.env.REACT_APP_BACKEND_BASE_URL +
        "/backend/widget-customization/" +
        webWidgetCustomization._id,
      post: false,
      admin: false,
      token_user: "",
      patch: true,
      obj: {
        ...webWidgetCustomization,
        brandSetting: { ...whatsappBrandSetting },
        chatButtonSetting: { ...whatsappChatButtonSetting },
      },
    });

    if (res.status === 200) {
      getWidgetCustomization();
    }
  };
  React.useEffect(() => {
    getWidgetCustomization();
  }, []);
  return (
    <Box m={3} p={2} bgcolor={"white"} borderRadius={2}>
      <Stack direction={"column"} spacing={2}>
        {userData?.isMetaRegistred ? (
          <>
            <Stack direction={"row"} spacing={2} alignItems={"center"}>
              <FaWhatsapp
                style={{ width: "25px", height: "25px", color: "#7C7C7C" }}
              />
              <Typography variant="h6" color={"#7C7C7C"} fontWeight={600}>
                Whatsapp
              </Typography>
              <CustomToggleButton
                buttonValues={buttonValues}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
              />
            </Stack>
            <Grid container p={1}>
              {selectedOption === "Settings" && (
                <WhatsappSetting
                  whatsappBrandSetting={whatsappBrandSetting}
                  setWhatsappBrandSetting={setWhatsappBrandSetting}
                  whatsappChatButtonSetting={whatsappChatButtonSetting}
                  setWhatsappChatButtonSetting={setWhatsappChatButtonSetting}
                  handleSubmitUpdateWhatsapp={handleSubmitUpdateWhatsapp}
                  getWidgetCustomization={getWidgetCustomization}
                  webWidgetCustomization={webWidgetCustomization}
                  data={data}
                />
              )}
              {selectedOption === "Share" && (
                <WhatsappShare id={userData?._id} />
              )}
              {selectedOption === "Integration" && <WhatsappIntegration />}
            </Grid>
          </>
        ) : (
          <WhatsappIntegration />
        )}
      </Stack>
    </Box>
  );
};

export default Whatsapp;
