import React from 'react'
import TextFieldNew from '../../../../components/TextFieldNew'
import { Chip, IconButton, Stack } from '@mui/material'
import { AddOutlined } from '@mui/icons-material'

const AddQuick = ({ data, setStates, states, setQuickReply, actionBtn, quickReply }) => {
    return (
        <>
            <Stack direction={'row'} spacing={1}>
                {quickReply?.map((i, key) => {
                    return (
                        <Chip
                            color='primary'
                            onDelete={() => {
                                const newOne = quickReply.filter((ii) => ii.text !== i?.text)
                                setQuickReply(newOne)
                            }}
                            label={i?.text} key={key} />
                    )
                })}
            </Stack>

            <TextFieldNew
                label={data.btnText}
                value={states?.quickReplyTitle}
                sx={{
                    "& fieldset": { border: 'none' },
                }}
                onChange={(e) => {
                    const inputText = e.target.value.slice(0, 20); // Limiting input to 1024 characters
                    setStates({ ...states, quickReplyTitle: inputText });
                }}
                helperText={`${states.quickReplyTitle.length}/${data.allowed20}`}


                bgColor={'#f5f7fb'}
                fullWidth
                endIcon={quickReply?.length + actionBtn?.length < 2 &&
                    <IconButton
                        onClick={() => {
                            setQuickReply([...quickReply, {
                                type: "QUICK_REPLY",
                                text: states?.quickReplyTitle
                            }])
                            setStates({ ...states, quickReplyTitle: "" })
                        }}
                        disabled={states.quickReplyTitle ? false : true}
                    >
                        <AddOutlined />
                    </IconButton>}
            />
        </>
    )
}

export default AddQuick