import AddIcon from "@mui/icons-material/Add";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { GlobalContext } from "../../../../context/GlobalContext";
import ValuesOption from "../valuesOption";
const useStyles = makeStyles((theme) => ({
  scrollableBox: {
    overflow: "hidden",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#51d785",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#51d785",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#22a454",
    },
  },
}));
const TempletList = ({
  data,
  screenWidth,
  templetList,
  setTempletList,
  getTemplets,
  setState,
  listTitle,
  state,
  webhookType,
}) => {
  const [hide, setHide] = React.useState(
    localStorage.getItem("hide_templet_header") ? true : false
  );
  const classes = useStyles();
  return (
    <>
      {" "}
      {templetList ? (
        <Box position={"relative"} height={"100%"}>
          <Box
            height={"30px"}
            display={"flex"}
            alignItems={"flex-end"}
            style={{ borderTopLeftRadius: "2" }}
            paddingLeft={"15px"}
            paddingBottom={"5px"}
            fontWeight={"500"}
            fontSize={"18px"}
            gap="10px"
          >
            <Box
              textOverflow={"ellipsis"}
              whiteSpace={"nowrap"}
              overflow={"hidden"}
            >
              {" "}
              {listTitle}
            </Box>{" "}
          </Box>
          <Box
            maxHeight={screenWidth > 899 ? "75vh" : "27vh"}
            style={{ overflow: "hidden", overflowY: "scroll" }}
            className={classes.scrollableBox}
          >
            {templetList.map((el) => {
              return (
                <ValuesOption
                  handleFunc={setState}
                  state={state}
                  data={el}
                  listTitle={listTitle}
                  webhookType={webhookType}
                />
              );
            })}
            <Box
              height={"40px"}
              display={"flex"}
              alignItems={"center"}
              gap={"10px"}
              style={{ borderTopLeftRadius: "2", cursor: "pointer" }}
              paddingLeft={"15px"}
              fontWeight={"500"}
              fontSize={"16px"}
              position={"sticky"}
              bottom={"0"}
              width={"100%"}
              bgcolor={"#22a454"}
              onClick={() =>
                setState({
                  ...state,
                  templetData: {
                    name: "",
                    url: "",
                    templetFor: listTitle,
                    mediaType: "IMAGE",
                    headerText: "",
                    bodyMsg: "",
                    footerMsg: "",
                    btnType: "NONE",
                    quickReplyTitle: "",
                    actionUrl: "",
                    btnTitle: "",
                    phoneNum: "",
                    category: "UTILITY",
                    language: "",
                    webhookType: webhookType,
                  },
                  addDialog: true,
                })
              }
            >
              <AddIcon /> Add Templet
            </Box>
          </Box>
        </Box>
      ) : (
        ""
      )}
    </>
  );
};

export default TempletList;
