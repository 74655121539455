import { Box, Button, Dialog, Stack, Switch, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import CloseBtn from "../../context/CloseBtn";
import TextFieldNew from "../../components/TextFieldNew";
import ButtonComp from "../../context/ButtonComp";
import { GlobalContext } from "../../context/GlobalContext";
import { AiFillDelete, AiFillPlusCircle } from "react-icons/ai";
import {
  AddShoppingCart,
  Cancel,
  CancelOutlined,
  CheckCircle,
  CheckCircleOutline,
  DeleteOutline,
} from "@mui/icons-material";
const AddPlan = ({ data, state, setState, getPlan }) => {
  const [whatsappfeature, setWhatsappFeature] = React.useState("");
  const [aifeature, setAiFeature] = React.useState("");
  const [dashboardfeatures, setDashboardFeature] = React.useState("");
  const [agentfeature, setAgentFeatures] = React.useState("");
  const [supportfeature, setSupportFeature] = React.useState("");
  const [integrationfeature, setIntegrationFeature] = React.useState("");
  const [channelsfeature, setChannelFeature] = React.useState("");
  // const [sufeature, setSupportFeature] = React.useState("");
  const [isEdit, setIsEdit] = React.useState(false);
  const [isInclude, setIsinclude] = React.useState(false);
  const { hitAxios } = React.useContext(GlobalContext);
  const CON = React.useContext(GlobalContext);
  const [plan, setPlan] = React.useState({
    allow_tag: false,
    allow_note: false,
    allow_chatbot: false,
    allow_api: false,
    contact_limit: 100,
    is_trial: false,
    whatsapp_amount_limit: 0,
    aiFeatures: [],
    whatsappFeatures: [],
    channelsFeatures: [],
    integrationFeatures: [],
    dashboardFeatures: [],
    agentFeatures: [],
    supportFeatures: [],
    planFeatures: [],
    ai_token_limit: 0,
    agentLimit: 0,
  });

  async function addPlan() {
    const res = await hitAxios({
      path: "/api/admin/add_plan",
      post: true,
      admin: true,
      obj: plan,
    });
    if (res.data.success) {
      await getPlan();
      setState({ ...state, dialog: false });
    }
  }
  async function updatePlan() {
    const res = await hitAxios({
      path: "/api/admin/update_plan",
      post: true,
      admin: true,
      obj: { plan },
    });
    if (res.data.success) {
      await getPlan();
      setState({ ...state, dialog: false });
    }
  }
  useEffect(() => {
    setPlan(state.planData);
    setIsEdit(state.isEdit);
  }, [state]);
  return (
    <div>
      <Dialog
        sx={{ backdropFilter: "blur(10px)" }}
        onClose={() => setState({ ...state, dialog: false })}
        PaperProps={{
          style: { borderRadius: 10 },
        }}
        open={state.dialog}
        fullWidth
      >
        <Box p={3}>
          <Stack
            justifyContent="space-between"
            alignItems={"center"}
            direction="row"
          >
            <Typography variant="h6">
              {" "}
              {isEdit ? "Edit Plan" : data.addPlan}
            </Typography>
            <CloseBtn onClick={() => setState({ ...state, dialog: false })} />
          </Stack>

          <Stack mt={3} direction={"column"} spacing={2}>
            <TextFieldNew
              onChange={(e) => setPlan({ ...plan, title: e.target.value })}
              label={data.planTitle}
              value={plan.title}
            />

            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Box>
                {" "}
                <Typography variant="caption">
                  {data.isThisATrialPlan}
                </Typography>
                <Switch
                  checked={plan.is_trial}
                  onChange={(e) =>
                    setPlan({ ...plan, is_trial: e.target.checked })
                  }
                  size="small"
                />
              </Box>
              <Box>
                {" "}
                <Typography variant="caption">{"Allow Api"}</Typography>
                <Switch
                  checked={plan.allow_api}
                  onChange={(e) =>
                    setPlan({ ...plan, allow_api: e.target.checked })
                  }
                  size="small"
                />
              </Box>
              <Box>
                {" "}
                <Typography variant="caption">{"Allow Chat Bot"}</Typography>
                <Switch
                  checked={plan.allow_chatbot}
                  onChange={(e) =>
                    setPlan({ ...plan, allow_chatbot: e.target.checked })
                  }
                  size="small"
                />
              </Box>
            </Stack>

            <TextFieldNew
              onChange={(e) => setPlan({ ...plan, price: e.target.value })}
              type="number"
              value={plan.price}
              label={data.planPrice}
            />

            <TextFieldNew
              onChange={(e) =>
                setPlan({ ...plan, price_strike: e.target.value })
              }
              // type="number"
              value={plan.price_strike}
              label={data.planPriceCrosed}
              helperText={
                <span style={{ textDecoration: "line-through" }}>
                  {data.itWillShowLike}
                </span>
              }
            />

            <TextFieldNew
              onChange={(e) =>
                setPlan({ ...plan, plan_duration_in_days: e.target.value })
              }
              type="number"
              value={plan.plan_duration_in_days}
              label={data.planDurationInDays}
            />

            <TextFieldNew
              value={plan.contact_limit}
              onChange={(e) =>
                setPlan({ ...plan, contact_limit: e.target.value })
              }
              type="number"
              label={data.phoneBookContactsLImit}
            />
            <TextFieldNew
              value={plan.ai_token_limit}
              onChange={(e) =>
                setPlan({ ...plan, ai_token_limit: e.target.value })
              }
              type="number"
              label={"Ai Token Limit"}
            />
            <TextFieldNew
              value={plan.whatsapp_amount_limit}
              onChange={(e) =>
                setPlan({ ...plan, whatsapp_amount_limit: e.target.value })
              }
              type="number"
              label={"Whatsapp Amount Limit"}
            />
            <TextFieldNew
              value={plan.agentLimit}
              onChange={(e) =>
                setPlan({ ...plan, agentLimit: e.target.value })
              }
              type="number"
              label={"Agents Limit"}
            />
            <Box display={"flex"} alignItems={"center"} gap={"10px"}>
              <TextFieldNew
                fullWidth
                value={aifeature}
                placeholder="Multilingual Support (100+ Languages)"
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    if (e.target.value.length != "") {
                      setPlan({
                        ...plan,
                        aiFeatures: [...plan?.aiFeatures, { name: aifeature }],
                      });
                      setAiFeature("");
                    }
                  }
                }}
                onChange={(e) => setAiFeature(e.target.value)}
                label="Enter AI Features"
              />

              <AiFillPlusCircle
                fontSize={"35px"}
                color="#22a454"
                onClick={() => {
                  if (aifeature.length != "") {
                    setPlan({
                      ...plan,
                      aiFeatures: [...plan?.aiFeatures, { name: aifeature }],
                    });
                    setAiFeature("");
                  }
                }}
              />
            </Box>
            <Box display={"flex"} flexWrap={"wrap"}>
              {plan?.aiFeatures.map((el, index) => {
                return (
                  <Box
                    display={"flex"}
                    justifyContent={"space-around"}
                    border={"1px solid  #c8c8c8"}
                    padding={"10px"}
                    margin={"2%"}
                    gap={"10px"}
                    borderRadius={"10px"}
                    alignItems={"center"}
                  >
                    {el.name}
                    <AiFillDelete
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        let newArray = [...plan.aiFeatures];
                        newArray.splice(index, 1);
                        setPlan({
                          ...plan,
                          aiFeatures: newArray,
                        });
                      }}
                    />
                  </Box>
                );
              })}
            </Box>
            <Box display={"flex"} alignItems={"center"} gap={"10px"}>
              <TextFieldNew
                fullWidth
                value={whatsappfeature}
                placeholder="Official WhatsApp API"
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    if (e.target.value.length != "") {
                      setPlan({
                        ...plan,
                        whatsappFeatures: [
                          ...plan.whatsappFeatures,
                          { name: whatsappfeature },
                        ],
                      });
                      setWhatsappFeature("");
                    }
                  }
                }}
                onChange={(e) => setWhatsappFeature(e.target.value)}
                label="Enter Whatsapp Features"
              />

              <AiFillPlusCircle
                fontSize={"35px"}
                color="#22a454"
                onClick={() => {
                  if (whatsappfeature.length != "") {
                    setPlan({
                      ...plan,
                      whatsappFeatures: [
                        ...plan.whatsappFeatures,
                        { name: whatsappfeature },
                      ],
                    });
                    setWhatsappFeature("");
                  }
                }}
              />
            </Box>
            <Box display={"flex"} flexWrap={"wrap"}>
              {plan?.whatsappFeatures.map((el, index) => {
                return (
                  <Box
                    display={"flex"}
                    justifyContent={"space-around"}
                    border={"1px solid  #c8c8c8"}
                    padding={"10px"}
                    margin={"2%"}
                    gap={"10px"}
                    borderRadius={"10px"}
                    alignItems={"center"}
                  >
                    {el.name}
                    <AiFillDelete
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        let newArray = [...plan.whatsappFeatures];
                        newArray.splice(index, 1);
                        setPlan({
                          ...plan,
                          whatsappFeatures: newArray,
                        });
                      }}
                    />
                  </Box>
                );
              })}
            </Box>
            <Box display={"flex"} alignItems={"center"} gap={"10px"}>
              <TextFieldNew
                fullWidth
                value={integrationfeature}
                placeholder="Shopify"
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    if (e.target.value.length != "") {
                      setPlan({
                        ...plan,
                        integrationFeatures: [
                          ...plan.integrationFeatures,
                          { name: integrationfeature },
                        ],
                      });
                      setIntegrationFeature("");
                    }
                  }
                }}
                onChange={(e) => setIntegrationFeature(e.target.value)}
                label="Enter Integrations"
              />

              <AiFillPlusCircle
                fontSize={"35px"}
                color="#22a454"
                onClick={() => {
                  if (integrationfeature.length != "") {
                    setPlan({
                      ...plan,
                      integrationFeatures: [
                        ...plan.integrationFeatures,
                        { name: integrationfeature },
                      ],
                    });
                    setIntegrationFeature("");
                  }
                }}
              />
            </Box>
            <Box display={"flex"} flexWrap={"wrap"}>
              {plan?.integrationFeatures.map((el, index) => {
                return (
                  <Box
                    display={"flex"}
                    justifyContent={"space-around"}
                    border={"1px solid  #c8c8c8"}
                    padding={"10px"}
                    margin={"2%"}
                    gap={"10px"}
                    borderRadius={"10px"}
                    alignItems={"center"}
                  >
                    {el.name}
                    <AiFillDelete
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        let newArray = [...plan.integrationFeatures];
                        newArray.splice(index, 1);
                        setPlan({
                          ...plan,
                          integrationFeatures: newArray,
                        });
                      }}
                    />
                  </Box>
                );
              })}
            </Box>
            <Box display={"flex"} alignItems={"center"} gap={"10px"}>
              <TextFieldNew
                fullWidth
                value={channelsfeature}
                placeholder="WhatsAPP"
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    if (e.target.value.length != "") {
                      setPlan({
                        ...plan,
                        channelsFeatures: [
                          ...plan.channelsFeatures,
                          { name: channelsfeature },
                        ],
                      });
                      setChannelFeature("");
                    }
                  }
                }}
                onChange={(e) => setChannelFeature(e.target.value)}
                label="Enter Channels"
              />

              <AiFillPlusCircle
                fontSize={"35px"}
                color="#22a454"
                onClick={() => {
                  if (channelsfeature.length != "") {
                    setPlan({
                      ...plan,
                      channelsFeatures: [
                        ...plan.channelsFeatures,
                        { name: channelsfeature },
                      ],
                    });
                    setChannelFeature("");
                  }
                }}
              />
            </Box>
            <Box display={"flex"} flexWrap={"wrap"}>
              {plan?.channelsFeatures.map((el, index) => {
                return (
                  <Box
                    display={"flex"}
                    justifyContent={"space-around"}
                    border={"1px solid  #c8c8c8"}
                    padding={"10px"}
                    margin={"2%"}
                    gap={"10px"}
                    borderRadius={"10px"}
                    alignItems={"center"}
                  >
                    {el.name}
                    <AiFillDelete
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        let newArray = [...plan.channelsFeatures];
                        newArray.splice(index, 1);
                        setPlan({
                          ...plan,
                          channelsFeatures: newArray,
                        });
                      }}
                    />
                  </Box>
                );
              })}
            </Box>
            <Box display={"flex"} alignItems={"center"} gap={"10px"}>
              <TextFieldNew
                fullWidth
                value={dashboardfeatures}
                placeholder="Dashboard chat inbox"
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    if (e.target.value.length != "") {
                      setPlan({
                        ...plan,
                        dashboardFeatures: [
                          ...plan.dashboardFeatures,
                          { name: dashboardfeatures },
                        ],
                      });
                      setDashboardFeature("");
                    }
                  }
                }}
                onChange={(e) => setDashboardFeature(e.target.value)}
                label="User Service Features"
              />

              <AiFillPlusCircle
                fontSize={"35px"}
                color="#22a454"
                onClick={() => {
                  if (dashboardfeatures.length != "") {
                    setPlan({
                      ...plan,
                      dashboardFeatures: [
                        ...plan.dashboardFeatures,
                        { name: dashboardfeatures },
                      ],
                    });
                    setDashboardFeature("");
                  }
                }}
              />
            </Box>
            <Box display={"flex"} flexWrap={"wrap"}>
              {plan?.dashboardFeatures.map((el, index) => {
                return (
                  <Box
                    display={"flex"}
                    justifyContent={"space-around"}
                    border={"1px solid  #c8c8c8"}
                    padding={"10px"}
                    margin={"2%"}
                    gap={"10px"}
                    borderRadius={"10px"}
                    alignItems={"center"}
                  >
                    {el.name}
                    <AiFillDelete
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        let newArray = [...plan.dashboardFeatures];
                        newArray.splice(index, 1);
                        setPlan({
                          ...plan,
                          dashboardFeatures: newArray,
                        });
                      }}
                    />
                  </Box>
                );
              })}
            </Box>
            <Box display={"flex"} alignItems={"center"} gap={"10px"}>
              <TextFieldNew
                fullWidth
                value={supportfeature}
                placeholder="Live chat"
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    if (e.target.value.length != "") {
                      setPlan({
                        ...plan,
                        supportFeatures: [
                          ...plan.planFeatures,
                          { name: supportfeature },
                        ],
                      });
                      setSupportFeature("");
                    }
                  }
                }}
                onChange={(e) => setSupportFeature(e.target.value)}
                label="Support"
              />

              <AiFillPlusCircle
                fontSize={"35px"}
                color="#22a454"
                onClick={() => {
                  if (supportfeature.length != "") {
                    setPlan({
                      ...plan,
                      supportFeatures: [
                        ...plan.supportFeatures,
                        { name: supportfeature },
                      ],
                    });
                    setSupportFeature("");
                  }
                }}
              />
            </Box>
            <Box display={"flex"} flexWrap={"wrap"}>
              {plan?.supportFeatures.map((el, index) => {
                return (
                  <Box
                    display={"flex"}
                    justifyContent={"space-around"}
                    border={"1px solid  #c8c8c8"}
                    padding={"10px"}
                    margin={"2%"}
                    gap={"10px"}
                    borderRadius={"10px"}
                    alignItems={"center"}
                  >
                    {el.name}
                    <AiFillDelete
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        let newArray = [...plan.supportFeatures];
                        newArray.splice(index, 1);
                        setPlan({
                          ...plan,
                          supportFeatures: newArray,
                        });
                      }}
                    />
                  </Box>
                );
              })}
            </Box>
            <Box display={"flex"} alignItems={"center"} gap={"10px"}>
              <TextFieldNew
                fullWidth
                value={agentfeature}
                placeholder="2 Users Included"
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    if (e.target.value.length != "") {
                      setPlan({
                        ...plan,
                        agentFeatures: [
                          ...plan.agentFeatures,
                          { name: agentfeature },
                        ],
                      });
                      setAgentFeatures("");
                    }
                  }
                }}
                onChange={(e) => setAgentFeatures(e.target.value)}
                label="Agents"
              />

              <AiFillPlusCircle
                fontSize={"35px"}
                color="#22a454"
                onClick={() => {
                  if (agentfeature.length != "") {
                    setPlan({
                      ...plan,
                      agentFeatures: [
                        ...plan.agentFeatures,
                        { name: agentfeature },
                      ],
                    });
                    setAgentFeatures("");
                  }
                }}
              />
            </Box>
            <Box display={"flex"} flexWrap={"wrap"}>
              {plan?.agentFeatures.map((el, index) => {
                return (
                  <Box
                    display={"flex"}
                    justifyContent={"space-around"}
                    border={"1px solid  #c8c8c8"}
                    padding={"10px"}
                    margin={"2%"}
                    gap={"10px"}
                    borderRadius={"10px"}
                    alignItems={"center"}
                  >
                    {el.name}
                    <AiFillDelete
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        let newArray = [...plan.agentFeatures];
                        newArray.splice(index, 1);
                        setPlan({
                          ...plan,
                          agentFeatures: newArray,
                        });
                      }}
                    />
                  </Box>
                );
              })}
            </Box>
            <LoadingButton
              loading={CON.data.loading}
              onClick={isEdit ? updatePlan : addPlan}
              variant="contained"
              sx={{ borderRadius: 3, boxShadow: 0 }}
            >
              {isEdit ? "Update Plan" : data.addPlan}
            </LoadingButton>
          </Stack>
        </Box>
      </Dialog>
    </div>
  );
};

export default AddPlan;
