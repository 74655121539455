import React from "react";
import Chart from "react-apexcharts";

const ChatBots = ({ data, dash }) => {
  const options = {
    chart: {
      id: "basic-line-chart",
    },
    xaxis: {
      categories: dash?.opened?.map((i) => i.month),
    },
    // plotOptions: {
    //     bar: {
    //         borderRadius: 3
    //     },
    // }
    title: {
      text: "Bots status",
      align: "left",
    },
  };

  const series = [
    {
      name: data.activeChatbot,
      data: dash?.activeBot?.map((i) => i.numberOfOders),
    },
    {
      name: data.notActiveChatbots,
      data: dash?.dActiveBot?.map((i) => i.numberOfOders),
    },
  ];

  return (
    <Chart
      id="basic-line-chart"
      height={"100%"}
      width={"100%"}
      options={options}
      series={series}
      type="bar"
    />
  );
};

export default ChatBots;
