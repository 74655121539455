import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const GoalCompletion = ({ data, goalCompletionData }) => {
  const [series, setState] = useState([0, 0, 0]);
  const options = {
    chart: {
      type: "donut",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    labels: ["Order Place", "View Checkout", "Create Cart", "Abandoned User"],
    title: {
      text: "Goal Completion",
      align: "left",
    },
  };

  useEffect(() => {
    if (goalCompletionData.totalSession != 0) {
      setState([
        goalCompletionData.whatsapp_order_select_cashOnDelivery,
        goalCompletionData.whatsapp_checkout_init,
        goalCompletionData.whatsapp_cart,
        goalCompletionData.remainingSession,
      ]);
    }
  }, [goalCompletionData]);
  return (
    <Chart
      id="basic-line-chart"
      height={"100%"}
      width={"100%"}
      options={options}
      series={series}
      type="donut"
    />
  );
};

export default GoalCompletion;
