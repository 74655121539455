import React from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { Box, IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import { DeleteOutline, Visibility } from "@mui/icons-material";
import { GlobalContext } from "../../context/GlobalContext";
import EditIcon from "@mui/icons-material/Edit";

const AgentList = ({ data, setState, state, allgents, getAllagents }) => {
  const { hitAxios } = React.useContext(GlobalContext);
  const CON = React.useContext(GlobalContext);
  const [hide, setHide] = React.useState(
    localStorage.getItem("hide_broadcast_header") ? true : false
  );
  const [dialog, setDialog] = React.useState({
    open: false,
  });
  const [logs, setLogs] = React.useState([]);
  const [logStatus, setLogStatus] = React.useState({});

  async function delAgent(email) {
    if (window.confirm(data.AUS)) {
      const res = await hitAxios({
        path: "/api/agent/deleteAgent",
        post: true,
        admin: false,
        obj: { email },
      });
      if (res.data.success) {
        getAllagents();
      }
    }
  }
  return (
    <div>
      <Box mt={2} borderRadius={4} bgcolor={"white"}>
        {allgents ? (
          <Box height={hide ? "76vh" : "48vh"}>
            <DataGrid
              getRowId={(row) => row._id}
              rows={[...allgents].reverse()}
              columns={[
                {
                  headerName: "Name",
                  field: "firstName",
                  flex: 1,
                  renderCell: (dataa) => {
                    return (
                      <Tooltip title={dataa.row.firstName}>
                        {dataa.row.firstName}
                      </Tooltip>
                    );
                  },
                },
                {
                  headerName: "Email",
                  field: "templet",
                  flex: 1,
                  renderCell: (dataa) => {
                    return (
                      <Tooltip title={dataa.row.email}>
                        {dataa.row.email}
                      </Tooltip>
                    );
                  },
                },
                {
                  headerName: "isActive",
                  field: "phonebook",
                  flex: 1,
                  renderCell: (dataa) => {
                    return (
                      <Tooltip title={dataa.row.status}>
                        {dataa.row.status}
                      </Tooltip>
                    );
                  },
                },
                {
                  headerName: "Action",
                  field: "del",
                  flex: 1,
                  renderCell: (dataa) => {
                    return (
                      <>
                        <IconButton
                          onClick={() => {
                            setState({
                              ...state,
                              addAgentDialog: true,
                              agentData: {
                                ...dataa.row,
                                isViewOnly: true,
                              },
                            });
                          }}
                          color="successs"
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            delAgent(dataa.row.email);
                          }}
                          color="error"
                        >
                          <DeleteOutline />
                        </IconButton>
                      </>
                    );
                  },
                },
              ]}
              pageSize={50}
              rowsPerPageOptions={[50]}
              checkboxSelection={false}
              // components={{
              //     Toolbar: CustomToolbar
              // }}
              sx={{
                boxShadow: 0,
                borderRadius: 4,
                border: 0,
                fontSize: 13,
                padding: 2,
              }}
            />
          </Box>
        ) : (
          ""
        )}
      </Box>
    </div>
  );
};

export default AgentList;
