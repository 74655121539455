import React from "react";
import Chart from "react-apexcharts";

const Chats = ({ data, dash }) => {
  const options = {
    chart: {
      id: "basic-line-chart",
    },
    xaxis: {
      categories: dash?.opened?.map((i) => i.month),
    },
    title: {
      text: "Chats viewing status",
      align: "left",
    },
  };

  const series = [
    {
      name: data.openedChat,
      data: dash?.opened?.map((i) => i.numberOfOders),
    },
    {
      name: data.pendingChat,
      data: dash?.pending?.map((i) => i.numberOfOders),
    },
    {
      name: data.resolvedChat,
      data: dash?.resolved?.map((i) => i.numberOfOders),
    },
  ];

  return (
    <Chart
      id="basic-line-chart"
      height={"100%"}
      width={"100%"}
      options={options}
      series={series}
      type="area"
    />
  );
};

export default Chats;
