import {
  Avatar,
  Box,
  CardActionArea,
  Chip,
  Divider,
  Drawer,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import ChatList from "./components/ChatList";
import { Add, AddCircle, AddOutlined, Search } from "@mui/icons-material";

const ChatWindow = ({
  DRAWER_WIDTH,
  state,
  setState,
  INBOX,
  chatList,
  data,
  getChatConvo,
}) => {
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  const [selected, setSelected] = React.useState("ALL CHATS");
  const [filteredChats, setFilteredChats] = React.useState([]);
  const [searchType, setSearchType] = React.useState("NAME");
  const [searchKey, setSearchKey] = React.useState("");

  React.useEffect(() => {
    setFilteredChats(chatList);
  }, [chatList]);

  function filterArrayByValue(arr, key, value) {
    return arr.filter((obj) => obj[key] === value);
  }

  function filterArrayByValueStartingWith(arr, key, value) {
    if (!value) {
      return arr;
    }
    // console.log("arr", arr);
    return arr.filter((obj) => {
      const objValue = obj["sender_name"].toLowerCase();
      const objValue1 = obj["sender_mobile"].substring(2);
      const searchValue = value.toLowerCase();
      if (objValue.startsWith(searchValue)) {
        return obj;
      } else if (objValue1.startsWith(searchValue)) {
        return obj;
      }
    });
  }

  React.useEffect(() => {
    const updateScreenWidth = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", updateScreenWidth);
    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);

  const filterType = [
    {
      title: data.allChats,
      type: "ALL CHATS",
      onClick: () => {
        setFilteredChats(chatList);
      },
    },
    {
      title: data.pending,
      type: "PENDING",
      onClick: () => {
        const newVal = filterArrayByValue(chatList, "chat_status", "pending");
        setFilteredChats(newVal);
      },
    },
    {
      title: data.open,
      type: "OPEN",
      onClick: () => {
        const newVal = filterArrayByValue(chatList, "chat_status", "open");
        setFilteredChats(newVal);
      },
    },
    {
      title: data.solved,
      type: "SOLVED",
      onClick: () => {
        const newVal = filterArrayByValue(chatList, "chat_status", "solved");
        setFilteredChats(newVal);
      },
    },
  ];

  const searchComp = (
    <Box sx={{ p: "15px 15px 15px 15px" }}>
      <TextField
        value={searchKey}
        onChange={(e) => {
          const newSearchKey = e.target.value;
          setSearchKey(newSearchKey);

          if (newSearchKey.trim() === "") {
            // If the search key is empty, set filteredChats to the original chatList
            setFilteredChats(chatList);
            return; // No need to proceed with filtering
          }

          const newValue = filterArrayByValueStartingWith(
            chatList,
            searchType === "MOBILE" ? "sender_mobile" : "sender_name",
            newSearchKey
          );
          setFilteredChats(newValue);
        }}
        size="small"
        fullWidth
        InputProps={{
          style: { borderRadius: 10 },
          endAdornment: (
            <InputAdornment position="end">
              {/* <Stack direction={"row"} spacing={0.5}>
                <Chip
                  onClick={() => setSearchType("MOBILE")}
                  variant={searchType === "MOBILE" ? "filled" : "outlined"}
                  size="small"
                  label={data.phoneNo}
                  sx={{ fontSize: 11 }}
                />
                <Chip
                  onClick={() => setSearchType("NAME")}
                  variant={searchType === "NAME" ? "filled" : "outlined"}
                  size="small"
                  label={data.name}
                  sx={{ fontSize: 11 }}
                />
              </Stack> */}
            </InputAdornment>
          ),
          startAdornment: (
            <InputAdornment position="start">
              <Search sx={{ color: (t) => t.palette.primary.main }} />
            </InputAdornment>
          ),
        }}
        label={data.search}
      />
    </Box>
  );

  const returnHtml = (
    <Box sx={{ p: "0px 15px 15px 15px" }}>
      <Stack alignItems={"center"} spacing={1} direction="row">
        <FormControl size="small" fullWidth>
          <InputLabel>{data.filter}</InputLabel>
          <Select
            sx={{ borderRadius: 2 }}
            size="small"
            value={selected}
            label={data.filter}
            onChange={(e) => setSelected(e.target.value)}
          >
            {filterType?.map((i, key) => {
              return (
                <MenuItem onClick={() => i.onClick()} key={key} value={i.type}>
                  <Typography fontWeight={500} color={"#565656"}>
                    {i.title}
                  </Typography>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <Tooltip title={data.addNewChat}>
          <Avatar
            onClick={() => console.log("hey")}
            variant="circular"
            sx={{
              bgcolor: (t) => t.palette.primary.main,
              border: 1,
              borderColor: "black",
              cursor: "pointer",
              height: 35,
              width: 35,
            }}
          >
            <Add />
          </Avatar>
        </Tooltip>
      </Stack>
    </Box>
  );

  return (
    <div>
      {screenWidth > DRAWER_WIDTH ? (
        <Box width={340} height={"92vh"} bgcolor={"white"} overflow={"auto"}>
          {searchComp}
          {returnHtml}
          <Divider style={{ background: "white" }} variant="middle" />
          <ChatList
            data={data}
            getChatConvo={getChatConvo}
            chatList={filteredChats}
            INBOX={INBOX}
          />
        </Box>
      ) : (
        <Drawer
          key="5"
          onClose={() => setState({ ...state, drawer: false })}
          open={state.drawer && screenWidth < DRAWER_WIDTH}
        >
          <Box height={"92vh"} bgcolor={"white"} overflow={"auto"} width={340}>
            {searchComp}
            {returnHtml}

            <Divider />
            <ChatList
              data={data}
              getChatConvo={getChatConvo}
              chatList={filteredChats}
              INBOX={INBOX}
            />
          </Box>
        </Drawer>
      )}
    </div>
  );
};

export default ChatWindow;
