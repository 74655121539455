import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import "./CountryCodeDropDown.css";
import { Box, Typography } from "@mui/material";

const CountryCodeDropDown = ({
  state,
  setState,
  disabled,
  label,
  helperText,
  width,
}) => {
  return (
    <Box
      display="flex"
      flexDirection={"column"}
      width={width ? width : "100%"}
      gap={"5px"}
    >
      <PhoneInput
        specialLabel={label}
        style={{ width: "100%" }}
        country={"in"}
        disabled={disabled}
        value={state?.mobile_with_country_code ?? ""}
        onChange={(phone, data) => {
          setState({
            ...state,
            mobile_with_country_code: phone,
            mobileCodeObj: data,
          });
        }}
        helperText
      />
      {helperText ? (
        <Box display={"flex"} flexDirection={"column"}>
          <Typography variant="body1" fontSize={"13px"}>
            * Enter the phone number that you will use to register your WhatsApp
            account.
          </Typography>
          <Typography variant="body1" fontSize={"13px"}>
            * {helperText}
          </Typography>
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
};

export default CountryCodeDropDown;
