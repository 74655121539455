import React from "react";
import { Route, Switch } from "react-router-dom";
import {
  Alert,
  Snackbar,
  Box,
  LinearProgress,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { GlobalContext } from "./context/GlobalContext";
import PublicRoute from "./routing/PublicRoute";
import AdminRoute from "./routing/AdminRoute";
import UserRoute from "./routing/UserRoute";
import { TranslateContext } from "./context/TranslateContext";
import axios from "axios";
import Error404 from "./Error404";
import "./loading.css";
import AdminDashboard from "./admin/AdminDashboard";
import AdminLogin from "./admin/AdminLogin";
import ReactFlowComp from "./ReactFlowComp";
import UserLogin from "./user/UserLogin";
import UserDashboard from "./user/UserDashboard";
import FrontEnd from "./frontend/FrontEnd";
import CheckOut from "./frontend/checkout/CheckOut";
import ContactForm from "./frontend/contactForm/ContactForm";
import ViewPage from "./frontend/page/ViewPage";
import RecoveryEmail from "./recoveryEmails/RecoveryEmail";
import RecoveryAdminEmail from "./recoveryEmails/RecoveryAdminEmail";
import Plans from "./frontend/components/Plans";

const HomeMainPage = () => {
  const GLOBAL_CONTEXT = React.useContext(GlobalContext);
  const LANGUAGE = React.useContext(TranslateContext);

  React.useEffect(() => {
    const code = "English"; // localStorage.getItem('language')
    // const localcode = local.slice(0, local.indexOf('.'))
    if (code) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/api/web/get-one-translation?code=${code}`
        )
        .then((res) => {
          if (res.data.notfound) {
            localStorage.removeItem("language");
            alert("Maybe translate file issue.");
            window.location.reload();
            return;
          }
          LANGUAGE.setData({ ...res.data.data });
        })
        .catch((err) => console.error(err));
    } else {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/api/web/get-all-translation-name`
        )
        .then((lan) => {
          localStorage.setItem(
            "language",
            lan.data.data[0]?.replace(".json", "")
          );
          if (lan.data.data[0]?.replace(".json", "")) {
            axios
              .get(
                `${
                  process.env.REACT_APP_BASE_URL
                }/api/web/get-one-translation?code=${lan.data.data[0]?.replace(
                  ".json",
                  ""
                )}`
              )
              .then((res) => {
                LANGUAGE.setData({ ...res.data.data });
              })
              .catch((err) => console.error(err));
          }
        });
    }
  }, []);

  return (
    <Box
      sx={{ position: "relative" }}
      color="text.primary"
      minHeight={"100vh"}
      bgcolor="background.default"
    >
      {/* <div style={{ position: "absolute", top: 0, left: 0, right: 0, display: GLOBAL_CONTEXT.data.loading ? 'block' : 'none' }} >
                <LinearProgress />
            </div> */}

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={GLOBAL_CONTEXT.data.loading}
      >
        <CircularProgress />
      </Backdrop>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={GLOBAL_CONTEXT.data.snack}
        autoHideDuration={4000}
      >
        <Alert
          severity={GLOBAL_CONTEXT.data.snack_success ? "success" : "error"}
        >
          {GLOBAL_CONTEXT.data.snack_msg}
        </Alert>
      </Snackbar>

      <div
        style={{
          opacity: GLOBAL_CONTEXT.data.loading ? 0.25 : 1,
          pointerEvents: GLOBAL_CONTEXT.data.loading ? "none" : "initial",
        }}
      >
        <Switch>
          <PublicRoute path="/" exact component={UserLogin} />
          <PublicRoute path="/plans" exact component={Plans} />
          <UserRoute path="/user" exact component={UserDashboard} />
          <PublicRoute path="/user/login" exact component={UserLogin} />

          <UserRoute path="/user/checkout" exact component={CheckOut} />

          <PublicRoute
            path="/page/contact-form"
            exact
            component={ContactForm}
          />
          <PublicRoute
            path="/recovery-user/:id"
            exact
            component={RecoveryEmail}
          />
          <PublicRoute
            path="/recovery-admin/:id"
            exact
            component={RecoveryAdminEmail}
          />

          <PublicRoute path="/view/:slug" exact component={ViewPage} />
          <AdminRoute path="/admin" exact component={AdminDashboard} />
          <PublicRoute path="/admin/login" exact component={AdminLogin} />
          <PublicRoute path="*" component={Error404} />
        </Switch>
      </div>
    </Box>
  );
};

export default HomeMainPage;
