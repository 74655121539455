import React from "react";
import { useDropzone } from "react-dropzone";
import { v4 as uuid } from "uuid";
import {
  SmartToy,
  ApartmentOutlined,
  AttachFileOutlined,
  Close,
} from "@mui/icons-material";
import {
  Grid,
  IconButton,
  Stack,
  Typography,
  Button,
  Chip,
  Box,
} from "@mui/material";
import TextFieldNew from "../../components/TextFieldNew";

const BotInfoComponent = ({ data, state, setState, hitAxios, userData }) => {
  const [uploadedFile, setUploadedFile] = React.useState([]);
  const [defaultFileName, setDefaultFileName] = React.useState("");
  const [showDropZone, setShowDropZone] = React.useState(false);

  const onDrop = (file) => {
    setUploadedFile(file);
    setDefaultFileName(file[0]?.name.split(".").slice(0, -1).join("."));
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { "text/plain": [".csv"] },
    capture: "filesystem",
    maxFiles: 1,
    multiple: false,
  });

  const updateFaqListURLAndGetUser = async (res, faqListURL = []) => {
    const res1 = await hitAxios({
      path:
        process.env.REACT_APP_BACKEND_BASE_URL +
        "/backend/users/" +
        userData._id,
      post: false,
      admin: false,
      token_user: "",
      patch: true,
      obj: {
        ...userData,
        faqListURL,
      },
    });
    if (res1.status === 200) {
      const res2 = await hitAxios({
        path:
          process.env.REACT_APP_BACKEND_BASE_URL +
          "/backend/users/" +
          userData._id,
        post: false,
        admin: false,
        token_user: "",
      });

      if (res2.data._id === userData._id) {
        localStorage.setItem("myData", JSON.stringify({ ...res2.data }));
      }
    }
  };

  const handleUploadFile = async () => {
    const formData = new FormData();
    formData.append("companyName", userData?.companyName);
    formData.append("file", uploadedFile[0]);
    const res = await hitAxios({
      path:
        process.env.REACT_APP_BACKEND_BASE_URL +
        "/backend/file-upload-product-csv",
      post: true,
      admin: false,
      obj: formData,
    });
    if (res.status === 200) {
      const faqList = [
        ...userData.faqListURL,
        { fileName: defaultFileName, fileURL: res.data.file, id: uuid() },
      ];
      updateFaqListURLAndGetUser(res, faqList);
    }
    setTimeout(() => {
      setShowDropZone(false);
    }, 1000);
  };

  return (
    <Grid container p={1}>
      <Grid item xs={12} p={1}>
        <Box
          display={"flex"}
          justifyContent={"flex-end"}
          direction={"row"}
          spacing={2}
        >
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={() => {
                setShowDropZone((prv) => !prv);
                setUploadedFile([]);
                setDefaultFileName("");
              }}
            >
              {showDropZone ? "Cancel" : "Add CSV"}
            </Button>
          </Grid>
        </Box>
      </Grid>
      {showDropZone ? (
        <Grid item xs={12} p={1}>
          <Grid item xs={12} sm={12}>
            <Typography>Attach CSV File </Typography>
            <Typography color="gray">
              We Support only a single CSV or Excel File (with 1 sheet) up to 5
              MB in size.
            </Typography>
            <TextFieldNew
              fullWidth
              onChange={(e) => setDefaultFileName(e.target.value)}
              value={defaultFileName}
              sx={{ mb: 2, mt: 2 }}
              startIcon={
                <AttachFileOutlined
                  sx={{
                    color: (t) => t.palette.primary.main,
                    height: 20,
                    width: 20,
                  }}
                />
              }
              label="File Name"
            />
            <Grid item xs={12} sm={12}>
              <section className="container">
                <div
                  {...getRootProps({
                    style: {
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      padding: "20px",
                      borderWidth: "3px",
                      borderRadius: "12px",
                      borderColor: (t) => t.palette.primary.main,
                      borderStyle: "dashed",
                      backgroundColor: "#22a45421",
                      color: "#bdbdbd",
                      outline: "none",
                      transition: "border .24s ease-in-out",
                    },
                  })}
                >
                  <input {...getInputProps()} />
                  <Typography>
                    Click to upload a File or drag and drop it here
                  </Typography>
                </div>
                {uploadedFile.length > 0 && (
                  <aside>
                    {uploadedFile.map((file) => (
                      <Typography
                        key={file.path}
                        sx={{ display: "flex", marginTop: "10px" }}
                      >
                        <Chip
                          label={file.name}
                          onDelete={() => {
                            setUploadedFile([]);
                            setDefaultFileName("");
                          }}
                          icon={
                            <AttachFileOutlined
                              sx={{ height: "20px", width: "20px" }}
                            />
                          }
                          deleteIcon={<Close />}
                          sx={{
                            backgroundColor: "#22a45421",
                          }}
                        />
                      </Typography>
                    ))}
                  </aside>
                )}
              </section>
            </Grid>
          </Grid>
          <Grid item style={{ padding: "10px 8px 0px 24px" }}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              size="small"
              style={{ margin: "3, 0, 2", textTransform: "none" }}
              onClick={() => {
                handleUploadFile();
              }}
            >
              Add CSV
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={12} p={1}>
          <Grid item xs={12} sm={12}>
            <Typography>
              Attached CSV Files ({userData.faqListURL.length})
            </Typography>
            <Grid container direction="column" sx={{ paddingLeft: "15px" }}>
              {userData.faqListURL.map((fileInfo) => (
                <Grid
                  key={fileInfo.id}
                  item
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Typography variant="subtitle1">
                    {fileInfo.fileName}
                  </Typography>
                  <IconButton
                    aria-label="close"
                    onClick={() => {
                      const faqList = userData.faqListURL.filter(
                        (obj) => obj.id !== fileInfo.id
                      );
                      updateFaqListURLAndGetUser(userData, faqList);
                    }}
                  >
                    <Close />
                  </IconButton>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default BotInfoComponent;
