import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { GlobalContext } from "../../context/GlobalContext";
import PaymentPlanHistory from "./PaymentPlanHistory";
import { BookmarkBorder } from "@mui/icons-material";
import displayRazorpay from "../../frontend/checkout/razorPay";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import NoDataFound from "../../components/NoDataFound";
import PrettoSlider from "../../components/PrettoSlider";

const PaymentHistory = ({ data, dash }) => {
  const GC = React.useContext(GlobalContext);
  const history = useHistory();
  const userData = JSON.parse(localStorage.getItem("myData"));
  const [loading, setLoading] = useState(false);
  const [currentPlanData, setCurrentPlanData] = React.useState();
  const [userSubscription, setUserSubscription] = React.useState({});
  const [paymentHistory, setPaymentHistory] = React.useState([]);
  const [webData, setWebData] = React.useState({});
  const [addOnPrice, setAddOnPrice] = React.useState({});
  const [addOnHistory, setAddOnHistory] = React.useState([]);
  const getPaymentHistoryData = async () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
    const res = await GC.hitAxios({
      path:
        process.env.REACT_APP_BACKEND_BASE_URL +
        "/razorpay/get-subscription-history",
      post: true,
      admin: false,
      obj: { userId: userData?._id },
    });
    if (res.status === 200 && res.data.status === 200) {
      setPaymentHistory(res.data.data.paymentHistory);
      setCurrentPlanData(res.data.data.currentPlanData);
      setUserSubscription(res.data.data.userSubscription);
      if (res.data.data.userData) {
        const myData = JSON.parse(localStorage.getItem("myData"));
        localStorage.setItem(
          "myData",
          JSON.stringify({ ...myData, ...res.data.data.userData })
        );
      }
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  async function getWebPublic() {
    const res = await GC.hitAxios({
      path: "/api/admin/get_web_public",
      post: false,
      admin: false,
      token_user: "",
    });
    if (res.data.success) {
      setWebData(res.data.data);
    }
  }

  const getAddOnPlan = async () => {
    const res = await GC.hitAxios({
      path: "/api/payment/get_add_on_prices",
      post: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.data.success) {
      setAddOnPrice({
        whatsappPriceObject: res.data.data.filter(
          (itm) => itm.platform === "whatsapp"
        )[0],
        openAIPriceObject: res.data.data.filter(
          (itm) => itm.platform === "openAI"
        )[0],
      });
    }
  };

  const getAddonPlanHistory = async () => {
    const res = await GC.hitAxios({
      path: "/api/payment/get_add_on_history",
      post: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.data.success) {
      setAddOnHistory(res.data.data.filter((itm) => itm.status === "paid"));
    }
  };

  React.useEffect(() => {
    getWebPublic();
    getPaymentHistoryData();
    getAddOnPlan();
    getAddonPlanHistory();
  }, []);

  const options = { day: "numeric", month: "short", year: "numeric" };
  const [whatsppSliderValue, setWhatsappSliderValue] = React.useState(10000);
  const [openAISliderValue, setOpenAISliderValue] = React.useState(1);
  const handleVerify = async (response, planId, userId, addOnId) => {
    const data = {
      razorpay_payment_id: response.razorpay_payment_id,
      razorpay_order_id: response.razorpay_order_id,
      razorpay_signature: response.razorpay_signature,
      planId: planId ?? "",
      addOnId,
      userId,
    };
    const resultPay = await GC.hitAxios({
      path: process.env.REACT_APP_BACKEND_BASE_URL + "/razorpay/payment/verify",
      post: true,
      admin: false,
      obj: {
        ...data,
      },
    });

    if (
      resultPay.status === 200 &&
      resultPay.data.success &&
      resultPay.data.data.status === "paid"
    ) {
      getAddonPlanHistory();
    }
  };
  const buyAddsOn = async (addOnsType) => {
    if (
      addOnsType === "whatsapp" &&
      whatsppSliderValue <= 0 &&
      whatsppSliderValue > 20000
    ) {
      GC.setData({
        ...GC.data,
        snack: true,
        snack_msg:
          whatsppSliderValue <= 0
            ? "Select some whatsapp toknes"
            : "Too many whatsapp toknes can't process right now",
        snack_success: false,
      });
      setTimeout(() => {
        // Setting Default value
        GC.setData({
          ...GC.data,
          snack: false,
          snack_msg: "Great",
          snack_success: true,
        });
      }, 4000);
      return;
    }
    if (
      addOnsType === "openAI" &&
      openAISliderValue <= 0 &&
      openAISliderValue > 10
    ) {
      GC.setData({
        ...GC.data,
        snack: true,
        snack_msg:
          openAISliderValue <= 0
            ? "Select some openAI toknes"
            : "Too many openAI toknes can't process right now",
        snack_success: true,
      });
      setTimeout(() => {
        // Setting Default value
        GC.setData({
          ...GC.data,
          snack: false,
          snack_msg: "Great",
          snack_success: true,
        });
      }, 4000);
      return;
    }
    const res = await GC.hitAxios({
      path: "/api/payment/add_on",
      post: true,
      admin: false,
      obj: {
        addOnList:
          addOnsType === "whatsapp"
            ? [
                {
                  platform: "whatsapp",
                  tokens: whatsppSliderValue,
                },
              ]
            : [
                {
                  platform: "openAi",
                  tokens: openAISliderValue,
                },
              ],
      },
    });
    if (res.data.success) {
      displayRazorpay({
        addOnId: res?.data?.data?.addOnId,
        globalContext: GC,
        price: res?.data?.data?.orderData[0]?.price,
        history,
        handleVerify,
      });
    }
  };

  return (
    <Container maxWidth="lg">
      <Box mt={2} borderRadius={2} bgcolor={"white"} p={2}>
        <Grid container spacing={2}>
          {addOnPrice?.whatsappPriceObject?.status === 1 && (
            <Grid xs={12} item>
              <Typography variant="h6">
                Whatsapp amount Add-On
                <Button
                  onClick={() => {
                    if (whatsppSliderValue === 0) {
                      GC.setData({
                        ...GC.data,
                        snack: true,
                        snack_msg: "Select some Whatsapp tokens",
                        snack_success: true,
                      });
                      setTimeout(() => {
                        // Setting Default value
                        GC.setData({
                          ...GC.data,
                          snack: false,
                          snack_msg: "Great",
                          snack_success: true,
                        });
                      }, 4000);
                    } else buyAddsOn("whatsapp");
                  }}
                  variant="contained"
                  color="primary"
                  startIcon={<BookmarkBorder />}
                  sx={{
                    marginLeft: "10px",
                    boxShadow: 0,
                    borderRadius: 2,
                    textTransform: "none",
                  }}
                >
                  <Tooltip
                    title={
                      "Whatsapp price for " + whatsppSliderValue + " message "
                    }
                  >
                    <span>
                      Add Whatsapp Amount {webData?.currency_symbol}
                      {whatsppSliderValue.toLocaleString()}
                    </span>
                  </Tooltip>
                </Button>
              </Typography>
              <Grid container spacing={2} sx={{ padding: "20px 50px" }}>
                <Grid item xs={12}>
                  <PrettoSlider
                    defaultValue={5000}
                    value={whatsppSliderValue}
                    onChange={(e) => {
                      e.target.value >= 0 &&
                        e.target.value <= 20000 &&
                        setWhatsappSliderValue(e.target.value);
                    }}
                    min={-5000}
                    max={25000}
                    step={5000}
                    marks={[0, 5000, 10000, 15000, 20000].map((itm) => {
                      return { value: itm, label: itm.toLocaleString() };
                    })}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          {addOnPrice?.openAIPriceObject?.status === 0 && (
            <Grid xs={12} item>
              <Typography variant="h6">
                Open AI tokens Add-On
                <Button
                  onClick={() => {
                    if (openAISliderValue === 0) {
                      GC.setData({
                        ...GC.data,
                        snack: true,
                        snack_msg: "Select some open-AI tokens",
                        snack_success: true,
                      });
                      setTimeout(() => {
                        // Setting Default value
                        GC.setData({
                          ...GC.data,
                          snack: false,
                          snack_msg: "Great",
                          snack_success: true,
                        });
                      }, 4000);
                    } else buyAddsOn("openAI");
                  }}
                  variant="contained"
                  color="primary"
                  startIcon={<BookmarkBorder />}
                  sx={{
                    marginLeft: "10px",
                    boxShadow: 0,
                    borderRadius: 2,
                    textTransform: "none",
                  }}
                >
                  <Tooltip
                    title={"Open AI price for " + openAISliderValue + "M token"}
                  >
                    <span>
                      Buy Tokens for {webData?.currency_symbol}
                      {(
                        openAISliderValue * addOnPrice?.openAIPriceObject?.price
                      ).toLocaleString()}
                    </span>
                  </Tooltip>
                </Button>
              </Typography>

              <Grid container spacing={2} sx={{ padding: "20px 50px" }}>
                <Grid item xs={12}>
                  <PrettoSlider
                    value={openAISliderValue}
                    onChange={(e) => {
                      e.target.value >= 0 &&
                        e.target.value <= 10 &&
                        setOpenAISliderValue(e.target.value);
                    }}
                    min={-5}
                    max={15}
                    step={1}
                    marks={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((itm) => {
                      return {
                        value: itm,
                        label: itm.toLocaleString() + " M",
                      };
                    })}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid container spacing={2}>
          <Grid container item spacing={2}>
            <Grid
              xs={12}
              sm={12}
              md={5}
              lg={5}
              border={2}
              borderColor={"#E3E3E3"}
              borderRadius={2}
            >
              <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  fontWeight={500}
                >
                  <Typography variant="h6">Usage</Typography>{" "}
                </Box>
                <Box display={"flex"} justifyContent={"center"}>
                  <Typography sx={{ display: "flex" }}>
                    {" "}
                    <span
                      style={{
                        fontSize: "16px",
                        marginLeft: "5px",
                        marginRight: "5px",
                        fontWeight: "500",
                        color: "black",
                      }}
                    >
                      {" "}
                      AI Token -{" "}
                    </span>{" "}
                    {userData?.aitokenUsage} USAGE / {userData?.aiTokenLimit}{" "}
                    LIMIT{" "}
                  </Typography>
                </Box>
                <Box display={"flex"} justifyContent={"center"}>
                  <Typography sx={{ display: "flex" }}>
                    {" "}
                    <span
                      style={{
                        fontSize: "16px",
                        marginLeft: "5px",
                        marginRight: "5px",
                        fontWeight: "500",
                        color: "black",
                      }}
                    >
                      Whatsapp Amount-{" "}
                    </span>{" "}
                    {userData?.whatsappTotalAmountUse} USAGE /{" "}
                    {userData?.whatsappAmountLimit} LIMIT{" "}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid xs={12} sm={12} md={7} lg={7}>
              <PaymentPlanHistory
                currentPlanData={currentPlanData}
                webData={webData}
                userData={userData}
                loading={loading}
              />
            </Grid>
            {/* </Box> */}
          </Grid>
          <Grid xs={6} lg={6} sm={6} item>
            <Typography variant="h6">Subscribed Payment History</Typography>
            <Box
              overflow={"auto"}
              height={"32vh"}
              p={2}
              mt={2}
              mb={2}
              borderRadius={2}
              bgcolor={"white"}
            >
              <Stack direction={"column"} spacing={2}>
                {userSubscription?.installmentPaidDetails?.length ? (
                  userSubscription?.installmentPaidDetails?.map(
                    (itm, index) => (
                      <React.Fragment>
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          justifyContent={"space-between"}
                        >
                          <Stack
                            alignItems={"center"}
                            direction={"row"}
                            spacing={2}
                          >
                            <FaMoneyBillTrendUp />
                            <Typography variant="body2">
                              Subscription purchase/renewal on{" "}
                              {new Date(itm?.timestamp).toLocaleDateString(
                                "en-US",
                                options
                              )}
                            </Typography>
                          </Stack>
                        </Stack>
                        {index + 1 !== paymentHistory.length && <Divider />}
                      </React.Fragment>
                    )
                  )
                ) : (
                  <NoDataFound subTitle="No Payment history" />
                )}
              </Stack>
            </Box>
          </Grid>
          <Grid xs={6} lg={6} sm={6} item>
            <Typography variant="h6">Add-ons purchase History</Typography>
            <Box
              overflow={"auto"}
              height={"32vh"}
              p={2}
              mt={2}
              mb={2}
              borderRadius={2}
              bgcolor={"white"}
            >
              <Stack direction={"column"} spacing={2}>
                {addOnHistory?.length ? (
                  addOnHistory?.map(
                    (itm, index) =>
                      itm.status === "paid" && (
                        <React.Fragment>
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                          >
                            <Stack
                              alignItems={"center"}
                              direction={"row"}
                              spacing={2}
                            >
                              <FaMoneyBillTrendUp />
                              <Typography variant="body2">
                                Purchased{" "}
                                {itm?.orderData[0]?.tokens.toLocaleString() +
                                  " " +
                                  itm?.orderData[0]?.platform +
                                  " "}
                                tokens for {webData?.currency_symbol}
                                {(itm?.orderData[0]?.price)
                                  .toFixed(2)
                                  ?.toLocaleString()}{" "}
                                on{" "}
                                {new Date(itm?.updatedAt).toLocaleDateString(
                                  "en-US",
                                  options
                                )}
                              </Typography>
                            </Stack>
                          </Stack>
                          {index + 1 !== addOnHistory.length && <Divider />}
                        </React.Fragment>
                      )
                  )
                ) : (
                  <NoDataFound subTitle="No Purchase history" />
                )}
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default PaymentHistory;
