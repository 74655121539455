import { Box, Chip, Divider, Stack, Tooltip, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import ChatListMsgType from "./ChatListMsgType";
import { ContactPageOutlined } from "@mui/icons-material";

function sortMessagesByTimestamp(messages) {
  const messagesWithTimestamp = [];
  const messagesWithoutTimestamp = [];

  messages.forEach((message) => {
    if (message.last_message_came) {
      messagesWithTimestamp.push(message);
    } else {
      messagesWithoutTimestamp.push(message);
    }
  });

  messagesWithTimestamp.sort(
    (a, b) => b.last_message_came - a.last_message_came
  );
  return [...messagesWithTimestamp, ...messagesWithoutTimestamp];
}

const getFormattedTimestamp = (timestamp) => {
  const today = moment().startOf("day");
  const yesterday = moment().subtract(1, "day").startOf("day");

  const messageTime = moment.unix(timestamp);

  if (messageTime.isSame(today, "d")) {
    // If message is from today, show time only
    return messageTime.format("hh:mm A");
  } else if (messageTime.isSame(yesterday, "d")) {
    // If message is from yesterday, show "Yesterday" only
    return "Yesterday";
  } else {
    // For older messages, show day name, month name, and then time
    return messageTime.format("dddd, MMMM hh:mm A");
  }
};

const ChatList = ({ chatList, data, getChatConvo, INBOX }) => {
  // console.log("df", chatList);
  const open = (
    <Box sx={{ padding: "1px 5px 1px 5px" }} bgcolor={"#eef7e7"}>
      <Typography color={"#5da570"} fontSize={12} fontWeight={600}>
        {data.open}
      </Typography>
    </Box>
  );

  const pending = (
    <Box sx={{ padding: "1px 5px 1px 5px" }} bgcolor={"#fef7da"}>
      <Typography color={"#f5bd1d"} fontSize={12} fontWeight={600}>
        {data.pending}
      </Typography>
    </Box>
  );
  const agentConnect = (d) => {
    return (
      <Box sx={{ padding: "1px 5px 1px 5px" }} bgcolor={"#fef7da"}>
        <Typography color={"#f5bd1d"} fontSize={12} fontWeight={600}>
          {`${d?.agentName} Connected`}
        </Typography>
      </Box>
    );
  };

  const solved = (
    <Box sx={{ padding: "1px 5px 1px 5px" }} bgcolor={"#e5f7ff"}>
      <Typography color={"#72b5cf"} fontSize={12} fontWeight={600}>
        {data.solved}
      </Typography>
    </Box>
  );

  return (
    <div>
      <Stack direction={"column"}>
        {sortMessagesByTimestamp(chatList)?.map((i, key) => {
          return (
            <Box key={key}>
              <Box
                onClick={() => {
                  getChatConvo(i);
                  INBOX.setData({ ...INBOX.data, openedChat: i });
                }}
                sx={{
                  cursor: "pointer",
                  bgcolor:
                    (i?.is_opened < 1 && "rgba(85, 255, 56, 0.04)") ||
                    (i?.sender_name == "Web-user" && "#e1fff7"),
                }}
                p={2}
              >
                <Stack width={"100%"} spacing={0.5} direction={"column"}>
                  <Stack direction={"row"} alignItems={"center"}>
                    {i?.contact && (
                      <Tooltip title={data.thisContactIssaved}>
                        <ContactPageOutlined
                          sx={{ height: 18, width: 18, color: "gray" }}
                        />
                      </Tooltip>
                    )}
                    <Typography
                      fontWeight={800}
                      color={i?.is_opened > 0 ? "#676667" : "black"}
                      variant="body1"
                    >
                      {i?.contact ? i?.contact?.name : i?.sender_name}
                    </Typography>
                  </Stack>
                  <ChatListMsgType data={data} i={i} />
                  <Box p={0.2} />
                  <Box>
                    <Stack
                      direction={"row"}
                      spacing={2}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Box display={"flex"} gap={"5px"}>
                        {i?.chat_status === "open" && open}
                        {i?.chat_status === "solved" && solved}
                        {i?.chat_status === "pending" && pending}
                        {i?.agentConnect === 1 && agentConnect(i)}
                      </Box>
                      <Tooltip
                        title={moment
                          .unix(i?.last_message_came)
                          .format("DD MMM YYYY - hh:mm A")}
                      >
                        <Typography
                          variant="caption"
                          fontWeight={600}
                          color={"#a0a1a0"}
                        >
                          {getFormattedTimestamp(i?.last_message_came)}
                        </Typography>
                      </Tooltip>
                    </Stack>
                  </Box>
                </Stack>
              </Box>
              <Box sx={{ p: 0.003 }} bgcolor={"#E7E7E7"} />
            </Box>
          );
        })}
      </Stack>
    </div>
  );
};

export default ChatList;
