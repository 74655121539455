import {
  Box,
  Chip,
  Dialog,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import TextFieldNew from "../../../../components/TextFieldNew";
import CloseBtn from "../../../../context/CloseBtn";
import { GlobalContext } from "../../../../context/GlobalContext";
import AddButton from "./AddButton";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers";
import { convertISODatetoStringDate } from "../../../../utils/DateFormatter";
const languages = [
  { type: "One-time", code: "One-time" },
  // { type: "Recurring", code: "Recurring" },
];

const CreateCampaignsDialog = ({
  getCampaignsList,
  state,
  templetList,
  setState,
  data,
}) => {
  const CON = React.useContext(GlobalContext);
  const [isViewOnly, setViewOnly] = React.useState(false);
  const [states, setStates] = React.useState({
    name: "",
    startTime: "",
    endTime: "",
    campaignType: "",
    segment: "",
    templetName: "",
  });

  async function delTemplet(name) {
    if (window.confirm(data.AUS)) {
      const res = await CON.hitAxios({
        path: "/api/user/del_meta_templet",
        post: true,
        admin: false,
        obj: { name },
      });
      if (res.data.success) {
        getCampaignsList();
      }
    }
  }
  const addCampaign = async () => {
    if (
      !states.name ||
      !states.startTime ||
      !states.templetName ||
      !states.segment ||
      !states.campaignType
    ) {
      CON.setData({
        ...CON.data,
        snack: true,
        snack_msg: "Please fill require details",
        snack_success: false,
      });
      setTimeout(() => {
        CON.setData({
          ...CON.data,
          snack: false,
          snack_msg: "",
          snack_success: false,
        });
      }, 4000);
      return;
    }
    const res = await CON.hitAxios({
      path: "/api/campaigns/create",
      post: true,
      admin: false,
      obj: {
        ...states,
        startTime: new Date(states.startTime).toLocaleString(),
        endTime: states.endTime
          ? new Date(states.endTime).toLocaleString()
          : "",
        orgId: JSON.parse(localStorage.getItem("myData"))?._id,
      },
    });
    if (res.data.success) {
      setState({ ...state, addCampaignsDialog: false });
      getCampaignsList();
    }
  };
  useEffect(() => {
    setStates(state.campaignDate);
    setViewOnly(state.campaignDate.isViewOnly);
  }, [state]);
  return (
    <Dialog
      maxWidth="lg"
      open={state.addCampaignsDialog}
      onClose={() => setState({ ...state, addCampaignsDialog: false })}
      PaperProps={{
        style: { borderRadius: 10 },
      }}
    >
      <Box p={2}>
        <Stack mb={1} direction={"column"} spacing={3}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box display={"flex"} alignItems={"center"} gap="30px">
              {" "}
              {isViewOnly == false && (
                <>
                  {" "}
                  <Typography fontWeight={600}>{"Add new Campaign"}</Typography>
                </>
              )}
              {isViewOnly == true && (
                <Box display={"flex"} alignItems={"center"}>
                  {" "}
                  <Typography fontWeight={600} color={"green"}>
                    View Campaign
                  </Typography>
                </Box>
              )}
            </Box>
            <Box display={"flex"} gap="30px" alignItems={"center"}>
              <CloseBtn
                onClick={() =>
                  setState({ ...state, addCampaignsDialog: false })
                }
              />
            </Box>
          </Stack>
        </Stack>
        <Divider variant="middle" />

        <Box mt={4}>
          <Grid container spacing={2}>
            <Grid item>
              <Box pr={2} overflow={"auto"} height={500}>
                <Stack direction={"column"} spacing={3}>
                  <Stack direction={"column"} spacing={1}>
                    <TextFieldNew
                      value={states?.name}
                      onChange={(e) =>
                        setStates({
                          ...states,
                          name: e.target.value,
                        })
                      }
                      sx={{
                        "& fieldset": { border: "none" },
                      }}
                      bgColor={"#f5f7fb"}
                      placeholder={"Campaign Name"}
                    />
                  </Stack>

                  <Stack minWidth={200} direction={"column"} spacing={1}>
                    <FormControl size="small" fullWidth>
                      <InputLabel size="small" id="demo-simple-select-label">
                        {"Type"}
                      </InputLabel>
                      <Select
                        sx={{ borderRadius: 2 }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={states.campaignType}
                        label={"Type"}
                        onChange={(e) =>
                          setStates({ ...states, campaignType: e.target.value })
                        }
                      >
                        {languages.map((i, key) => {
                          return (
                            <MenuItem key={key} value={i.type}>
                              {i?.type}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Stack>
                  <Divider variant="middle" />
                  <Stack minWidth={200} direction={"column"} spacing={1}>
                    <FormControl size="small" fullWidth>
                      <InputLabel size="small" id="demo-simple-select-label">
                        {"Segment"}
                      </InputLabel>
                      <Select
                        sx={{ borderRadius: 2 }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={states.segment}
                        label={"Segment"}
                        onChange={(e) =>
                          setStates({ ...states, segment: e.target.value })
                        }
                      >
                        {state.allSegments.map((i, key) => {
                          return (
                            <MenuItem key={key} value={i._id}>
                              {i?.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Stack>
                  <Divider variant="middle" />
                  <Stack minWidth={200} direction={"column"} spacing={1}>
                    <FormControl size="small" fullWidth>
                      <InputLabel size="small" id="demo-simple-select-label">
                        {"Templet"}
                      </InputLabel>
                      <Select
                        sx={{ borderRadius: 2 }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={states.templetName}
                        label={"Templet"}
                        onChange={(e) =>
                          setStates({ ...states, templetName: e.target.value })
                        }
                      >
                        {templetList.map((i, key) => {
                          if (
                            i.status &&
                            i.actualMetaTemplet.status == "APPROVED"
                          ) {
                            return (
                              <MenuItem
                                key={key}
                                value={i.actualMetaTemplet.name}
                              >
                                {i?.actualMetaTemplet.name}
                              </MenuItem>
                            );
                          }
                        })}
                      </Select>
                    </FormControl>
                  </Stack>
                  <Divider variant="middle" />

                  <Stack minWidth={200} direction={"column"} spacing={1}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Box
                        components={["DatePicker", "DatePicker"]}
                        display={"flex"}
                        justifyContent={"flex-end"}
                        gap="5px"
                      >
                        <DateTimePicker
                          label="Start Time"
                          // inputFormat="YYYY-MM-DD HH:mm:ss"
                          disablePast
                          renderInput={(props) => (
                            <TextField
                              size="small"
                              InputProps={{ style: { borderRadius: 10 } }}
                              {...props}
                            />
                          )}
                          value={states.startTime}
                          onChange={(newValue) => {
                            CON.setData({
                              ...CON.data,
                              snack: false,
                              snack_msg: "",
                              snack_success: false,
                            });
                            setStates({
                              ...states,
                              startTime: newValue.$d,
                            });
                            // getData(dateFormate(newValue.$d), date.endTime);
                          }}
                        />
                        {states.campaignType != "One-time" && (
                          <DateTimePicker
                            label="End Time"
                            disablePast
                            inputFormat="YYYY-MM-DD HH:mm:ss"
                            renderInput={(props) => (
                              <TextField
                                size="small"
                                InputProps={{ style: { borderRadius: 10 } }}
                                {...props}
                              />
                            )}
                            disabled={
                              states.campaignType == "One-time" ? true : false
                            }
                            value={states.endTime}
                            onChange={(newValue) => {
                              CON.setData({
                                ...CON.data,
                                snack: false,
                                snack_msg: "",
                                snack_success: false,
                              });
                              setStates({
                                ...states,
                                endTime: newValue.$d,
                              });
                            }}
                          />
                        )}
                      </Box>
                    </LocalizationProvider>
                  </Stack>
                  {!isViewOnly && <AddButton addTemplate={addCampaign} />}
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Dialog>
  );
};

export default CreateCampaignsDialog;
