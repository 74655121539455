import { ScheduleSend, SettingsOutlined } from "@mui/icons-material";
import { Box, Chip, Stack } from "@mui/material";
import React from "react";
import WhatsappIntegration from "../channels/WhatsappIntegration";
import SideBar from "./SideBar";
import SideScreen from "./SideScreen";
const Orders = ({ data }) => {
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  const [state, setState] = React.useState({
    sideBar: false,
    addDialog: false,
  });
  const [menu, setMenu] = React.useState({
    selected: "Configration",
  });
  const userData = JSON.parse(localStorage.getItem("myData"));
  React.useEffect(() => {
    const updateScreenWidth = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", updateScreenWidth);
    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);

  return userData?.isMetaRegistred ? (
    <div>
      <Stack direction={"row"}>
        <SideBar
          setMenu={setMenu}
          menu={menu}
          state={state}
          setState={setState}
          data={data}
          screenWidth={screenWidth}
        />

        <Box sx={{ width: screenWidth > 899 ? screenWidth - 250 : "100%" }}>
          {screenWidth < 900 && (
            <Box
              display={"flex"}
              flexWrap={"wrap"}
              gap={"10px"}
              p={2}
              pr={2}
              pl={2}
            >
              <Chip
                icon={
                  <SettingsOutlined
                    sx={{ color: "gray", height: 20, width: 20 }}
                  />
                }
                variant={
                  menu.selected === "Configration" ? "filled" : "outlined"
                }
                onClick={() => setMenu({ ...menu, selected: "Configration" })}
                label={"Configration"}
              />
              <Chip
                icon={
                  <ScheduleSend sx={{ color: "gray", height: 20, width: 20 }} />
                }
                variant={
                  menu.selected === "Action_Needed_Orders" ? "filled" : "outlined"
                }
                onClick={() => setMenu({ ...menu, selected: "Action_Needed_Orders" })}
                label={"Action Needed Orders"}
              />
              {/* <Chip
                icon={
                  <ScheduleSend sx={{ color: "gray", height: 20, width: 20 }} />
                }
                variant={menu.selected === "History" ? "filled" : "outlined"}
                onClick={() => setMenu({ ...menu, selected: "History" })}
                label="History"
              /> */}
            </Box>
          )}

          <SideScreen
            setMenu={setMenu}
            menu={menu}
            state={state}
            setState={setState}
            data={data}
            screenWidth={screenWidth}
          />
        </Box>
      </Stack>
    </div>
  ) : (
    <Box m={3} p={2} bgcolor={"white"} borderRadius={2}>
      <WhatsappIntegration />
    </Box>
  );
};

export default Orders;
