import { SmartToy, Close, EditOutlined } from "@mui/icons-material";
import {
  Grid,
  IconButton,
  Stack,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import React, { useContext } from "react";
import TextFieldNew from "../../components/TextFieldNew";
import { GlobalContext } from "../../context/GlobalContext";

const PaymentModeComponent = ({ data, state, hitAxios, userData }) => {
  const CON = useContext(GlobalContext);
  const [paymentModeArray, setPaymentModeArray] = React.useState(
    state?.paymentModeArray ?? []
  );
  const [availableOption, setAvailableOption] = React.useState(
    state?.paymentModeArray ?? []
  );
  const [paymentModeObj, setPaymentModeObj] = React.useState({
    userId: userData._id,
    paymentEnabled: false,
    paymentType: "cash_on_delivery",
    paymentName: "COD (Cash on Delivery)",
    paymentKeyId: "",
    paymentApiKey: "",
    paymentBase64Key: "",
    paymentKeySecret: "",
  });

  const [popUpOpen, setPopUpOpen] = React.useState("close");
  const addPaymentModes = async () => {
    let flag = false;
    paymentModeArray.map((el) => {
      if (el.paymentName == paymentModeObj.paymentName) {
        flag = true;
      }
    });
    if (flag) {
      CON.setData({
        ...CON.data,
        snack: true,
        snack_msg: "Payment name is already there !",
        snack_success: false,
      });
      setTimeout(() => {
        CON.setData({
          ...CON.data,
          snack: false,
          snack_msg: "",
          snack_success: false,
        });
      }, 4000);
      return;
    }
    const res = await hitAxios({
      path: process.env.REACT_APP_BACKEND_BASE_URL + "/backend/paymentModes",
      post: true,
      admin: false,
      obj: [paymentModeObj],
    });
    if (res.status === 200) {
      getAllPaymentModes();
    }
    setTimeout(() => {
      setPopUpOpen("close");
    }, 500);
  };

  async function getAllPaymentModes() {
    const res = await hitAxios({
      path:
        process.env.REACT_APP_BACKEND_BASE_URL +
        "/backend/paymentModes/" +
        userData._id,
      post: false,
      admin: false,
      token_user: "",
    });
    if (res.status === 200) {
      setPaymentModeArray(res.data);
      const esp = res.data.filter((el) => el.paymentType == "cash_on_delivery");
      if (esp.length >= 1) {
        setAvailableOption([{ value: "Online", name: "Online" }]);
      } else {
        setAvailableOption([
          { value: "cash_on_delivery", name: "COD (Cash on Delivery)" },
          { value: "Online", name: "Online" },
        ]);
      }
    }
  }

  async function updatePaymentMethod(paymentObj) {
    let flag = 0;
    const respd = paymentModeArray.map((el) => {
      if (el._id == paymentObj._id) {
        el = paymentObj;
      }
      return el;
    });
    respd.map((el) => {
      if (el.paymentName == paymentModeObj.paymentName) {
        flag += 1;
      }
    });
    console.log("flag", flag);
    if (flag > 1) {
      CON.setData({
        ...CON.data,
        snack: true,
        snack_msg: "Payment name is already there !",
        snack_success: false,
      });
      setTimeout(() => {
        CON.setData({
          ...CON.data,
          snack: false,
          snack_msg: "",
          snack_success: false,
        });
      }, 4000);
      return;
    }
    const res = await hitAxios({
      path:
        process.env.REACT_APP_BACKEND_BASE_URL +
        "/backend/paymentModes/" +
        paymentObj._id +
        "/update",
      post: false,
      admin: false,
      token_user: "",
      patch: true,
      obj: paymentObj,
    });
    if (res.status === 200) {
      getAllPaymentModes();
    }
    setTimeout(() => {
      setPopUpOpen("close");
    }, 500);
  }

  React.useEffect(() => {
    getAllPaymentModes();
  }, []);

  return (
    <Grid container p={1}>
      <Grid item xs={12} p={1}>
        <Stack direction={"row"} spacing={2}>
          <Grid container spacing={2}>
            {/* Title at left */}
            <Grid item xs={6}>
              <Typography variant="h6">Payment Modes</Typography>
            </Grid>

            {/* Button at right */}
            <Grid item xs={6} style={{ textAlign: "right" }}>
              <Button
                variant="contained"
                onClick={() => {
                  setPopUpOpen("New");
                  setPaymentModeObj({
                    ...paymentModeObj,
                    paymentType: "",
                    paymentName: "",
                    paymentKeyId: "",
                    paymentApiKey: "",
                    paymentBase64Key: "",
                    paymentKeySecret: "",
                  });
                }}
              >
                Add Payment Mode
              </Button>
            </Grid>

            <Grid container direction="column" sx={{ paddingLeft: "0px" }}>
              {paymentModeArray.map((itm) => (
                <Grid key={itm._id} item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={itm}
                        checked={itm?.paymentEnabled}
                        onClick={(e) => {
                          updatePaymentMethod({
                            ...itm,
                            paymentEnabled: !itm?.paymentEnabled,
                          });
                        }}
                      />
                    }
                    label={itm.paymentName}
                  />
                  <IconButton
                    aria-label="Edit"
                    onClick={() => {
                      setPaymentModeObj(itm);
                      setPopUpOpen("Edit");
                    }}
                  >
                    <EditOutlined />
                  </IconButton>
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Dialog
            open={popUpOpen !== "close"}
            onClose={() => setPopUpOpen("close")}
          >
            <DialogTitle
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {popUpOpen} Payment Mode
              <IconButton
                aria-label="close"
                onClick={() => setPopUpOpen("close")}
              >
                <Close />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <FormControl fullWidth>
                <InputLabel
                  id="popup-payment-type-label"
                  sx={{
                    paddingTop: "10px",
                    "&.Mui-focused": {
                      paddingTop: "20px",
                    },
                  }}
                  shrink={true}
                >
                  Payment Type *
                </InputLabel>
                <Select
                  size="small"
                  value={paymentModeObj?.paymentType}
                  onChange={(e) =>
                    setPaymentModeObj({
                      ...paymentModeObj,
                      paymentType: e.target.value,
                      paymentName:
                        e.target.value === "cash_on_delivery"
                          ? "COD (Cash on Delivery)"
                          : "",
                      paymentKeyId: "",
                      paymentApiKey: "",
                      paymentBase64Key: "",
                      paymentKeySecret: "",
                    })
                  }
                  sx={{ mb: 2, mt: 2, borderRadius: "10px" }}
                  labelId="popup-payment-type-label"
                  label="Payment Type *"
                  id="popup-payment-type"
                >
                  {availableOption.map((el) => (
                    <MenuItem value={el.value}>{el.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              {paymentModeObj?.paymentType != "cash_on_delivery" && (
                <FormControl fullWidth>
                  <InputLabel
                    id="popup-payment-type-label"
                    sx={{
                      paddingTop: "10px",
                      "&.Mui-focused": {
                        paddingTop: "20px",
                      },
                    }}
                    shrink={true}
                  >
                    Payment Name *
                  </InputLabel>
                  <Select
                    size="small"
                    value={paymentModeObj?.paymentName}
                    onChange={(e) =>
                      setPaymentModeObj({
                        ...paymentModeObj,
                        paymentName: e.target.value,
                        paymentKeyId: "",
                        paymentApiKey: "",
                        paymentBase64Key: "",
                        paymentKeySecret: "",
                      })
                    }
                    sx={{ mb: 2, mt: 2, borderRadius: "10px" }}
                    labelId="popup-payment-type-label"
                    label="Payment Type *"
                    id="popup-payment-type"
                  >
                    {/* {availableOption.map((el) => ( */}
                    <MenuItem value={"razorpay"}>Razorpay</MenuItem>
                    {/* <MenuItem value={"juspay"}>Juspay</MenuItem> */}
                    {/* ))} */}
                  </Select>
                </FormControl>
              )}
              {/* <TextFieldNew
                fullWidth
                value={paymentModeObj?.paymentName}
                onChange={(e) =>
                  setPaymentModeObj({
                    ...paymentModeObj,
                    paymentName: e.target.value,
                  })
                }
                startIcon={
                  <SmartToy
                    sx={{
                      color: (t) => t.palette.primary.main,
                      height: 20,
                      width: 20,
                    }}
                  />
                }
                label="Payment Name *"
              /> */}
              <TextFieldNew
                fullWidth
                value={paymentModeObj?.paymentKeyId}
                onChange={(e) =>
                  setPaymentModeObj({
                    ...paymentModeObj,
                    paymentKeyId: e.target.value,
                  })
                }
                sx={{ mb: 2, mt: 2 }}
                disabled={paymentModeObj?.paymentType === "cash_on_delivery"}
                startIcon={
                  <SmartToy
                    sx={{
                      color: (t) => t.palette.primary.main,
                      height: 20,
                      width: 20,
                    }}
                  />
                }
                label="Payment KeyId *"
              />
              <TextFieldNew
                fullWidth
                value={paymentModeObj?.paymentKeySecret}
                onChange={(e) =>
                  setPaymentModeObj({
                    ...paymentModeObj,
                    paymentKeySecret: e.target.value,
                  })
                }
                disabled={paymentModeObj?.paymentType === "cash_on_delivery"}
                startIcon={
                  <SmartToy
                    sx={{
                      color: (t) => t.palette.primary.main,
                      height: 20,
                      width: 20,
                    }}
                  />
                }
                label="Payment Key Secret *"
              />
              <TextFieldNew
                fullWidth
                value={paymentModeObj?.paymentBase64Key}
                onChange={(e) =>
                  setPaymentModeObj({
                    ...paymentModeObj,
                    paymentBase64Key: e.target.value,
                  })
                }
                sx={{ mb: 2, mt: 2 }}
                disabled={paymentModeObj?.paymentType === "cash_on_delivery"}
                startIcon={
                  <SmartToy
                    sx={{
                      color: (t) => t.palette.primary.main,
                      height: 20,
                      width: 20,
                    }}
                  />
                }
                label="Payment Base64Key *"
              />
              <TextFieldNew
                fullWidth
                value={paymentModeObj?.paymentApiKey}
                onChange={(e) =>
                  setPaymentModeObj({
                    ...paymentModeObj,
                    paymentApiKey: e.target.value,
                  })
                }
                disabled={paymentModeObj?.paymentType === "cash_on_delivery"}
                startIcon={
                  <SmartToy
                    sx={{
                      color: (t) => t.palette.primary.main,
                      height: 20,
                      width: 20,
                    }}
                  />
                }
                label="Payment ApiKey *"
              />
            </DialogContent>
            <div style={{ margin: "20px" }}>
              <Button
                onClick={() => {
                  if (popUpOpen === "New") {
                    addPaymentModes();
                  } else {
                    updatePaymentMethod(paymentModeObj);
                  }
                }}
                variant="contained"
                disabled={
                  paymentModeObj?.paymentType === "cash_on_delivery"
                    ? !paymentModeObj.paymentName || false
                    : !paymentModeObj.paymentName ||
                      !paymentModeObj.paymentKeyId ||
                      !paymentModeObj.paymentKeySecret ||
                      !paymentModeObj.paymentBase64Key ||
                      !paymentModeObj.paymentApiKey ||
                      false
                }
              >
                Submit
              </Button>
            </div>
          </Dialog>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default PaymentModeComponent;
