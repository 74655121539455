import {
  CircleNotifications,
  MonetizationOn,
  MonetizationOnOutlined,
  Stars,
} from "@mui/icons-material";
import { Box, Chip, Divider, Stack, Typography } from "@mui/material";
import React from "react";

const UpdatePlan = ({ state, setState, data, getUsers, hitAxios }) => {
  const [plans, setPlans] = React.useState([]);

  function returnUserPlan(userData) {
    if (userData?.plan) {
      const plan = JSON.parse(userData?.plan);
      return plan;
    } else {
      return {};
    }
  }

  async function getPlans() {
    const res = await hitAxios({
      path: "/api/admin/get_plans",
      post: false,
      admin: true,
      token_user: "",
    });
    if (res.data.success) {
      setPlans(res.data.data);
    }
  }

  async function updateUserPlan(uid, plan) {
    if (window.confirm(data.AUS)) {
      const res = await hitAxios({
        path: "/api/admin/update_users_plan",
        post: true,
        admin: true,
        obj: {
          uid,
          plan,
        },
      });
      if (res.data.success) {
        setState({
          ...state,
          dialogEditData: {
            ...state.dialogEditData,
            plan: JSON.stringify(plan),
          },
        });
        getUsers();
      }
    }
  }

  React.useEffect(() => {
    getPlans();
  }, []);

  return (
    <Box mt={2}>
      <Divider />
      <Stack mt={2} direction={"column"} spacing={2}>
        <Stack spacing={1} direction={"row"}>
          <CircleNotifications sx={{ color: "orange" }} />
          <Stack direction={"column"}>
            <Typography fontWeight={600}>{data.updatePLan}</Typography>
            <Typography variant="caption" color={"gray"}>
              {data.clickToUpdateUserPlan}
            </Typography>
          </Stack>
        </Stack>

        <Box>
          {plans?.map((i, key) => {
            return (
              <Chip
                onClick={() => {
                  if (state?.dialogEditData?.plan?.title != i?.title) {
                    updateUserPlan(state?.dialogEditData?.sqlId, i);
                  }
                }}
                variant={
                  state?.dialogEditData?.plan?.title === i?.title
                    ? "filled"
                    : "outlined"
                }
                icon={<Stars sx={{ color: "gold" }} />}
                sx={{ p: 3, mr: 2, mb: 2 }}
                key={key}
                label={i?.title}
              />
            );
          })}
        </Box>
      </Stack>
    </Box>
  );
};

export default UpdatePlan;
