import {
  Box,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import TextFieldNew from "../../../components/TextFieldNew";
import CloseBtn from "../../../context/CloseBtn";
import { GlobalContext } from "../../../context/GlobalContext";
import {
  Fingerprint,
  HourglassTopOutlined,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import PhoneInput from "react-phone-input-2";
import { LoadingButton } from "@mui/lab";

const AddAgentDialog = ({ getAllagents, state, allgents, setState, data }) => {
  const CON = React.useContext(GlobalContext);
  const [isError, setIsError] = useState(false);
  const [isViewOnly, setViewOnly] = React.useState(false);
  const initalPermission = [
    { moduleName: "dashboard", permission: 0 },
    { moduleName: "automation", permission: 0 },
    { moduleName: "pay-history", permission: 0 },
    { moduleName: "analytics", permission: 0 },
    { moduleName: "phonebook", permission: 0 },
    { moduleName: "inbox", permission: 0 },
    { moduleName: "chatbot", permission: 0 },
    { moduleName: "broadcast", permission: 0 },
    { moduleName: "channels", permission: 0 },
    { moduleName: "api-access", permission: 0 },
  ];
  const [permissonArray, setPermission] = React.useState(initalPermission);
  const [states, setStates] = React.useState({
    firstName: "",
    email: "",
    password: "",
    isPassEnter: true,
    mobile_with_country_code: "",
  });

  const addAgent = async () => {
    if (
      !states.firstName ||
      !states.password ||
      !states.email ||
      !states.mobile_with_country_code
    ) {
      CON.setData({
        ...CON.data,
        snack: true,
        snack_msg: "Please fill require details",
        snack_success: false,
      });
      setTimeout(() => {
        CON.setData({
          ...CON.data,
          snack: false,
          snack_msg: "",
          snack_success: false,
        });
      }, 4000);
      return;
    }
    const myData = JSON.parse(localStorage.getItem("myData"));
    const res = await CON.hitAxios({
      path: "/api/user/agent/signup",
      post: true,
      admin: false,
      obj: {
        ...states,
        name: states.firstName,
        companyName: "",
        permissionArray: permissonArray,
        uid: myData?.sqlId,
        orgId: myData?._id,
      },
    });
    if (res.data.success) {
      setState({ ...state, addAgentDialog: false });
      getAllagents();
    }
  };
  const updateAgent = async () => {
    if (
      !states.firstName ||
      !states.password ||
      !states.email ||
      !states?.mobile_with_country_code
    ) {
      CON.setData({
        ...CON.data,
        snack: true,
        snack_msg: "Please fill require details",
        snack_success: false,
      });
      setTimeout(() => {
        CON.setData({
          ...CON.data,
          snack: false,
          snack_msg: "",
          snack_success: false,
        });
      }, 4000);
      return;
    }
    const myData = JSON.parse(localStorage.getItem("myData"));
    const res = await CON.hitAxios({
      path: "/api/agent/updateAgent",
      post: true,
      admin: false,
      obj: {
        ...states,
        name: states.firstName,
        companyName: "",
        permissionArray: permissonArray,
        uid: myData?.sqlId,
        orgId: myData?._id,
      },
    });
    if (res.data.success) {
      setState({ ...state, addAgentDialog: false });
      getAllagents();
    }
  };
  const checkIsError = async (e) => {
    if (e.firstName == "") {
      setIsError(true);
    } else if (e.email == "") {
      setIsError(true);
    } else if (
      !e.mobile_with_country_code ||
      e?.mobile_with_country_code?.length != 12
    ) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  };
  useEffect(() => {
    console.log("agentData", state.agentData);
    checkIsError(state.agentData);
    setStates(state.agentData);
    setViewOnly(state.agentData.isViewOnly);
    setPermission(state.agentData.permissionArray);
  }, [state]);
  return (
    <Dialog
      maxWidth="lg"
      open={state.addAgentDialog}
      onClose={() => setState({ ...state, addAgentDialog: false })}
      PaperProps={{
        style: { borderRadius: 10 },
      }}
    >
      <Box p={2}>
        <Stack mb={1} direction={"column"} spacing={3}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box display={"flex"} alignItems={"center"} gap="30px">
              {" "}
              {isViewOnly == false && (
                <>
                  {" "}
                  <Typography fontWeight={600}>{"Add new Agent"}</Typography>
                </>
              )}
              {isViewOnly == true && (
                <Box display={"flex"} alignItems={"center"}>
                  {" "}
                  <Typography fontWeight={600} color={"green"}>
                    View Agent
                  </Typography>
                </Box>
              )}
            </Box>
            <Box display={"flex"} gap="30px" alignItems={"center"}>
              <CloseBtn
                onClick={() => setState({ ...state, addAgentDialog: false })}
              />
            </Box>
          </Stack>
        </Stack>
        <Divider variant="middle" />

        <Box mt={2}>
          <Grid container spacing={2}>
            <Grid item>
              <Box pr={2} overflow={"auto"} height={500}>
                <Stack direction={"column"} spacing={3}>
                  <Stack direction={"column"} spacing={1} mt={2}>
                    <TextFieldNew
                      value={states?.firstName}
                      onChange={(e) => {
                        setStates({
                          ...states,
                          firstName: e.target.value,
                        });
                        checkIsError({
                          ...states,
                          firstName: e.target.value,
                        });
                      }}
                      placeholder={" Name"}
                      // label={"Name"}
                    />
                  </Stack>
                  <Stack direction={"column"} spacing={1}>
                    <TextFieldNew
                      value={states?.email}
                      onChange={(e) => {
                        setStates({
                          ...states,
                          email: e.target.value,
                        });
                        checkIsError({
                          ...states,
                          email: e.target.value,
                        });
                      }}
                      placeholder={"Email"}
                      label={"Email"}
                      disabled={isViewOnly}
                    />
                  </Stack>
                  {isViewOnly == false && (
                    <Stack direction={"column"} spacing={1}>
                      <TextFieldNew
                        onChange={(e) => {
                          setStates({ ...states, password: e.target.value });
                          checkIsError({ ...states, password: e.target.value });
                        }}
                        type={states.isPassEnter ? "password" : "text"}
                        endIcon={
                          <IconButton
                            onClick={() =>
                              setStates({
                                ...states,
                                isPassEnter: !states.isPassEnter,
                              })
                            }
                          >
                            {states.isPassEnter ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        }
                        startIcon={
                          <Fingerprint
                            sx={{
                              color: (t) => t.palette.primary.main,
                              height: 20,
                              width: 20,
                            }}
                          />
                        }
                        label={data.password}
                      />
                    </Stack>
                  )}
                  <PhoneInput
                    specialLabel={"Agent Whatsapp no"}
                    country={"in"}
                    value={states?.mobile_with_country_code ?? ""}
                    onChange={(phone, data) => {
                      setStates({
                        ...states,
                        mobile_with_country_code: phone,
                        mobileCodeObj: data,
                      });
                      checkIsError({
                        ...states,
                        mobile_with_country_code: phone,
                        mobileCodeObj: data,
                      });
                    }}
                    isValid={
                      states?.mobile_with_country_code
                        ? states?.mobile_with_country_code?.length == 12
                        : false
                    }
                  />
                  <Divider variant="middle" />
                  <Stack minWidth={200} direction={"column"} spacing={1}>
                    <Box display={"flex"} justifyContent={"center"}>
                      {" "}
                      Permissions
                    </Box>
                    {permissonArray.map((el) => (
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                      >
                        {el.moduleName}
                        <Switch
                          onChange={(e) => {
                            const newArray = permissonArray.map((ell) => {
                              if (ell.moduleName == el.moduleName) {
                                ell.permission = e.target.checked;
                              }
                              return ell;
                            });
                            setPermission(newArray);
                          }}
                          checked={el.permission}
                        />{" "}
                      </Box>
                    ))}
                  </Stack>
                  <Divider variant="middle" />
                  {isViewOnly == false ? (
                    <LoadingButton
                      loading={CON.data?.loading}
                      onClick={addAgent}
                      startIcon={<HourglassTopOutlined />}
                      sx={{ boxShadow: 0, borderRadius: 2 }}
                      fullWidth
                      disabled={isError}
                      variant="contained"
                    >
                      Create
                    </LoadingButton>
                  ) : (
                    <LoadingButton
                      loading={CON.data?.loading}
                      onClick={updateAgent}
                      startIcon={<HourglassTopOutlined />}
                      sx={{ boxShadow: 0, borderRadius: 2 }}
                      fullWidth
                      disabled={isError}
                      variant="contained"
                    >
                      Edit
                    </LoadingButton>
                  )}
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Dialog>
  );
};

export default AddAgentDialog;
