import {
  Box,
  CardActionArea,
  CardMedia,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import moment from "moment";
import {
  Check,
  DoneAll,
  Download,
  DownloadOutlined,
} from "@mui/icons-material";
import { format } from "@flasd/whatsapp-formatting";

const Outgoing = ({ i, data, notShow, chatConvo, bgText }) => {
  return (
    <div id={i?.metaChatId}>
      <Stack
        alignItems={"flex-end"}
        direction={"row"}
        justifyContent={"flex-end"}
      >
        {!notShow && (
          <Box ml={0.6} mb={1}>
            <Stack spacing={0.5} alignItems={"center"} direction={"row"}>
              <Typography fontSize={10} color={"gray"}>
                {moment.unix(i?.timestamp).format("DD/MM/YYYY hh:mm A")}
              </Typography>

              {!i?.status && <Check sx={{ height: 18, width: 18 }} />}
              {i?.status === "delivered" && (
                <DoneAll sx={{ eight: 18, width: 18 }} />
              )}
              {i?.status === "read" && (
                <DoneAll sx={{ eight: 18, width: 18, color: "#4CA340" }} />
              )}
            </Stack>
          </Box>
        )}

        <Box
          width={notShow ? "100%" : undefined}
          maxWidth={!notShow ? 505 : undefined}
          bgcolor={bgText ? bgText : "#dcf1e5"}
          p={1.5}
          sx={{
            borderTopRightRadius: 20,
            borderTopLeftRadius: 20,
            borderBottomLeftRadius: 20,
            m: 1,
          }}
        >
          <Box sx={{ wordWrap: "break-word" }} position={"relative"}>
            <Stack spacing={1} direction={"column"}>
              <CardMedia
                sx={{ borderRadius: 2 }}
                height={"100%"}
                width={"100%"}
                src={i?.msgContext?.image && i?.msgContext?.image?.link}
                component={"img"}
              />

              <Typography
                style={{ whiteSpace: "pre-line" }}
                variant="caption"
                color={"gray"}
              >
                {i?.msgContext?.image && i?.msgContext?.image?.caption}
              </Typography>
            </Stack>
            <Box position={"absolute"} top={0} right={0}>
              <IconButton
                onClick={() => {
                  window.open(
                    i?.msgContext?.image && i?.msgContext?.image?.link
                  );
                }}
                size="small"
                sx={{ bgcolor: "rgba(255, 255, 255, 0.1)" }}
              >
                <DownloadOutlined />
              </IconButton>
            </Box>
            <Box right={0} position={"absolute"}>
              <Box sx={{ cursor: "pointer" }}>
                <Typography variant="h7">{i?.reaction}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Stack>
    </div>
  );
};

export default Outgoing;
