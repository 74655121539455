import {
  Box,
  Button,
  Chip,
  Dialog,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import CloseBtn from "../context/CloseBtn";
import TextFieldNew from "../components/TextFieldNew";
import {
  AccessTime,
  AccessTimeFilled,
  CancelOutlined,
  CheckCircleOutlineRounded,
  CheckOutlined,
  DatasetLinked,
  FileCopyOutlined,
  PermContactCalendar,
  ReplyAllOutlined,
  ScreenShareOutlined,
  SmartToy,
  SpeakerNotes,
  Star,
  Stars,
  StarsOutlined,
  Style,
  Update,
  UpdateRounded,
} from "@mui/icons-material";
import { GlobalContext } from "../context/GlobalContext";
import { LoadingButton } from "@mui/lab";
import moment from "moment-timezone";
import momentTime from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CountryCodeDropDown from "../components/CountryCodeDropDown";

const ProfileDialog = ({ data, states, setStates }) => {
  const { hitAxios, getMyDetails } = React.useContext(GlobalContext);
  const CON = React.useContext(GlobalContext);
  const [timeZones, setTimeZones] = React.useState([]);
  const history = useHistory();
  const [profile, setProfile] = React.useState({});

  const [apiKeys, setApiKeys] = React.useState({});

  const [shortUrlData, setShortUrlData] = React.useState({
    inputValue: "",
    inValidUrl: true,
  });

  const handleCopyClick = () => {
    CON.setData({
      ...CON.data,
      snack: true,
      snack_msg: "ShortUrl Copied",
      snack_success: true,
    });
    setTimeout(() => {
      // Setting Default value
      CON.setData({
        ...CON.data,
        snack: false,
        snack_msg: "Great",
        snack_success: true,
      });
    }, 4000);
    navigator.clipboard.writeText(shortUrlData?.shortURL);
  };

  async function getProfile() {
    const myData = JSON.parse(localStorage.getItem("myData"));
    // const res = await hitAxios({
    //   path: "/api/user/get_me",
    //   post: true,
    //   admin: false,
    //   obj: {
    //     email: JSON.parse(localStorage.getItem("myData"))?.email,
    //   },
    //   token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    // });
    // if (res.data.success) {
    //   setProfile({ ...res.data.data });
    // }
  }

  async function getShortendUrl() {
    if (shortUrlData?.inputValue) {
      const res = await hitAxios({
        path: process.env.REACT_APP_BACKEND_BASE_URL + "/url",
        post: true,
        admin: false,
        obj: {
          url: shortUrlData?.inputValue,
        },
      });
      if (res.data.success) {
        setShortUrlData({
          ...shortUrlData,
          inputValue: "",
          shortURL: res.data.shortURL,
        });
      }
    }
  }

  function getAllTimezones() {
    try {
      const timezones = moment.tz.names();
      setTimeZones(timezones);
    } catch (error) {
      return `Error: ${error.message}`;
    }
  }

  async function getKeys() {
    const res = await hitAxios({
      path: "/api/user/get_meta_keys",
      post: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.data.success) {
      setApiKeys({ ...res.data.data });
    }
  }

  React.useEffect(() => {
    getKeys();
    getProfile();
    getAllTimezones();
  }, [states.dialog]);

  async function updteAPi() {
    const res = await hitAxios({
      path: "/api/user/update_meta",
      post: true,
      admin: false,
      obj: apiKeys,
    });
  }

  async function updateProfile() {
    const res = await hitAxios({
      path: "/api/user/update_profile",
      post: true,
      admin: false,
      obj: {
        ...getMyDetails,
        mobile_with_country_code: "+" + getMyDetails.mobile_with_country_code,
      },
    });
  }

  return (
    <Dialog
      fullWidth
      // maxWidth={states?.dialogType === "SUBSCRIPTION" ? 'lg' : 'sm'}
      PaperProps={{
        style: { borderRadius: 10 },
      }}
      onClose={() => {
        setStates({ ...states, dialog: false });
      }}
      open={states?.dialog}
    >
      <Box p={2}>
        <Stack
          mb={2}
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography fontWeight={600} variant="h7">
            {states?.dialogType === "API_META" && data.metaApiKeys}
            {states?.dialogType === "PROFILE" && data.profile}
          </Typography>
          <CloseBtn onClick={() => setStates({ ...states, dialog: false })} />
        </Stack>

        <Divider />

        <Box mt={2}>
          <Stack direction={"column"} spacing={2}>
            <Stack direction={"row"} spacing={2}>
              {/* <Chip
                onClick={() => setStates({ ...states, dialogType: "API_META" })}
                variant={
                  states.dialogType === "API_META" ? "filled" : "outlined"
                }
                label={data.metaApiKeys}
              /> */}

              <Chip
                onClick={() => setStates({ ...states, dialogType: "PROFILE" })}
                variant={
                  states.dialogType === "PROFILE" ? "filled" : "outlined"
                }
                label={data.profile}
              />

              <Chip
                onClick={() =>
                  setStates({ ...states, dialogType: "SUBSCRIPTION" })
                }
                variant={
                  states.dialogType === "SUBSCRIPTION" ? "filled" : "outlined"
                }
                label={data.subscription}
              />

              {/* <Chip
                onClick={() =>
                  setStates({ ...states, dialogType: "SHORT_URL" })
                }
                variant={
                  states.dialogType === "SHORT_URL" ? "filled" : "outlined"
                }
                label="Shorten URLs"
              /> */}
            </Stack>

            {states?.dialogType === "API_META" && (
              <>
                <TextFieldNew
                  value={apiKeys?.waba_id}
                  onChange={(e) =>
                    setApiKeys({ ...apiKeys, waba_id: e.target.value })
                  }
                  label={data.waBAID}
                  fullWidth
                />
                <TextFieldNew
                  value={apiKeys?.business_account_id}
                  onChange={(e) =>
                    setApiKeys({
                      ...apiKeys,
                      business_account_id: e.target.value,
                    })
                  }
                  label={data.businessAcID}
                  fullWidth
                />
                <TextFieldNew
                  value={apiKeys?.access_token}
                  onChange={(e) =>
                    setApiKeys({ ...apiKeys, access_token: e.target.value })
                  }
                  helperText={data.permTokenRecm}
                  label={data.MetaAccessToken}
                  fullWidth
                />
                <TextFieldNew
                  value={apiKeys?.business_phone_number_id}
                  onChange={(e) =>
                    setApiKeys({
                      ...apiKeys,
                      business_phone_number_id: e.target.value,
                    })
                  }
                  label={data.metaWaPhoneID}
                  fullWidth
                />
                <TextFieldNew
                  value={apiKeys?.app_id}
                  onChange={(e) =>
                    setApiKeys({ ...apiKeys, app_id: e.target.value })
                  }
                  label={data.appId}
                  fullWidth
                />

                <LoadingButton
                  loading={CON?.data?.loading}
                  onClick={updteAPi}
                  startIcon={<UpdateRounded />}
                  sx={{ borderRadius: 2, boxShadow: 0 }}
                  variant="contained"
                >
                  {data.save}
                </LoadingButton>
              </>
            )}

            {states?.dialogType === "PROFILE" && (
              <>
                <TextFieldNew
                  onChange={(e) =>
                    setProfile({ ...getMyDetails, name: e.target.value })
                  }
                  shrink
                  label={data.name}
                  fullWidth
                  value={getMyDetails.name}
                />

                <TextFieldNew
                  onChange={(e) =>
                    setProfile({ ...getMyDetails, email: e.target.value })
                  }
                  shrink
                  disabled
                  label={data.email}
                  fullWidth
                  value={getMyDetails.email}
                />

                <CountryCodeDropDown
                  state={getMyDetails}
                  setState={setProfile}
                  label={data.mobile}
                  disabled={true}
                />

                <FormControl fullWidth>
                  <InputLabel size="small" id="timezone">
                    {data.selTimezone}
                  </InputLabel>
                  <Select
                    sx={{ borderRadius: 2 }}
                    size="small"
                    labelId="timezone"
                    id="timezone"
                    value={getMyDetails.timezone}
                    label={data.selTimezone}
                  >
                    {timeZones.map((i, key) => {
                      return (
                        <MenuItem
                          onClick={() =>
                            setProfile({ ...getMyDetails, timezone: i })
                          }
                          key={key}
                          value={i}
                        >
                          <Typography variant="body2">{i}</Typography>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                <TextFieldNew
                  onChange={(e) =>
                    setProfile({ ...getMyDetails, newPassword: e.target.value })
                  }
                  label={data.password}
                  helperText={data.ignorePass}
                  fullWidth
                  value={getMyDetails.newPassword}
                />

                <LoadingButton
                  loading={CON?.data?.loading}
                  onClick={updateProfile}
                  startIcon={<UpdateRounded />}
                  sx={{ borderRadius: 2, boxShadow: 0 }}
                  variant="contained"
                >
                  {data.save}
                </LoadingButton>
              </>
            )}

            {states?.dialogType === "SUBSCRIPTION" && (
              <>
                {getMyDetails?.plan && (
                  <Stack direction={"row"} spacing={1}>
                    <Stars sx={{ color: "orange" }} />
                    <Typography fontWeight={600}>
                      {data.youHaveSubsTo}{" "}
                      <a style={{ color: data.color_theme }}>
                        {JSON.parse(getMyDetails?.plan)?.title}
                      </a>
                    </Typography>
                  </Stack>
                )}

                {getMyDetails?.plan && (
                  <Box p={2} bgcolor={"action.hover"} borderRadius={2}>
                    <Stack direction={"column"} spacing={2}>
                      {/* <Stack
                        alignItems={"center"}
                        direction={"row"}
                        spacing={2}
                        justifyContent={"space-between"}
                      >
                        <Stack
                          alignItems={"center"}
                          direction={"row"}
                          spacing={2}
                        >
                          <Style />
                          <Typography>{data.chatTags}</Typography>
                        </Stack>

                        {JSON.parse(getMyDetails?.plan)?.allow_tag > 0 ? (
                          <CheckCircleOutlineRounded sx={{ color: "green" }} />
                        ) : (
                          <CancelOutlined sx={{ color: "red" }} />
                        )}
                      </Stack> */}

                      {/* <Divider /> */}

                      {/* <Stack
                        alignItems={"center"}
                        direction={"row"}
                        spacing={2}
                        justifyContent={"space-between"}
                      >
                        <Stack
                          alignItems={"center"}
                          direction={"row"}
                          spacing={2}
                        >
                          <SpeakerNotes />
                          <Typography>{data.chatNotes}</Typography>
                        </Stack>

                        {JSON.parse(getMyDetails?.plan)?.allow_note > 0 ? (
                          <CheckCircleOutlineRounded sx={{ color: "green" }} />
                        ) : (
                          <CancelOutlined sx={{ color: "red" }} />
                        )}
                      </Stack> */}

                      {/* <Divider /> */}

                      <Stack
                        alignItems={"center"}
                        direction={"row"}
                        spacing={2}
                        justifyContent={"space-between"}
                      >
                        <Stack
                          alignItems={"center"}
                          direction={"row"}
                          spacing={2}
                        >
                          <SmartToy />
                          <Typography>{data.autoChatbot}</Typography>
                        </Stack>

                        {JSON.parse(getMyDetails?.plan)?.allow_chatbot > 0 ? (
                          <CheckCircleOutlineRounded sx={{ color: "green" }} />
                        ) : (
                          <CancelOutlined sx={{ color: "red" }} />
                        )}
                      </Stack>

                      <Divider />

                      <Stack
                        alignItems={"center"}
                        direction={"row"}
                        spacing={2}
                        justifyContent={"space-between"}
                      >
                        <Stack
                          alignItems={"center"}
                          direction={"row"}
                          spacing={2}
                        >
                          <PermContactCalendar />
                          <Typography>{data.phoneBookContactsLImit}</Typography>
                        </Stack>

                        <Typography fontWeight={600}>
                          {getMyDetails?.contact}/
                          {JSON.parse(getMyDetails?.plan)?.contact_limit}
                        </Typography>
                      </Stack>

                      {/* <Divider />

                      <Stack
                        alignItems={"center"}
                        direction={"row"}
                        spacing={2}
                        justifyContent={"space-between"}
                      >
                        <Stack
                          alignItems={"center"}
                          direction={"row"}
                          spacing={2}
                        >
                          <DatasetLinked />
                          <Typography>{data.apiAccess}</Typography>
                        </Stack>

                        {JSON.parse(getMyDetails?.plan)?.allow_api > 0 ? (
                          <CheckCircleOutlineRounded sx={{ color: "green" }} />
                        ) : (
                          <CancelOutlined sx={{ color: "red" }} />
                        )}
                      </Stack> */}

                      <Divider />

                      <Stack
                        alignItems={"center"}
                        direction={"row"}
                        spacing={2}
                        justifyContent={"space-between"}
                      >
                        <Stack
                          alignItems={"center"}
                          direction={"row"}
                          spacing={2}
                        >
                          <AccessTime />
                          <Typography>{data.planDuration}</Typography>
                        </Stack>

                        <Typography fontWeight={600}>
                          {
                            JSON.parse(getMyDetails?.plan)
                              ?.plan_duration_in_days
                          }
                        </Typography>
                      </Stack>

                      <Divider />

                      <Stack
                        alignItems={"center"}
                        direction={"row"}
                        spacing={2}
                        justifyContent={"space-between"}
                      >
                        <Stack
                          alignItems={"center"}
                          direction={"row"}
                          spacing={2}
                        >
                          <AccessTimeFilled />
                          {getMyDetails?.plan_expire ? (
                            <Typography>
                              {momentTime
                                .unix(getMyDetails.plan_expire / 1000)
                                .isBefore(moment())
                                ? data.planExpiredON
                                : data.planDaysLeft}
                            </Typography>
                          ) : (
                            <Typography>{data.planExpiredON}</Typography>
                          )}
                        </Stack>
                        {getMyDetails?.plan_expire ? (
                          <Typography fontWeight={600}>
                            {momentTime
                              .unix(getMyDetails.plan_expire / 1000)
                              .fromNow()}
                          </Typography>
                        ) : (
                          <Typography fontWeight={"bold"} color={"red"}>
                            {data.planExpired}
                          </Typography>
                        )}
                      </Stack>
                    </Stack>
                  </Box>
                )}
              </>
            )}

            {states?.dialogType === "SHORT_URL" && (
              <>
                <Stack direction={"row"} spacing={1}>
                  <ScreenShareOutlined color="primary" />
                  <Typography fontWeight={600}>Paste URL to shorten</Typography>
                </Stack>

                <TextFieldNew
                  fullWidth
                  value={shortUrlData?.inputValue}
                  label="Long URL Links"
                  onChange={(elm) => {
                    if (elm.target.value.startsWith("http")) {
                      setShortUrlData({
                        ...shortUrlData,
                        inValidUrl: false,
                        inputValue: elm.target.value,
                        shortURL: "",
                      });
                    } else {
                      setShortUrlData({
                        ...shortUrlData,
                        inValidUrl: true,
                        inputValue: elm.target.value,
                        shortURL: "",
                      });
                    }
                  }}
                />
                <Typography sx={{ fontSize: "small" }}>
                  {shortUrlData.inValidUrl
                    ? "Paste Urls starting with http:// or https://"
                    : "Paste long URL link to shorten"}
                </Typography>
                {shortUrlData?.shortURL && (
                  <Grid item xs={12}>
                    <Stack
                      direction={"row"}
                      spacing={2}
                      sx={{
                        background: "whitesmoke",
                        borderRadius: "5px",
                        marginTop: "10px",
                        padding: "10px",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>{shortUrlData?.shortURL}</Typography>
                      <IconButton
                        aria-label="copy"
                        onClick={handleCopyClick}
                        sx={{
                          borderRadius: "50%",
                          backgroundColor: "white",
                          padding: "8px",
                        }}
                      >
                        <FileCopyOutlined />
                      </IconButton>
                    </Stack>
                  </Grid>
                )}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ margin: "3, 0, 2", textTransform: "none" }}
                  disabled={shortUrlData?.inValidUrl || shortUrlData?.shortURL}
                  startIcon={
                    <ReplyAllOutlined
                      sx={{
                        height: 20,
                        width: 20,
                      }}
                    />
                  }
                  onClick={getShortendUrl}
                >
                  Get Short Link
                </Button>
              </>
            )}

            <Button
              onClick={() => {
                history.push("/plans");
                // setTimeout(() => {
                //     window.scrollTo({
                //         top: 2849,
                //         behavior: 'smooth',
                //       });
                // }, 2000);
              }}
              variant="contained"
              color="info"
              startIcon={<Star />}
              sx={{ boxShadow: 0, borderRadius: 2 }}
              size="large"
            >
              {data.checkAllPlans}
            </Button>
          </Stack>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ProfileDialog;
