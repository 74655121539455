function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

async function displayRazorpay({
  id: planId,
  addOnId,
  globalContext,
  price,
  handleVerify,
}) {
  const userId = JSON.parse(localStorage.getItem("myData"))?._id;

  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

  if (!res) {
    alert("Razorpay SDK failed to load. Are you online?");
    return;
  }

  const result = await globalContext.hitAxios({
    path: process.env.REACT_APP_BACKEND_BASE_URL + "/razorpay/payment/order",
    post: true,
    admin: false,
    obj: {
      planId: planId ?? "",
      addOnId,
      userId,
      amount: price,
    },
  });

  if (!result) {
    alert("Server error. Are you online?");
    return;
  }

  const { amount, orderId: order_id, currency } = result.data.paymentDetail;

  const options = {
    key: process.env.RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
    amount: amount,
    currency: currency,
    name: "ULAI",
    description: "ULAI",
    image: "https://app.ulai.in/logo512v2.png",
    order_id: order_id,
    handler: async function (response) {
      handleVerify(response, planId, userId, addOnId);
    },
    // prefill: {
    //     name: "Mayank Mohak",
    //     email: "mayank.mohahk@ulai.in",
    //     contact: "9999999999",
    // },
    // notes: {
    //     address: "Mayank Mohak ULAI in",
    // },
    theme: {
      color: (theme) => theme.palette.primary.main,
    },
  };

  const paymentObject = new window.Razorpay(options);
  paymentObject.open();
}
export const displayRazorpaySubscription = async (
  planId,
  globalContext,
  callbackFun
) => {
  const userId = JSON.parse(localStorage.getItem("myData"))?._id;

  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

  if (!res) {
    alert("Razorpay SDK failed to load. Are you online?");
    return;
  }

  const result = await globalContext.hitAxios({
    path:
      process.env.REACT_APP_BACKEND_BASE_URL +
      "/razorpay/payment/createSubscriptionLink",
    post: true,
    admin: false,
    obj: {
      planId: planId,
      userId: userId,
      razorpayplanId: planId.razorpayplanId,
    },
  });

  if (!result) {
    alert("Server error. Are you online?");
    return;
  }
  // if (!result.data.subscriptionId) {
  //   alert(result.data.msg);
  //   return;
  // }

  const { subscriptionId } = result.data;

  const options = {
    key: process.env.RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
    name: "ULAI",
    description: "ULAI",
    image: "https://app.ulai.in/logo512v2.png",
    subscription_id: subscriptionId,
    handler: async function (response) {
      window.location.href = "https://app.ulai.in/user?page=pay-history";
    },
    // callback_url: "https://app.ulai.in/user?page=pay-history",
    // redirect: true,
    // prefill: {
    //     name: "Mayank Mohak",
    //     email: "mayank.mohahk@ulai.in",
    //     contact: "9999999999",
    // },
    theme: {
      color: (theme) => theme.palette.primary.main,
    },
  };

  const paymentObject = new window.Razorpay(options);
  paymentObject.open();
};
export default displayRazorpay;
