import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { GlobalContext } from "../../context/GlobalContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  Box,
  Button,
  CardActionArea,
  CardMedia,
  Checkbox,
  Container,
  Dialog,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { TranslateContext } from "../../context/TranslateContext";
import { AccountBalanceOutlined, CreditCard } from "@mui/icons-material";
import CloseBtn from "../../context/CloseBtn";
// Depricated
// import { loadStripe } from "@stripe/stripe-js";
import Header from "../components/Header";
import Footer from "../components/Footer";
import displayRazorpay, { displayRazorpaySubscription } from "./razorPay";
import TextFieldNew from "../../components/TextFieldNew";

const CheckOut = () => {
  const { data } = React.useContext(TranslateContext);
  const location = useLocation();
  const [planOrignalPrice, setPlanOrignalPrice] = useState(0);
  const [couponObject, setCouponObject] = useState({
    code: "",
    error: false,
    msg: "",
  });
  const queryParams = new URLSearchParams(location.search);
  const productId = queryParams.get("product");
  const GC = React.useContext(GlobalContext);
  const history = useHistory();
  const [state, setState] = React.useState({
    gateways: {},
    pp: false,
    tc: false,
    offlineDialog: false,
  });

  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const updateScreenWidth = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", updateScreenWidth);
    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);

  const [planDetails, setPlanDetails] = React.useState({});

  async function getPlan() {
    const res = await GC.hitAxios({
      path: "/api/user/get_plan_details",
      post: true,
      admin: false,
      obj: { id: productId },
    });
    if (res.data.success) {
      setPlanOrignalPrice(res.data.data.price);
      setPlanDetails(res.data.data);
    } else {
      history.push("/user");
    }
  }

  async function getPaymentGate() {
    const res = await GC.hitAxios({
      path: "/api/user/get_payment_details",
      post: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.data.success) {
      setState({ ...state, gateways: res.data.data });
    }
  }

  // Depricated
  // async function payWithStripe() {
  //   const stripe = await loadStripe(state?.gateways?.pay_stripe_id);

  //   const res = await GC.hitAxios({
  //     path: "/api/user/create_stripe_session",
  //     post: true,
  //     admin: false,
  //     obj: {
  //       planId: planDetails?.id,
  //     },
  //   });
  //   if (res.data.success) {
  //     const result = stripe.redirectToCheckout({
  //       sessionId: res?.data?.session?.id,
  //     });
  //   }
  // }
  const generateSubscriptionLink = async (planId, userId) => {
    displayRazorpaySubscription(planId, GC, () => {
      history.push("/user?page=pay-history");
    });
  };
  const handleVerify = async (response, planId, userId) => {
    const data = {
      razorpay_payment_id: response.razorpay_payment_id,
      razorpay_order_id: response.razorpay_order_id,
      razorpay_signature: response.razorpay_signature,
      planId: planId ?? "",
      userId,
    };
    const resultPay = await GC.hitAxios({
      path: process.env.REACT_APP_BACKEND_BASE_URL + "/razorpay/payment/verify",
      post: true,
      admin: false,
      obj: {
        ...data,
      },
    });

    if (
      resultPay.status === 200 &&
      resultPay.data.success &&
      resultPay.data.data.status === "paid"
    ) {
      const mydata = JSON.parse(localStorage.getItem("myData"));
      const resultUser = await GC.hitAxios({
        path: "/api/user/get_user_data",
        post: true,
        admin: false,
        obj: {
          userType: mydata.userType,
          email: mydata.email,
        },
      });
      if (resultUser.data.success) {
        localStorage.setItem(
          "myData",
          JSON.stringify({
            ...resultUser.data.data.user,
            userType: mydata.userType,
            allagent: resultUser.data.data?.allagent
              ? resultUser.data.data?.allagent
              : [],
          })
        );
        history.push("/user");
      }
      // getAddonPlanHistory();
    }
  };
  async function enrollFree(planId) {
    const myData = JSON.parse(localStorage.getItem("myData"));
    const res = await GC.hitAxios({
      path: "/api/user/start_free_trial",
      post: true,
      admin: false,
      obj: { planId, code: couponObject.code },
    });
    if (res.data.success) {
      localStorage.setItem(
        "myData",
        JSON.stringify({ ...myData, ...res.data.data })
      );
      setTimeout(() => {
        history.push("/user");
      }, 3000);
    }
  }
  const applyCode = async () => {
    const res = await GC.hitAxios({
      path: "/api/user/applyPromoCode",
      post: true,
      admin: false,
      obj: {
        planId: planDetails._id,
        code: couponObject.code,
        price: planDetails.price,
      },
    });
    if (res.data.success) {
      setPlanOrignalPrice(planDetails.price);
      setPlanDetails({ ...planDetails, price: res.data.discountPrice });
      setCouponObject({
        ...couponObject,
        error: false,
        msg: `${couponObject.code} Applied !`,
      });
      // setTimeout(() => {
      //   history.push("/user");
      // }, 3000);
    } else {
      setPlanDetails({ ...planDetails, price: planOrignalPrice });
      setCouponObject({
        ...couponObject,
        error: true,
        msg: res.data.errorText,
      });
    }
  };
  React.useEffect(() => {
    if (productId) {
      getPlan();
      getPaymentGate();
    }
  }, [productId]);

  return (
    <div>
      <Header screenWidth={screenWidth} data={data} />
      <Container maxWidth="lg">
        <Grid
          alignItems={"center"}
          justifyContent={"center"}
          container
          minHeight={"90vh"}
        >
          <Grid xs={12} sm={12} lg={12} item>
            <Stack spacing={4} direction={"column"} alignItems={"center"}>
              <CardMedia
                src="/assets/payment_page.svg"
                component={"img"}
                sx={{ height: "100%", width: 200 }}
              />

              <Stack direction={"column"}>
                <Typography variant="h6" fontWeight={600} align="center">
                  {data.cehckOutFor}{" "}
                  <a
                    style={{
                      color: data.color_theme,
                    }}
                  >
                    {planDetails?.title}
                  </a>{" "}
                  {data.plan}
                </Typography>
                <Typography
                  align="center"
                  color={"gray"}
                  fontWeight={600}
                  variant="body2"
                >
                  {data.selPayment}
                </Typography>
              </Stack>

              <Stack alignItems={"center"} direction={"row"} spacing={2}>
                <Stack alignItems={"center"} direction={"row"} spacing={1}>
                  <Checkbox
                    onChange={(e) =>
                      setState({ ...state, pp: e.target.checked })
                    }
                    checked={state.pp}
                    size="small"
                  />
                  <Typography
                    onClick={() => {
                      window.location = "https://ulai.in/privacy-policy.html";
                    }}
                    fontWeight={600}
                    sx={{
                      cursor: "pointer",
                      color: (t) => t.palette.primary.main,
                    }}
                    variant="body2"
                  >
                    {data.privacyPlicy}
                  </Typography>
                </Stack>
                <Stack alignItems={"center"} direction={"row"} spacing={1}>
                  <Checkbox
                    onChange={(e) =>
                      setState({ ...state, tc: e.target.checked })
                    }
                    checked={state.tc}
                    size="small"
                  />
                  <Typography
                    onClick={() => {
                      window.location =
                        "https://ulai.in/terms-and-condition.html";
                    }}
                    fontWeight={600}
                    sx={{
                      cursor: "pointer",
                      color: (t) => t.palette.primary.main,
                    }}
                    variant="body2"
                  >
                    {data.termsCondition}
                  </Typography>
                </Stack>
              </Stack>
              {state.tc && state.pp && (
                <>
                  {" "}
                  <Box>
                    <Box display={"flex"} alignItems={"center"} gap={"10px"}>
                      <TextFieldNew
                        label={"Promo code"}
                        value={couponObject?.code}
                        error={couponObject?.error}
                        onChange={(e) => {
                          setCouponObject({
                            ...couponObject,
                            error: false,
                            msg: "",
                            code: e.target.value,
                          });
                        }}
                        // helperText={`Appy promo code `}
                        // bgColor={"#f5f7fb"}
                        fullWidth
                      />
                      <Button variant="contained" onClick={applyCode}>
                        Apply
                      </Button>
                    </Box>
                    <Typography
                      variant="body1"
                      fontSize={"14px"}
                      marginTop={"5px"}
                      marginLeft={"2px"}
                      color={couponObject.error ? "red" : "green"}
                    >
                      {couponObject.msg}
                    </Typography>
                  </Box>
                </>
              )}
              {planDetails?.price > 0 ? (
                <>
                  <Stack spacing={2} direction={"column"} alignItems={"center"}>
                    {state?.gateways?.offline_active > 0 &&
                      state.tc &&
                      state.pp && (
                        <CardActionArea
                          onClick={() =>
                            setState({ ...state, offlineDialog: true })
                          }
                          disabled={state.tc && state.pp ? false : true}
                          sx={{
                            padding: 2,
                            borderRadius: 3,
                            bgcolor: "#b2e0f7",
                          }}
                        >
                          <Stack direction={"row"} spacing={2}>
                            <AccountBalanceOutlined />
                            <Typography fontWeight={600}>
                              {data.offlinePayment}
                            </Typography>
                          </Stack>
                        </CardActionArea>
                      )}

                    {state?.gateways?.stripe_active > 0 &&
                      state.pp &&
                      state.tc && (
                        <>
                          <CardActionArea
                            onClick={() =>
                              // displayRazorpay({
                              //   ...planDetails,
                              //   globalContext: GC,
                              //   history,
                              //   handleVerify,
                              // })
                              generateSubscriptionLink(planDetails)
                            }
                            disabled={state.tc && state.pp ? false : true}
                            sx={{
                              padding: 2,
                              borderRadius: 3,
                              bgcolor: "#d8d2fa",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Stack direction={"row"} spacing={2}>
                              <CreditCard />
                              <Typography fontWeight={600}>
                                Pay Online
                              </Typography>
                            </Stack>
                          </CardActionArea>
                        </>
                      )}
                  </Stack>
                </>
              ) : (
                state.tc &&
                state.pp && (
                  <Stack spacing={2} direction={"column"} alignItems={"center"}>
                    {" "}
                    <CardActionArea
                      onClick={() => enrollFree(planDetails?._id)}
                      disabled={state.tc && state.pp ? false : true}
                      sx={{
                        padding: 2,
                        borderRadius: 3,
                        bgcolor: "action.hover",
                      }}
                    >
                      <Stack
                        alignItems={"center"}
                        direction={"row"}
                        spacing={2}
                      >
                        <Typography variant="h4">🤩</Typography>
                        <Typography fontWeight={600}>
                          {data.startFreeTrial}
                        </Typography>
                      </Stack>
                    </CardActionArea>
                  </Stack>
                )
              )}
            </Stack>
          </Grid>
        </Grid>

        <Dialog
          fullWidth
          PaperProps={{
            style: { borderRadius: 10 },
          }}
          onClose={() => setState({ ...state, offlineDialog: false })}
          open={state.offlineDialog}
        >
          <Box p={2}>
            <Stack direction={"column"} spacing={2}>
              <Stack
                alignItems={"center"}
                spacing={2}
                justifyContent={"space-between"}
                direction={"row"}
              >
                <Typography variant="h6">
                  {state?.gateways?.pay_offline_id}
                </Typography>
                <CloseBtn
                  onClick={() => setState({ ...state, offlineDialog: false })}
                />
              </Stack>

              <Divider />

              <Typography
                sx={{ display: "inline-block" }}
                dangerouslySetInnerHTML={{
                  __html: state?.gateways?.pay_offline_key,
                }}
                fontWeight={400}
              />
            </Stack>
          </Box>
        </Dialog>
      </Container>

      <Footer screenWidth={screenWidth} data={data} />
    </div>
  );
};

export default CheckOut;
