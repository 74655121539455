import React from "react";
import { GlobalContext } from "../../../context/GlobalContext";
import { Box, Button, Grid, Switch } from "@mui/material";
import "react-querybuilder/dist/query-builder.css";
import TempletList from "./templetList/TempletList";
import AddTempletDialog from "./addTemplet/AddTempletDialog";
import CampaignList from "./campaignsList/campaignsList";
import SegmentList from "./segementList/SegmentList";
import CreateCampaignsDialog from "./createCampaigns/AddCampaignsDialog";
import AddSegmentDialog from "./addSegment/AddSegmentDialog";

const Segment = ({ setMenu, menu, data, screenWidth }) => {
  const CON = React.useContext(GlobalContext);
  const [profileData, setProfileData] = React.useState([]);
  const [templetList, setTempletList] = React.useState([]);
  const [segmentList, setSegmentList] = React.useState([]);
  const [campaignsList, setCampaignsList] = React.useState([]);
  const [state, setState] = React.useState({
    sideBar: false,
    addDialog: false,
    addCampaignsDialog: false,
    segmentDialog: false,
    allSegments: [],
    allTemplet: [],
    templetData: {
      url: "",
      templetFor: "Order Placed",
      mediaType: "IMAGE",
      headerText: "",
      bodyMsg: "",
      footerMsg: "",
      btnType: "NONE",
      quickReplyTitle: "",
      actionUrl: "",
      btnTitle: "",
      phoneNum: "",
      category: "UTILITY",
    },
    campaignDate: {
      name: "",
      startTime: "",
      endTime: "",
      campaignType: "",
      segment: "",
      templetName: "",
      viewOnly: false,
    },
    segmentData: {
      isActive: true,
      from: "",
      to: "",
      name: "",
      viewOnly: false,
      actualQuery: { combinator: "and", rules: [] },
    },
  });
  const userData = JSON.parse(localStorage.getItem("myData"));
  async function getSegmentList() {
    const res = await CON.hitAxios({
      path: "/api/segments/getAll",
      post: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.data.success) {
      setState({ ...state, allSegments: res.data.data });
      setSegmentList(res.data.data);
    }
  }
  async function getCampaignsList() {
    const res = await CON.hitAxios({
      path: "/api/campaigns/getAll",
      post: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.data.success) {
      setCampaignsList(res.data.data);
    }
  }
  async function getTemplets() {
    const res = await CON.hitAxios({
      path: "/api/user/get_my_meta_templets",
      post: true,
      admin: false,
      obj: { type: "BROADCAST" },
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.data.success) {
      setTempletList(res.data.data);
    }
  }
  React.useEffect(() => {
    getTemplets();
    getCampaignsList();
    getSegmentList();
  }, []);

  return (
    <Box>
      <AddTempletDialog
        screenWidth={screenWidth}
        setMenu={setMenu}
        menu={menu}
        state={state}
        setState={setState}
        data={data}
        getTemplets={getTemplets}
      />
      <AddSegmentDialog
        screenWidth={screenWidth}
        setMenu={setMenu}
        menu={menu}
        state={state}
        setState={setState}
        data={data}
        getSegmentList={getSegmentList}
      />
      <CreateCampaignsDialog
        screenWidth={screenWidth}
        setMenu={setMenu}
        templetList={templetList}
        menu={menu}
        state={state}
        setState={setState}
        data={data}
        getCampaignsList={getCampaignsList}
      />
      <Box display={"flex"} justifyContent={"flex-end"} alignItems={"center"}>
        Sync Orders <Switch onChange={(e) => {}} checked={true} />
      </Box>
      <Box>
        <Grid container spacing={2}>
          <Grid xs={12} sm={12} md={12} lg={"2.5"} item>
            <Box
              position={"relative"}
              maxHeight={screenWidth > 899 ? "80vh" : "35vh"}
              mt={2}
              borderRadius={2}
              bgcolor={"#22a454"}
              p={0.5}
              color={"white"}
              boxShadow={"0px 5px 10px rgba(0, 0, 0, 0.3)"}
              overflow={"hidden"}
              overflowY="scroll"
            >
              <TempletList
                templetList={templetList}
                setTempletList={setTempletList}
                getTemplets={getTemplets}
                setState={setState}
                state={state}
                data={data}
                screenWidth={screenWidth}
                listTitle={"Templets"}
                colorTheme={"classes1"}
              />
            </Box>
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={6} item>
            <Box
              position={"relative"}
              maxHeight={screenWidth > 899 ? "80vh" : "35vh"}
              mt={2}
              borderRadius={2}
              bgcolor={"#ffd857"}
              p={0.5}
              boxShadow={"0px 5px 10px rgba(0, 0, 0, 0.3)"}
              overflow={"hidden"}
              overflowY="scroll"
            >
              <Box
                fontSize={"18px"}
                fontWeight={600}
                marginLeft={"2%"}
                marginTop={"2%"}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                Campaign
                <Button
                  variant="contained"
                  onClick={() => {
                    if (segmentList.length == 0) {
                      CON.setData({
                        ...CON.data,
                        snack: true,
                        snack_msg: "Please create segment first !",
                        snack_success: false,
                      });
                      setTimeout(() => {
                        CON.setData({
                          ...CON.data,
                          snack: false,
                          snack_msg: "",
                          snack_success: false,
                        });
                      }, 3000);
                      return;
                    } else if (templetList.length == 0) {
                      CON.setData({
                        ...CON.data,
                        snack: true,
                        snack_msg: "Please create templet first !",
                        snack_success: false,
                      });
                      setTimeout(() => {
                        CON.setData({
                          ...CON.data,
                          snack: false,
                          snack_msg: "",
                          snack_success: false,
                        });
                      }, 3000);
                      return;
                    } else if (templetList.length >= 1) {
                      const respArray = templetList.filter((el) => {
                        if (
                          el.actualMetaTemplet.status != "APPROVED" ||
                          el.status == 0
                        ) {
                          return el;
                        }
                      });
                      if (respArray.length == templetList.length) {
                        CON.setData({
                          ...CON.data,
                          snack: true,
                          snack_msg:
                            "Please create templet first! or You don't have any APPROVED templet",
                          snack_success: false,
                        });
                        setTimeout(() => {
                          CON.setData({
                            ...CON.data,
                            snack: false,
                            snack_msg: "",
                            snack_success: false,
                          });
                        }, 3000);
                        return;
                      }
                    } else if (segmentList.length >= 1) {
                      const respArray = segmentList.filter((el) => {
                        if (el.isRunning == true || el.stopWithError == 1) {
                          return el;
                        }
                      });
                      if (respArray.length == segmentList.length) {
                        CON.setData({
                          ...CON.data,
                          snack: true,
                          snack_msg:
                            "The target audience of a segment is still syncing !",
                          snack_success: false,
                        });
                        setTimeout(() => {
                          CON.setData({
                            ...CON.data,
                            snack: false,
                            snack_msg: "",
                            snack_success: false,
                          });
                        }, 3000);
                        return;
                      }
                    }
                    setState({
                      ...state,
                      addCampaignsDialog: true,
                      campaignDate: {
                        name: "",
                        startTime: "",
                        endTime: "",
                        campaignType: "",
                        segment: "",
                        templetName: "",
                        isViewOnly: false,
                      },
                    });
                  }}
                >
                  Create
                </Button>
              </Box>
              <CampaignList
                campaignsList={campaignsList}
                setCampaignsList={setCampaignsList}
                getCampaignsList={getCampaignsList}
                data={data}
                state={state}
                setState={setState}
                screenWidth={screenWidth}
              />
            </Box>
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={3.5} item>
            <Box
              position={"relative"}
              maxHeight={screenWidth > 899 ? "80vh" : "55vh"}
              mt={2}
              borderRadius={2}
              bgcolor={"#03dcbe"}
              p={0.5}
              boxShadow={"0px 5px 10px rgba(0, 0, 0, 0.3)"}
              overflow={"hidden"}
              overflowY="scroll"
            >
              <Box
                fontSize={"18px"}
                fontWeight={600}
                marginLeft={"2%"}
                marginTop={"2%"}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                Segment
                <Button
                  variant="contained"
                  onClick={() => {
                    setState({
                      ...state,
                      segmentDialog: true,
                      segmentData: {
                        isActive: true,
                        from: "",
                        to: "",
                        name: "",
                        actualQuery: { combinator: "and", rules: [] },
                        isViewOnly: false,
                      },
                    });
                  }}
                >
                  Create
                </Button>
              </Box>
              <SegmentList
                segmentList={segmentList}
                setSegmentList={setSegmentList}
                getSegmentList={getSegmentList}
                state={state}
                setState={setState}
                data={data}
                screenWidth={screenWidth}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Segment;
