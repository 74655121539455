import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  Dialog,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import TextFieldNew from "../../components/TextFieldNew";
import ButtonComp from "../../context/ButtonComp";
import { GlobalContext } from "../../context/GlobalContext";
import { LoadingButton } from "@mui/lab";
import UpdatePlan from "./UpdatePlan";
import UpdateWhatsappPricing from "./UpdateWhatsappPricing";

const EditUser = ({ state, setState, data, getUsers }) => {
  const { hitAxios } = React.useContext(GlobalContext);
  const CON = React.useContext(GlobalContext);

  async function updateUser() {
    const res = await hitAxios({
      path: "/api/admin/update_user",
      post: true,
      admin: true,
      obj: state?.dialogEditData,
    });
    if (res.data.success) {
      getUsers();
    }
  }

  return (
    <Dialog
      fullScreen
      open={state.dialogEdit}
      onClose={() => setState({ ...state, dialogEdit: false })}
    >
      <Box p={2} sx={{ bgcolor: (t) => t.palette.primary.main }}>
        <Stack alignItems={"center"} direction={"row"} spacing={2}>
          <IconButton onClick={() => setState({ ...state, dialogEdit: false })}>
            <Close sx={{ color: "white" }} />
          </IconButton>
          <Typography variant="h6" color={"white"}>
            {data?.editing} - {state?.dialogEditData?.firstName}
          </Typography>
        </Stack>
      </Box>

      <Box mt={4}>
        <Container maxWidth="lg">
          <Stack direction={"column"} spacing={2}>
            <TextFieldNew
              label={data.name}
              onChange={(e) =>
                setState({
                  ...state,
                  dialogEditData: {
                    ...state?.dialogEditData,
                    firstName: e.target.value,
                  },
                })
              }
              value={state?.dialogEditData?.firstName}
            />
            <TextFieldNew
              // type='number'
              label={data.mobNo}
              onChange={(e) =>
                setState({
                  ...state,
                  dialogEditData: {
                    ...state?.dialogEditData,
                    contact: e.target.value,
                  },
                })
              }
              value={state?.dialogEditData?.contact}
            />

            <TextFieldNew
              helperText={data.ignorePass}
              label={data.password}
              onChange={(e) =>
                setState({
                  ...state,
                  dialogEditData: {
                    ...state?.dialogEditData,
                    newPassword: e.target.value,
                  },
                })
              }
            />

            <LoadingButton
              loading={CON.data?.loading}
              onClick={updateUser}
              variant="contained"
              sx={{ boxShadow: 0, borderRadius: 2 }}
            >
              {data.update}
            </LoadingButton>
          </Stack>

          <UpdatePlan
            getUsers={getUsers}
            state={state}
            setState={setState}
            data={data}
            hitAxios={hitAxios}
            CON={CON}
          />
          <UpdateWhatsappPricing
            getUsers={getUsers}
            state={state}
            setState={setState}
            data={data}
            hitAxios={hitAxios}
            CON={CON}
          />
        </Container>
      </Box>
    </Dialog>
  );
};

export default EditUser;
