import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Icon } from "@mui/material";
import { SentimentDissatisfied } from "@mui/icons-material";

function NoDataFound({ title, subTitle }) {
  return (
    <React.Fragment>
      {title && (
        <Typography
          style={{
            fontSize: "14px",
            fontWeight: "900",
            fill: "rgb(55, 61, 63)",
            paddingLeft: "10px",
            fontFamily: "Helvetica, Arial, sans-serif",
          }}
        >
          {title}
        </Typography>
      )}
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{ flexDirection: "column", marginTop: "5vh" }}
      >
        <Grid item>
          <Icon color="disabled" fontSize="large">
            <SentimentDissatisfied />
          </Icon>
        </Grid>
        <Grid item>
          <Typography variant="h6">{subTitle ?? "Data Unavailable"}</Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default NoDataFound;
