import React from "react";

export const InboxContext = React.createContext(null);

export const InboxProvider = (props) => {
  const [data, setData] = React.useState({
    openedChat: {},
  });

  const [chatConvo, setChatConvo] = React.useState([]);
  const [chatList, setChatList] = React.useState([]);
  const [isScroll, setIsScroll] = React.useState(true);
  return (
    <InboxContext.Provider
      value={{
        data,
        setData,
        chatConvo,
        setChatConvo,
        chatList,
        setChatList,
        setIsScroll,
        isScroll,
      }}
    >
      {props.children}
    </InboxContext.Provider>
  );
};
