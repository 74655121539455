import React, { useState } from "react";
import Chart from "react-apexcharts";
import { Chip, Tooltip, Typography } from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";

const UrlAccessedComponent = ({ urlAccessedData }) => {
  function downloadCSVFileAccessedURL() {
    const convertedCSV =
      "URL,Accessed Time\n" +
      urlAccessedData
        .map(function (d) {
          return JSON.stringify(
            Object.values({
              a: d.redirectURL,
              b: d.visitHistory
                ?.map((itm) =>
                  moment(itm.timestamp).format("MM-DD-YYYY hh:mm a")
                )
                .toString(),
            })
          );
        })
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");
    const dl = "data:text/csv;charset=utf-8," + convertedCSV;

    const downloadLink = document.createElement("a");
    downloadLink.href = dl;
    downloadLink.download = "urlAccessedData.csv";

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          style={{
            fontSize: "14px",
            fontWeight: "900",
            fill: "rgb(55, 61, 63)",
            paddingLeft: "10px",
            fontFamily: "Helvetica, Arial, sans-serif",
          }}
        >
          Url Accessed Data
        </Typography>
        <Tooltip title="Download All Data">
          <Typography display={"flex"} sx={{cursor: "pointer"}} onClick={downloadCSVFileAccessedURL}>
            Export full data
            <FileDownloadOutlinedIcon />
          </Typography>
        </Tooltip>
      </div>
      <div style={{ height: 350, width: "100%" }}>
        <DataGrid
          getRowId={(row) => row.shortId}
          rows={[...urlAccessedData].reverse().slice(0, 5)}
          columns={[
            { headerName: "URL", field: "redirectURL", flex: 1 },
            {
              headerName: "Accessed Time",
              // field: visitHistory.map(itm=>{itm.timestamp}).toString(),
              flex: 1,
              renderCell: (data) => {
                const visitHistoryDates = data?.row?.visitHistory;
                if (visitHistoryDates.length == 0)
                  return (
                    <Chip
                      sx={{ marginRight: "5px" }}
                      variant={"filled"}
                      label="Not Used"
                    />
                  );
                return (
                  <div>
                    {visitHistoryDates?.map(
                      (itm, index) =>
                        index < 5 && (
                          <Chip
                            sx={{ marginRight: "5px" }}
                            variant={"filled"}
                            label={
                              index > 3
                                ? "+" + (visitHistoryDates.length - 4) + " more"
                                : moment(itm.timestamp).format("DD, MMM YYYY")
                            }
                          />
                        )
                    )}
                  </div>
                );
              },
            },
          ]}
          pageSize={5}
          rowsPerPageOptions={[5]}
          // components={{
          //   Toolbar: CustomToolbar,
          // }}
          // {...dataGridOptions}
          sx={{
            boxShadow: 0,
            borderRadius: 4,
            border: 0,
            fontSize: 13,
            padding: 2,
          }}
          hideFooter
        />
      </div>
    </>
  );
};

export default UrlAccessedComponent;
