import {
  FacebookOutlined,
  ScheduleSend,
  SmartToyOutlined,
} from "@mui/icons-material";
import { Box, CardActionArea, Stack, Typography, Chip } from "@mui/material";
import React from "react";

const SideBar = ({ data, screenWidth, setState, state, menu, setMenu }) => {
  return (
    <div>
      {screenWidth > 899 && (
        <Box width={250} minHeight={"92vh"} height={"100%"} bgcolor={"white"}>
          <Box p={1}>
            <Stack direction={"column"} spacing={2}>
              <CardActionArea
                onClick={() => setMenu({ ...menu, selected: "CREATE_TEMPLET" })}
                sx={{
                  p: 1.3,
                  borderRadius: 2,
                  bgcolor:
                    menu.selected === "CREATE_TEMPLET" ? "action.hover" : "",
                }}
              >
                <Stack direction={"row"} spacing={2} alignItems={"center"}>
                  <FacebookOutlined
                    sx={{ color: "gray", height: 20, width: 20 }}
                  />
                  <Typography
                    sx={{
                      color: (t) =>
                        menu.selected === "CREATE_TEMPLET"
                          ? t.palette.primary.main
                          : "",
                    }}
                    variant="body2"
                    fontWeight={600}
                  >
                    {data.manageTemplet}
                  </Typography>
                </Stack>
              </CardActionArea>

              <CardActionArea
                onClick={() => setMenu({ ...menu, selected: "SEND_BROADCAST" })}
                sx={{
                  p: 1.3,
                  borderRadius: 2,
                  bgcolor:
                    menu.selected === "SEND_BROADCAST" ? "action.hover" : "",
                }}
              >
                <Stack direction={"row"} spacing={2} alignItems={"center"}>
                  <ScheduleSend sx={{ color: "gray", height: 20, width: 20 }} />
                  <Typography
                    sx={{
                      color: (t) =>
                        menu.selected === "SEND_BROADCAST"
                          ? t.palette.primary.main
                          : "",
                    }}
                    variant="body2"
                    fontWeight={600}
                  >
                    {data.sendBroadcast}
                  </Typography>
                </Stack>
              </CardActionArea>
              <CardActionArea
                onClick={() => setMenu({ ...menu, selected: "CAMPAIGNS" })}
                sx={{
                  p: 1.3,
                  borderRadius: 2,
                  bgcolor: menu.selected === "CAMPAIGNS" ? "action.hover" : "",
                }}
              >
                <Stack direction={"row"} spacing={2} alignItems={"center"}>
                  <ScheduleSend sx={{ color: "gray", height: 20, width: 20 }} />
                  <Typography
                    sx={{
                      color: (t) =>
                        menu.selected === "CAMPAIGNS"
                          ? t.palette.primary.main
                          : "",
                    }}
                    variant="body2"
                    fontWeight={600}
                  >
                    Campaigns
                  </Typography>
                </Stack>
              </CardActionArea>
            </Stack>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default SideBar;
