// ToDo: Make Utility file for this.
export default function formatDate(dateString) {
  const date = new Date(dateString);

  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();

  const ordinalSuffix = (day) => {
    if (day > 3 && day < 21) return "th"; // handles 11th to 13th
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  return `${day}${ordinalSuffix(day)} ${month}, ${year}`;
}
function convertISODatetoStringDate(dateString) {
  // Parse the input date string
  if (!dateString) {
    return;
  }
  const parseDate = (dateStrin) => {
    const [day, month, yearTime] = dateStrin.split("/");
    const [year, time] = yearTime.split(", ");
    return new Date(`${year}-${month}-${day}T${time}`);
  };
  const date = new Date(parseDate(`${dateString}`));

  // Convert the date to the desired format
  const formattedDate = date.toString();

  return formattedDate;
}
function convertISODatetoGTMDate(dateString) {
  const date = new Date(dateString);

  const options = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZone: "Asia/Kolkata",
    timeZoneName: "short",
  };

  return date.toLocaleString("en-US", options).replace(",", "");
}
function convertToYYYYDDMMFormate(inputDate) {
  console.log("inputDate", inputDate);
  const date = new Date(inputDate);

  // Extract the year, month, and day
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const day = String(date.getUTCDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}
function dateFormate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
}
function getNumberOfDaysFromTimestamp(timestamp) {
  if (!timestamp || isNaN(timestamp)) {
    return "0"; // Invalid timestamp
  }

  const currentTimestamp = Date.now();
  if (timestamp <= currentTimestamp) {
    return "0"; // Timestamp is in the past or current time
  }

  const millisecondsInADay = 1000 * 60 * 60 * 24;
  const differenceInDays = Math.ceil(
    (timestamp - currentTimestamp) / millisecondsInADay
  );
  return `${differenceInDays}`;
}
function formatDateInIST(isoDateString) {
  const date = new Date(isoDateString);

  // Convert to IST time
  const istOffset = 5 * 60 + 30; // IST is UTC+5:30
  const istDate = new Date(date.getTime() + istOffset * 60 * 1000);

  const day = String(istDate.getDate()).padStart(2, "0");
  const monthIndex = istDate.getMonth();
  const year = istDate.getFullYear();

  // Array of month names
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const month = months[monthIndex];

  return `${day} ${month} ${year}`;
}
export {
  formatDateInIST,
  dateFormate,
  convertISODatetoStringDate,
  convertISODatetoGTMDate,
  convertToYYYYDDMMFormate,
  getNumberOfDaysFromTimestamp,
};
