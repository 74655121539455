import React, { useState } from "react";
import {
  BadgeOutlined,
  DnsOutlined,
  RateReviewOutlined,
  ChatOutlined,
  StickyNote2Outlined,
} from "@mui/icons-material";
import { Grid, Stack, Typography, Button, Box, Switch } from "@mui/material";
import { MuiColorInput } from "mui-color-input";
import TextFieldNew from "../../components/TextFieldNew";
import AddTempletDialog from "./addTemplet/AddTempletDialog";
import WhatsappNumberList from "./whatsappNumberList.js/whatsappNumberList";

const WhatsappSetting = ({
  whatsappBrandSetting,
  setWhatsappBrandSetting,
  whatsappChatButtonSetting,
  setWhatsappChatButtonSetting,
  handleSubmitUpdateWhatsapp,
  webWidgetCustomization,
  getWidgetCustomization,
  data,
}) => {
  const [addDialog, setDialog] = useState(false);
  const [isError, setError] = useState(false);
  const checkValidBrandSetting = (data) => {
    if (data?.brandName?.length == 0) {
      setError(true);
    } else if (data?.brandSubTitle?.length == 0) {
      setError(true);
    } else if (data?.welcomeText?.length == 0) {
      setError(true);
    } else if (data?.messageText?.length == 0) {
      setError(true);
    } else if (data?.ctaText?.length == 0) {
      setError(true);
    } else if (data?.backgroundColor?.length == 0) {
      setError(true);
    } else {
      setError(false);
    }
  };
  const checkValidButtonSetting = (data) => {
    if (data?.backgroundColor?.length == 0) {
      setError(true);
    } else if (data?.ctaText?.length == 0) {
      setError(true);
    } else if (data?.marginBottom <= 0 || isNaN(data?.marginBottom)) {
      setError(true);
    } else if (data?.marginRight <= 0 || isNaN(data?.marginRight)) {
      setError(true);
    } else if (
      data?.marginBottomMobile <= 0 ||
      isNaN(data?.marginBottomMobile)
    ) {
      setError(true);
    } else if (data?.marginRightMobile <= 0 || isNaN(data?.marginRightMobile)) {
      setError(true);
    } else {
      setError(false);
    }
  };
  return (
    <Grid container spacing={2} p={1}>
      <Typography>Widget - Brand Settings</Typography>
      <Grid container spacing={2} sx={{ mb: 1, mt: 2 }}>
        <Grid xs={12} sm={12} md={12} lg={4} item>
          <TextFieldNew
            fullWidth
            value={whatsappBrandSetting?.brandName}
            onChange={(e) => {
              setWhatsappBrandSetting({
                ...whatsappBrandSetting,
                brandName: e.target.value,
              });
              checkValidBrandSetting({
                ...whatsappBrandSetting,
                brandName: e.target.value,
              });
            }}
            startIcon={
              <BadgeOutlined
                sx={{
                  color: (t) => t.palette.primary.main,
                  height: 20,
                  width: 20,
                }}
              />
            }
            error={whatsappBrandSetting?.brandName?.length == 0}
            label="Brand Name"
          />
        </Grid>
        <Grid xs={12} sm={12} md={12} lg={4} item>
          <TextFieldNew
            fullWidth
            value={whatsappBrandSetting?.brandSubTitle}
            onChange={(e) => {
              setWhatsappBrandSetting({
                ...whatsappBrandSetting,
                brandSubTitle: e.target.value,
              });
              checkValidBrandSetting({
                ...whatsappBrandSetting,
                brandSubTitle: e.target.value,
              });
            }}
            startIcon={
              <DnsOutlined
                sx={{
                  color: (t) => t.palette.primary.main,
                  height: 20,
                  width: 20,
                }}
              />
            }
            error={whatsappBrandSetting?.brandSubTitle?.length == 0}
            label="Sub Title"
          />
        </Grid>
        <Grid xs={12} sm={12} md={12} lg={4} item>
          <TextFieldNew
            fullWidth
            value={whatsappBrandSetting?.welcomeText}
            onChange={(e) => {
              setWhatsappBrandSetting({
                ...whatsappBrandSetting,
                welcomeText: e.target.value,
              });
              checkValidBrandSetting({
                ...whatsappBrandSetting,
                welcomeText: e.target.value,
              });
            }}
            startIcon={
              <RateReviewOutlined
                sx={{
                  color: (t) => t.palette.primary.main,
                  height: 20,
                  width: 20,
                }}
              />
            }
            error={whatsappBrandSetting?.welcomeText?.length == 0}
            label="Welcome Text"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mb: 1, mt: 1 }}>
        <Grid xs={12} sm={12} md={12} lg={4} item>
          <TextFieldNew
            fullWidth
            value={whatsappBrandSetting?.messageText}
            onChange={(e) => {
              setWhatsappBrandSetting({
                ...whatsappBrandSetting,
                messageText: e.target.value,
              });
              checkValidBrandSetting({
                ...whatsappBrandSetting,
                messageText: e.target.value,
              });
            }}
            startIcon={
              <ChatOutlined
                sx={{
                  color: (t) => t.palette.primary.main,
                  height: 20,
                  width: 20,
                }}
              />
            }
            error={whatsappBrandSetting?.messageText?.length == 0}
            label="Message Text"
          />
        </Grid>
        <Grid xs={12} sm={12} md={12} lg={4} item>
          <TextFieldNew
            fullWidth
            value={whatsappBrandSetting?.ctaText}
            onChange={(e) => {
              setWhatsappBrandSetting({
                ...whatsappBrandSetting,
                ctaText: e.target.value,
              });
              checkValidBrandSetting({
                ...whatsappBrandSetting,
                ctaText: e.target.value,
              });
            }}
            startIcon={
              <StickyNote2Outlined
                sx={{
                  color: (t) => t.palette.primary.main,
                  height: 20,
                  width: 20,
                }}
              />
            }
            error={whatsappBrandSetting?.ctaText?.length == 0}
            label="Text Info"
          />{" "}
        </Grid>
        <Grid xs={12} sm={12} md={12} lg={4} item>
          <MuiColorInput
            size="small"
            fullWidth
            format="hex"
            InputProps={{
              style: {
                borderRadius: 10,
              },
            }}
            value={whatsappBrandSetting?.backgroundColor}
            label="Background Colour"
            onChange={(newValue) => {
              setWhatsappBrandSetting({
                ...whatsappBrandSetting,
                backgroundColor: newValue,
              });
              checkValidBrandSetting({
                ...whatsappBrandSetting,
                backgroundColor: newValue,
              });
            }}
            error={whatsappBrandSetting?.backgroundColor?.length == 0}
          />
        </Grid>
      </Grid>
      <Typography sx={{ mt: 2, mb: 1 }}>
        Widget - Chat Button Settings
      </Typography>
      <Grid container spacing={2}>
        <Grid xs={12} sm={12} md={6} lg={6} item>
          <TextFieldNew
            fullWidth
            value={whatsappChatButtonSetting?.ctaText}
            onChange={(e) => {
              setWhatsappChatButtonSetting({
                ...whatsappChatButtonSetting,
                ctaText: e.target.value,
              });
              checkValidButtonSetting({
                ...whatsappChatButtonSetting,
                ctaText: e.target.value,
              });
            }}
            startIcon={
              <StickyNote2Outlined
                sx={{
                  color: (t) => t.palette.primary.main,
                  height: 20,
                  width: 20,
                }}
              />
            }
            error={whatsappChatButtonSetting?.ctaText?.length == 0}
            label="Text"
          />
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={6} item>
          <MuiColorInput
            size="small"
            fullWidth
            format="hex"
            InputProps={{
              style: {
                borderRadius: 10,
              },
            }}
            value={whatsappChatButtonSetting?.backgroundColor}
            label="Background colour"
            onChange={(newValue) => {
              setWhatsappChatButtonSetting({
                ...whatsappChatButtonSetting,
                backgroundColor: newValue,
              });
              checkValidButtonSetting({
                ...whatsappChatButtonSetting,
                backgroundColor: newValue,
              });
            }}
            error={whatsappChatButtonSetting?.backgroundColor?.length == 0}
          />
        </Grid>
      </Grid>
      <Typography sx={{ mt: 2, mb: 1 }}> Widget - Postion Desktop</Typography>
      <Grid container spacing={2}>
        <Grid xs={12} sm={12} md={6} lg={6} item>
          <TextFieldNew
            fullWidth
            value={whatsappChatButtonSetting?.marginBottom}
            onChange={(e) => {
              setWhatsappChatButtonSetting({
                ...whatsappChatButtonSetting,
                marginBottom: e.target.value,
              });
              checkValidButtonSetting({
                ...whatsappChatButtonSetting,
                marginBottom: e.target.value,
              });
            }}
            error={
              whatsappChatButtonSetting?.marginBottom <= 0 ||
              isNaN(whatsappChatButtonSetting?.marginBottom)
            }
            label="Desktop Bottom Placeholder"
            helperText={"Example : 20"}
          />
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={6} item>
          <TextFieldNew
            fullWidth
            value={whatsappChatButtonSetting?.marginRight}
            onChange={(e) => {
              setWhatsappChatButtonSetting({
                ...whatsappChatButtonSetting,
                marginRight: e.target.value,
              });
              checkValidButtonSetting({
                ...whatsappChatButtonSetting,
                marginRight: e.target.value,
              });
            }}
            error={
              whatsappChatButtonSetting?.marginRight <= 0 ||
              isNaN(whatsappChatButtonSetting?.marginRight)
            }
            label="Desktop Right Placeholder"
            helperText={"Example : 20"}
          />
        </Grid>
      </Grid>
      <Typography sx={{ mt: 2, mb: 1 }}> Widget - Postion Mobile</Typography>
      <Grid container spacing={2}>
        <Grid xs={12} sm={12} md={6} lg={6} item>
          <TextFieldNew
            fullWidth
            value={whatsappChatButtonSetting?.marginBottomMobile}
            onChange={(e) => {
              setWhatsappChatButtonSetting({
                ...whatsappChatButtonSetting,
                marginBottomMobile: e.target.value,
              });
              checkValidButtonSetting({
                ...whatsappChatButtonSetting,
                marginBottomMobile: e.target.value,
              });
            }}
            error={
              whatsappChatButtonSetting?.marginBottomMobile <= 0 ||
              isNaN(whatsappChatButtonSetting?.marginBottomMobile)
            }
            label="Mobile Bottom Placeholder"
            helperText={"Example : 20"}
          />
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={6} item>
          <TextFieldNew
            fullWidth
            value={whatsappChatButtonSetting?.marginRightMobile}
            onChange={(e) => {
              setWhatsappChatButtonSetting({
                ...whatsappChatButtonSetting,
                marginRightMobile: e.target.value,
              });
              checkValidButtonSetting({
                ...whatsappChatButtonSetting,
                marginRightMobile: e.target.value,
              });
            }}
            error={
              whatsappChatButtonSetting?.marginRightMobile <= 0 ||
              isNaN(whatsappChatButtonSetting?.marginRightMobile)
            }
            label="Mobile Right Placeholder"
            helperText={"Example : 20"}
          />
        </Grid>
      </Grid>
      <Typography sx={{ mt: 2, mb: 1 }}>
        {" "}
        Welcome Message Configration
        <Switch
          {...{ inputProps: { "aria-label": "Switch demo" } }}
          checked={whatsappBrandSetting?.newCustomerMessageShow}
          name="askPhone"
          onChange={(e) => {
            setWhatsappBrandSetting({
              ...whatsappBrandSetting,
              newCustomerMessageShow: e.target.checked,
            });
          }}
        />
      </Typography>
      {whatsappBrandSetting?.newCustomerMessageShow && (
        <Grid xs={12} sm={12} md={12} lg={12} item>
          <Box>
            <Button
              variant="contained"
              onClick={() => {
                setDialog(true);
              }}
            >
              {" "}
              Welcome Message
            </Button>
          </Box>
        </Grid>
      )}

      <Grid xs={12} sm={12} md={12} lg={12} item>
        <Typography sx={{ mt: 2, mb: 1 }}>
          {" "}
          Whatsapp Phone Number List
        </Typography>
        <WhatsappNumberList data={data} />
      </Grid>
      <Grid xs={12} sm={12} md={12} lg={12} sx={{ mb: 1, mt: 2 }}>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          size="small"
          style={{ margin: "3, 0, 2", textTransform: "none" }}
          disabled={isError}
          onClick={handleSubmitUpdateWhatsapp}
        >
          Submit
        </Button>
      </Grid>

      <AddTempletDialog
        webWidgetCustomization={webWidgetCustomization}
        state={whatsappBrandSetting}
        addDialog={addDialog}
        setDialog={setDialog}
        setState={setWhatsappBrandSetting}
        handleSubmitUpdateWhatsapp={handleSubmitUpdateWhatsapp}
        whatsappChatButtonSetting={whatsappChatButtonSetting}
        data={data}
        getWidgetCustomization={getWidgetCustomization}
      />
    </Grid>
  );
};

export default WhatsappSetting;
