import React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { CancelOutlined, CheckCircleOutline, Star } from "@mui/icons-material";
import { FcMoneyTransfer } from "react-icons/fc";
import { TranslateContext } from "../../context/TranslateContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getNumberOfDaysFromTimestamp } from "../../utils/DateFormatter";

const PaymentPlanHistory = ({
  currentPlanData,
  webData,
  userData,
  loading,
}) => {
  const { data } = React.useContext(TranslateContext);
  const history = useHistory();
  return (
    <Box
      // top={4}
      bottom={0}
      right={4}
      left={0}
      borderRadius={2}
      border={2}
      borderColor={"#E3E3E3"}
      position={"relative"}
      p={1}
    >
      <Stack direction={"column"} spacing={2}>
        <Box sx={{ bgcolor: (t) => "white" }} borderRadius={2}>
          <Typography variant="h6">Current Plan</Typography>
          <Stack spacing={1} alignItems={"center"} direction={"column"}>
            {/* {currentPlanData?.is_trial ? (
              <Typography sx={{ display: "flex" }}>
                You are subscribed with a Trial Plan{" "}
                <span
                  style={{
                    fontSize: "20px",
                    marginLeft: "5px",
                    marginRight: "5px",
                    fontWeight: "500",
                    color: "red",
                  }}
                >
                  {" "}
                  {getNumberOfDaysFromTimestamp(userData?.planExpiresAt)}{" "}
                </span>
                Days Left of this plan
              </Typography>
            ) : ( */}
            {currentPlanData?.title ? (
              <Typography sx={{ display: "flex" }}>
                You are subscribed with &nbsp;
                <Typography sx={{ color: (t) => t.palette.primary.main }}>
                  {currentPlanData?.title} &nbsp;
                </Typography>
                <span
                  style={{
                    fontSize: "20px",
                    marginLeft: "5px",
                    marginRight: "5px",
                    fontWeight: "500",
                    color: "red",
                  }}
                >
                  {" "}
                  {getNumberOfDaysFromTimestamp(userData?.planExpiresAt)}{" "}
                </span>
                Days Left of this plan
              </Typography>
            ) : loading == false ? (
              <Typography sx={{ display: "flex" }}>
                Please Select Plan
                {/* </span> */}
                {/* Days Left of this plan */}
              </Typography>
            ) : (
              <></>
            )}
          </Stack>
        </Box>
        {/* {currentPlanData?.is_trial ? (
          <Stack alignItems={"center"}>
            <Box
              display={"flex"}
              alignItems={"center"}
              gap={"10px"}
              flexWrap={"wrap"}
            >
              <Button
                onClick={() => {
                  history.push("/plans");
                  // setTimeout(() => {
                  //   window.scrollTo({
                  //     top: 2849,
                  //     behavior: "smooth",
                  //   });
                  // }, 2000);
                }}
                variant="contained"
                color="info"
                startIcon={<Star />}
                sx={{ boxShadow: 0, borderRadius: 2 }}
              >
                {"Upgrade Plan"}
              </Button>
            </Box>
          </Stack>
        ) : ( */}
        {currentPlanData?.title ? (
          <Stack alignItems={"center"} direction={"column"} spacing={2}>
            <Stack direction={"row"} spacing={1}>
              <FcMoneyTransfer style={{ height: 20, width: 20 }} />
              <span
                style={{
                  textDecoration: "line-through",
                  color: "gray",
                  fontSize: 12,
                }}
              >
                {webData?.currency_symbol}
                {currentPlanData?.price_strike}
              </span>
              <Typography variant="h6">
                {webData?.currency_symbol}
                {currentPlanData?.price}
              </Typography>
            </Stack>
            <Stack>
              {" "}
              <Button
                onClick={() => {
                  history.push("/plans");
                }}
                variant="contained"
                color="info"
                startIcon={<Star />}
                sx={{ boxShadow: 0, borderRadius: 2 }}
              >
                {"Upgrade Plan"}
              </Button>{" "}
            </Stack>
          </Stack>
        ) : loading == false ? (
          <Stack alignItems={"center"} direction={"column"} spacing={2}>
            <Stack>
              {" "}
              <Button
                onClick={() => {
                  history.push("/plans");
                }}
                variant="contained"
                color="info"
                startIcon={<Star />}
                sx={{ boxShadow: 0, borderRadius: 2 }}
              >
                {"Select Plan"}
              </Button>{" "}
            </Stack>
          </Stack>
        ) : (
          <></>
        )}
      </Stack>
      <Box mt={1}>
        {/* <Stack alignItems={"center"} direction={"column"} spacing={1}>
          <Stack alignItems={"center"} direction={"row"} spacing={1}>
            <Typography variant="body2">{data.chatTags}</Typography>
            {currentPlanData?.allow_tag >= 1 || currentPlanData?.is_trial ? (
              <CheckCircleOutline
                sx={{ color: "green", height: 20, width: 20 }}
              />
            ) : (
              <CancelOutlined sx={{ color: "red", height: 20, width: 20 }} />
            )}
          </Stack>

          <Stack alignItems={"center"} direction={"row"} spacing={1}>
            <Typography variant="body2">{data.chatNote}</Typography>
            {currentPlanData?.allow_note >= 1 || currentPlanData?.is_trial ? (
              <CheckCircleOutline
                sx={{ color: "green", height: 20, width: 20 }}
              />
            ) : (
              <CancelOutlined sx={{ color: "red", height: 20, width: 20 }} />
            )}
          </Stack>

          <Stack alignItems={"center"} direction={"row"} spacing={1}>
            <Typography variant="body2">{data.chatBot}</Typography>
            {currentPlanData?.allow_chatbot >= 1 ||
            currentPlanData?.is_trial ? (
              <CheckCircleOutline
                sx={{ color: "green", height: 20, width: 20 }}
              />
            ) : (
              <CancelOutlined sx={{ color: "red", height: 20, width: 20 }} />
            )}
          </Stack>

          <Stack alignItems={"center"} direction={"row"} spacing={1}>
            <Typography variant="body2">{data.cloudAPI}</Typography>
            {currentPlanData?.allow_api >= 1 || currentPlanData?.is_trial ? (
              <CheckCircleOutline
                sx={{ color: "green", height: 20, width: 20 }}
              />
            ) : (
              <CancelOutlined sx={{ color: "red", height: 20, width: 20 }} />
            )}
          </Stack>
        </Stack> */}
      </Box>
    </Box>
  );
};

export default PaymentPlanHistory;
