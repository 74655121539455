import {
  AppBar,
  Box,
  Button,
  CardActionArea,
  CardMedia,
  Chip,
  Container,
  Drawer,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { GlobalContext } from "../../context/GlobalContext";
import { Link, useHistory } from "react-router-dom";
import {
  AutoStories,
  ContactMailOutlined,
  Gavel,
  Login,
  Security,
} from "@mui/icons-material";
import ButtonComp from "../../context/ButtonComp";
import { Menu } from "@mui/icons-material";
const Header = ({ data, screenWidth }) => {
  const [web, setWeb] = React.useState({});
  const {
    hitAxios,
    state: loginState,
    setState: setloginState,
  } = React.useContext(GlobalContext);
  const [state, setState] = React.useState({
    drawer: false,
    isLoggedIn: false,
  });

  const history = useHistory();

  async function getWeb() {
    const res = await hitAxios({
      path: "/api/admin/get_web_public",
      post: false,
      admin: false,
      token_user: "",
    });
    if (res.data.success) {
      setWeb(res.data.data);
    }
  }

  React.useEffect(() => {
    getWeb();
    if (window && localStorage.getItem(process.env.REACT_APP_TOKEN + "_user")) {
      setState({ ...state, isLoggedIn: true });
    }
  }, []);

  return web?.id ? (
    <div>
      <Box p={0}>
        <Container maxWidth="lg">
          {screenWidth < 869 ? (
            <Stack
              alignItems={"center"}
              justifyContent={"space-between"}
              direction={"row"}
            >
              <Stack direction={"row"} spacing={2}>
                <Chip
                  onClick={() => setState({ ...state, drawer: true })}
                  icon={<Menu sx={{ height: 18, width: 18 }} />}
                  label={data.menu}
                />
              </Stack>

              <Drawer
                onClose={() => setState({ ...state, drawer: false })}
                anchor="top"
                open={state.drawer}
              >
                <List>
                  <ListItem disablePadding>
                    <ListItemButton
                      component={Link}
                      //   to={`/view/privacy-policy`}
                      onClick={() => {
                        setState({ ...state, drawer: false });
                        window.location = "https://ulai.in/privacy-policy.html";
                      }}
                    >
                      <ListItemIcon>
                        <Security />
                      </ListItemIcon>
                      <ListItemText primary={data.privacyPlicy} />
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <ListItemButton
                      component={Link}
                      //   to={`https://ulai.in/privacy-policy.html`}
                      onClick={() => {
                        setState({ ...state, drawer: false });
                        window.location =
                          "https://ulai.in/terms-and-condition.html";
                      }}
                    >
                      <ListItemIcon>
                        <Gavel />
                      </ListItemIcon>
                      <ListItemText primary={data.termsCondition} />
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <ListItemButton
                      component={Link}
                      //   to={"/page/contact-form"}
                      onClick={() => {
                        setState({ ...state, drawer: false });
                        window.location = "https://ulai.in/book-a-demo.html";
                      }}
                    >
                      <ListItemIcon>
                        <ContactMailOutlined />
                      </ListItemIcon>
                      <ListItemText primary={data.contactUs} />
                    </ListItemButton>
                  </ListItem>
                </List>
              </Drawer>

              <Box maxWidth={80}>
                <CardActionArea
                  onClick={() => history.push("/")}
                  sx={{ borderRadius: 2 }}
                >
                  <CardMedia
                    component={"img"}
                    sx={{ height: "100%", width: 80, borderRadius: 2 }}
                    src={"https://app.ulai.in/logo512.png"}
                  />
                </CardActionArea>
              </Box>

              <Stack direction={"row"} spacing={2}>
                <ButtonComp
                  onClick={() =>
                    state.isLoggedIn
                      ? history.push("/user")
                      : !state.isLoggedIn && loginState.isLogin
                      ? setloginState({ ...loginState, isLogin: false })
                      : setloginState({ ...loginState, isLogin: true })
                  }
                  startIcon={<Login />}
                  title={
                    state.isLoggedIn
                      ? data.dashboard
                      : !state.isLoggedIn && loginState.isLogin
                      ? "Signup"
                      : "Login"
                  }
                />
              </Stack>
            </Stack>
          ) : (
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Stack alignItems={"center"} direction={"row"} spacing={10}>
                <Box maxWidth={80}>
                  <CardActionArea
                    onClick={() => history.push("/")}
                    sx={{ borderRadius: 2 }}
                  >
                    <CardMedia
                      component={"img"}
                      sx={{ height: "100%", width: 80, borderRadius: 2 }}
                      src={"https://app.ulai.in/logo512v2.png"}
                    />
                  </CardActionArea>
                </Box>

                <Stack alignItems={"center"} direction={"row"} spacing={3}>
                  <Typography
                    onClick={() =>
                      (window.location = "https://ulai.in/privacy-policy.html")
                    }
                    sx={{ cursor: "pointer" }}
                    fontWeight={600}
                  >
                    {data.privacyPlicy}
                  </Typography>

                  <Typography
                    onClick={() =>
                      (window.location =
                        "https://ulai.in/terms-and-condition.html")
                    }
                    sx={{ cursor: "pointer" }}
                    fontWeight={600}
                  >
                    {data.termsCondition}
                  </Typography>

                  <Typography
                    onClick={() =>
                      (window.location = "https://ulai.in/book-a-demo.html")
                    }
                    sx={{ cursor: "pointer" }}
                    fontWeight={600}
                  >
                    {data.contactUs}
                  </Typography>
                </Stack>
              </Stack>

              <Stack direction={"row"} spacing={2}>
                <ButtonComp
                  onClick={() =>
                    state.isLoggedIn
                      ? history.push("/user")
                      : !state.isLoggedIn && loginState.isLogin
                      ? setloginState({ ...loginState, isLogin: false })
                      : setloginState({ ...loginState, isLogin: true })
                  }
                  startIcon={<Login />}
                  title={
                    state.isLoggedIn
                      ? data.dashboard
                      : !state.isLoggedIn && loginState.isLogin
                      ? "Signup"
                      : "Login"
                  }
                />
              </Stack>
            </Stack>
          )}
        </Container>
      </Box>
    </div>
  ) : (
    <LinearProgress />
  );
};

export default Header;
