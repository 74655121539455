import { KeyOutlined } from "@mui/icons-material";
import { Box, Button, Tab, Tabs, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
// import TextFieldNew from "../../components/TextFieldNew";
import { AiFillDelete, AiFillPlusCircle } from "react-icons/ai";
import { oneLight } from "react-syntax-highlighter/dist/esm/styles/prism";
import TextFieldNew from "../../../components/TextFieldNew";
import HowTo from "./coversational/HowTo";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const sendText = `{
    "to": "18876656789",
    "type": "text",
    "text": {
        "preview_url": false,
        "body": "text-message-content"
    }
}`;

const sendMedia = `{
    "to": "18876656789",
    "type": "image",
    "image": {
        "link": "http(s)://image-url"
    }
}`;

const CustomWebhook = ({ hitAxios, data, setGetKeyDialog, userData }) => {
  const [value, setValue] = React.useState(0);
  const [allCustomWebhhookDetails, setAllWebhhookDetails] = React.useState([]);
  const [newWebook, setNewWebhook] = React.useState({
    statusId: null,
    templetName: "",
    webhookType: "CUSTOM",
    status: 1,
  });
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const codeStyle = {
    ...oneLight,
    margin: "0em",
    padding: "0em",
    fontSize: "10px", // Adjust the value as per your preference
  };
  const addNewWebhook = async () => {
    let obj = {
      statusId: newWebook.statusId,
      status: newWebook.templetName,
      webhookType: "CUSTOM",
      userId: userData.storeId,
      storeName: userData.storeName,
    };
    const res = await hitAxios({
      path: "/api/automation/addCustomTempletForAutomation",
      post: true,
      admin: false,
      obj: obj,
    });
    if (res.data.success) {
      setAllWebhhookDetails([...allCustomWebhhookDetails, newWebook]);
      setNewWebhook({
        statusId: null,
        templetName: "",
        webhookType: "CUSTOM",
      });
    }
  };
  const deleteWebhook = async (el, inDx) => {
    let obj = {
      statusId: el.statusId,
      status: el.templetName,
      webhookType: "CUSTOM",
      userId: userData.storeId,
      storeName: userData.storeName,
    };
    const res = await hitAxios({
      path: "/api/automation/deleteCustomTemplet",
      post: true,
      admin: false,
      obj: obj,
    });
    if (res.data.success) {
      let newArray = [...allCustomWebhhookDetails];
      newArray.splice(inDx, 1);
      setAllWebhhookDetails(newArray);
    }
  };
  useEffect(() => {
    if (userData.automationTemplet) {
      setAllWebhhookDetails(userData.automationTemplet);
    }
  }, [userData]);
  return (
    <div>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        gap="20px"
        bgcolor={"white"}
        padding={"15px"}
        borderRadius={"15px"}
      >
        <span style={{ fontWeight: "500" }}>Add New Status </span>
        <Box display={"flex"} gap={"10px"} width={"70%"}>
          <TextFieldNew
            fullWidth
            type="number"
            value={newWebook.statusId}
            placeholder="1"
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                setNewWebhook({ ...newWebook, statusId: e.target.value });
              }
            }}
            onChange={(e) =>
              setNewWebhook({ ...newWebook, statusId: e.target.value })
            }
            label="StatusId"
          />
          <TextFieldNew
            fullWidth
            value={newWebook.templetName}
            placeholder="Order In-Transit"
            onKeyUp={async (e) => {
              if (e.key === "Enter") {
                setNewWebhook({ ...newWebook, templetName: e.target.value });
                if (newWebook.statusId && newWebook.templetName) {
                  await addNewWebhook();
                }
              }
            }}
            onChange={(e) =>
              setNewWebhook({ ...newWebook, templetName: e.target.value })
            }
            label="Status"
          />
        </Box>
        <AiFillPlusCircle
          fontSize={"35px"}
          color="#22a454"
          cursor={"pointer"}
          onClick={async () => {
            if (newWebook.statusId && newWebook.templetName) {
              await addNewWebhook();
            }
          }}
        />
      </Box>
      {allCustomWebhhookDetails.length != 0 && (
        <Box
          alignItems={"center"}
          gap="5px"
          bgcolor={"white"}
          borderRadius={"15px"}
          padding={"10px"}
          marginTop={"10px"}
        >
          <Box fontWeight={"500"} marginBottom={"5px"}>
            {" "}
            All Custom Templet :-
          </Box>
          <Box display={"flex"} flexWrap={"wrap"} gap={"10px"}>
            {allCustomWebhhookDetails.map((el, key) => {
              if (el.webhookType != "CUSTOM" || !el.status) return;
              return (
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  borderRadius={"5px"}
                  bgcolor={"#0d920d"}
                  color={"white"}
                  padding={"5px"}
                  gap={"10px"}
                  key={key}
                >
                  {`{ statusId : ${el.statusId}, status : "${el.templetName}"}`}
                  <AiFillDelete
                    fontSize={"20px"}
                    cursor={"pointer"}
                    onClick={async () => {
                      await deleteWebhook(el, key);
                    }}
                  />
                </Box>
              );
            })}
          </Box>
        </Box>
      )}
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Tabs
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            label={
              <Typography fontWeight={600}>{data.howToRequest}</Typography>
            }
            {...a11yProps(0)}
          />
        </Tabs>
        <Button
          startIcon={<KeyOutlined />}
          onClick={() => setGetKeyDialog(true)}
        >
          Get Api Key
        </Button>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <HowTo data={data} hitAxios={hitAxios} />
      </CustomTabPanel>
    </div>
  );
};

export default CustomWebhook;
