import {
  AccountTree,
  Facebook,
  FeaturedPlayListOutlined,
  PermContactCalendar,
  Podcasts,
  QuestionAnswer,
  SmartToy,
  SmartToyOutlined,
} from "@mui/icons-material";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import {
  Box,
  CardActionArea,
  Chip,
  Container,
  Divider,
  Grid,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import NoDataFound from "../../components/NoDataFound";
import { GlobalContext } from "../../context/GlobalContext";
import { TranslateContext } from "../../context/TranslateContext";
import Cart from "./Cart";
import ChatBots from "./ChatBots";
import Chats from "./Chats";
import ChatsPerDay from "./ChatsPerDay";
import ChatsPerHour from "./ChatsPerHour";
import DashboardStoreForm from "./DashboardStoreForm";
import OrderSummary from "./OrderSummary";
import Orders from "./Orders";
import TopProducts from "./TopProducts";
import TopProductsDaily from "./TopProductsDaily";
import { getNumberOfDaysFromTimestamp } from "../../utils/DateFormatter";

const Dashboard = (props) => {
  const userData = JSON.parse(localStorage.getItem("myData"));

  // TODO: Make Utility for this.
  const history = useHistory();
  const logOutUser = () => {
    if (window.confirm(data.AUS)) {
      localStorage.clear();
      history.push("/user/login");
    }
  };

  const { data } = React.useContext(TranslateContext);
  const G = React.useContext(GlobalContext);
  const [dash, setDash] = React.useState({});

  async function getDash() {
    const res = await G.hitAxios({
      path: "/api/user/get_dashboard",
      post: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.data.success) {
      setDash(res.data);
    }
  }

  const [orderData, setOrderData] = React.useState([]);
  const [cartData, setCartData] = React.useState([]);
  const [chatsPerDay, setChatsPerDay] = React.useState([]);
  const [chatsEachHourList, setChatsEachHourList] = React.useState([]);
  const [topProductsData, setTopProductsData] = React.useState([]);
  const [topProductsDailyData, setTopProductsDailyData] = React.useState([]);

  async function getOrderData() {
    const res = await G.hitAxios({
      path:
        process.env.REACT_APP_BACKEND_BASE_URL +
        "/backend/analytics/" +
        userData?._id +
        "/no-orders-for-each-day/data?endDate=2024-06-30&startDate=2024-04-30",
      post: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.status === 200) {
      console.log("res?.data?.data", res?.data?.data);
      // setOrderData(res?.data?.data);  dashboardData["no-orders-for-each-day"]
    }
    const ress = await G.hitAxiosOnly({
      path:
        process.env.REACT_APP_BACKEND_BASE_URL +
        "/backend/analytics/" +
        userData?._id +
        "/getAllDefaultMetric",
      post: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (ress.status === 200) {
      G.setDashboardData(ress?.data);
    }
  }

  React.useEffect(() => {
    console.log("Called-Dashboard");
    if (validateNewUser() == false) {
      (async () => {
        getDash();
        getOrderData();
      })();
    }
  }, []);
  const validateNewUser = () => {
    if (userData?.bots?.length === 0 || userData.store === undefined) {
      return true;
    } else if (
      !userData.planExpiresAt ||
      getNumberOfDaysFromTimestamp(userData.planExpiresAt) < 1
    ) {
      history.push("/user?page=pay-history");
      return true;
    } else {
      return false;
    }
  };

  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  const [state, setState] = React.useState({
    menuSelected: "STORE",
    for_all: false,
    add: false,
  });
  React.useEffect(() => {
    const updateScreenWidth = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", updateScreenWidth);
    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);

  return validateNewUser() ? (
    <div>
      <Stack direction={"row"}>
        {screenWidth > 800 && (
          <Box
            p={1}
            width={200}
            bgcolor={"white"}
            minHeight={"100vh"}
            overflow={"auto"}
          >
            <Stack direction={"column"} spacing={2}>
              <CardActionArea
                onClick={() => setState({ ...state, menuSelected: "STORE" })}
                sx={{
                  p: 1.3,
                  borderRadius: 2,
                  bgcolor: state.menuSelected === "STORE" ? "action.hover" : "",
                }}
              >
                <Stack direction={"row"} spacing={2} alignItems={"center"}>
                  <StorefrontOutlinedIcon
                    sx={{
                      fill: (t) =>
                        state.menuSelected === "STORE"
                          ? t.palette.primary.main
                          : "#252C55",
                    }}
                  />
                  <Typography
                    sx={{
                      color: (t) =>
                        state.menuSelected === "STORE"
                          ? t.palette.primary.main
                          : "",
                    }}
                    variant="body2"
                    fontWeight={600}
                  >
                    Store
                  </Typography>
                </Stack>
              </CardActionArea>

              <CardActionArea
                onClick={logOutUser}
                sx={{
                  p: 1.3,
                  borderRadius: 2,
                  bgcolor:
                    state.menuSelected === "LOGOUT" ? "action.hover" : "",
                }}
              >
                <Stack direction={"row"} spacing={2} alignItems={"center"}>
                  <LoginOutlinedIcon
                    sx={{
                      fill: (t) =>
                        state.menuSelected === "LOGOUT" ? "black" : "gray",
                    }}
                  />
                  <Typography
                    sx={{
                      color: (t) =>
                        state.menuSelected === "LOGOUT" ? "black" : "gray",
                    }}
                    variant="body2"
                    fontWeight={600}
                  >
                    {data.logout}
                  </Typography>
                </Stack>
              </CardActionArea>
            </Stack>
          </Box>
        )}

        <Box width={"100%"}>
          {screenWidth < 799 && (
            <Box m={3}>
              <Stack direction={"row"} spacing={2} alignItems={"center"}>
                <Chip
                  icon={
                    <SmartToyOutlined
                      sx={{ color: "gray", height: 20, width: 20 }}
                    />
                  }
                  onClick={() => setState({ ...state, menuSelected: "STORE" })}
                  variant={
                    state.menuSelected === "STORE" ? "filled" : "outlined"
                  }
                  label={data.chatBot}
                />
                <Chip
                  icon={
                    <FeaturedPlayListOutlined
                      sx={{ color: "gray", height: 20, width: 20 }}
                    />
                  }
                  onClick={() => setState({ ...state, menuSelected: "LOGOUT" })}
                  variant={
                    state.menuSelected === "LOGOUT" ? "filled" : "outlined"
                  }
                  label={data.savedTemplets}
                />
              </Stack>
            </Box>
          )}

          {state.menuSelected === "STORE" && (
            <DashboardStoreForm page={props.page} setPage={props.setPage} />
          )}
        </Box>
      </Stack>
    </div>
  ) : (
    <div>
      <Container maxWidth="lg">
        <Box minHeight={"20vh"} mt={2} borderRadius={2} bgcolor={"white"} p={2}>
          <OrderSummary />
        </Box>
        <Grid container spacing={2}>
          <Grid xs={12} lg={6} md={12} sm={12} item>
            <Box
              minHeight={"40vh"}
              mt={2}
              borderRadius={2}
              bgcolor={"white"}
              p={2}
            >
              {G.data.loading ? (
                <Stack height={"40vh"}>
                  {Array(5)
                    .fill(null)
                    .map((_, key) => (
                      <Skeleton height={53} key={key} animation="wave" />
                    ))}
                </Stack>
              ) : G.dashboardData["no-orders-for-each-day"]?.length > 0 ? (
                <Orders
                  data={data}
                  orderData={G.dashboardData["no-orders-for-each-day"]}
                />
              ) : (
                <NoDataFound title="No of Orders. (per day)" />
              )}
            </Box>
          </Grid>

          <Grid xs={12} lg={6} md={12} sm={12} item>
            <Box
              minHeight={"40vh"}
              mt={2}
              borderRadius={2}
              bgcolor={"white"}
              p={2}
            >
              {G.data.loading ? (
                <Stack height={"40vh"}>
                  {Array(5)
                    .fill(null)
                    .map((_, key) => (
                      <Skeleton height={53} key={key} animation="wave" />
                    ))}
                </Stack>
              ) : G.dashboardData["no-of-carts-each-day"]?.length > 0 ? (
                <Cart
                  data={data}
                  cartData={G.dashboardData["no-of-carts-each-day"]}
                />
              ) : (
                <NoDataFound title="No of Cart. (per day)" />
              )}
            </Box>
          </Grid>
        </Grid>
        <Box minHeight={"40vh"} mt={2} borderRadius={2} bgcolor={"white"} p={2}>
          {G.data.loading ? (
            <Stack height={"40vh"}>
              {Array(5)
                .fill(null)
                .map((_, key) => (
                  <Skeleton height={53} key={key} animation="wave" />
                ))}
            </Stack>
          ) : G.dashboardData["no-chats-for-each-hour"].length > 0 ? (
            <ChatsPerHour
              data={data}
              chatsEachHourList={G.dashboardData["no-chats-for-each-hour"]}
            />
          ) : (
            <NoDataFound title="No of Chats. (per hour)" />
          )}
        </Box>
        <Box minHeight={"40vh"} mt={2} borderRadius={2} bgcolor={"white"} p={2}>
          {G.data.loading ? (
            <Stack height={"40vh"}>
              {Array(5)
                .fill(null)
                .map((_, key) => (
                  <Skeleton height={53} key={key} animation="wave" />
                ))}
            </Stack>
          ) : G.dashboardData["no-of-chat-session-each-day"]?.length > 0 ? (
            <ChatsPerDay
              data={data}
              chatsPerDay={G.dashboardData["no-of-chat-session-each-day"]}
            />
          ) : (
            <NoDataFound title="No of Chats. (per day)" />
          )}
        </Box>

        <Box minHeight={"40vh"} mt={2} borderRadius={2} bgcolor={"white"} p={2}>
          {G.data.loading ? (
            <Stack height={"40vh"}>
              {Array(5)
                .fill(null)
                .map((_, key) => (
                  <Skeleton height={53} key={key} animation="wave" />
                ))}
            </Stack>
          ) : (
            dash?.opened &&
            dash?.pending &&
            dash?.resolved && <Chats data={data} dash={dash} />
          )}
        </Box>

        <Box minHeight={"40vh"} mt={2} borderRadius={2} bgcolor={"white"} p={2}>
          {G.data.loading ? (
            <Stack height={"40vh"}>
              {Array(5)
                .fill(null)
                .map((_, key) => (
                  <Skeleton height={53} key={key} animation="wave" />
                ))}
            </Stack>
          ) : G.dashboardData["top-products-viewed-total"].length > 0 ? (
            <TopProducts
              data={data}
              topProductsData={G.dashboardData["top-products-viewed-total"]}
            />
          ) : (
            <NoDataFound title="Top viewed Products" />
          )}
        </Box>

        <Box minHeight={"40vh"} mt={2} borderRadius={2} bgcolor={"white"} p={2}>
          {G.data.loading ? (
            <Stack height={"40vh"}>
              {Array(5)
                .fill(null)
                .map((_, key) => (
                  <Skeleton height={53} key={key} animation="wave" />
                ))}
            </Stack>
          ) : G.dashboardData["top-products-viewed-per-day"].length > 0 ? (
            <TopProductsDaily
              data={data}
              topProductsDailyData={
                G.dashboardData["top-products-viewed-per-day"]
              }
            />
          ) : (
            <NoDataFound title="Top viewed Product of Day" />
          )}
        </Box>
        <Grid container spacing={2}>
          <Grid xs={12} lg={6} sm={6} item>
            <Box
              height="37vh"
              mt={2}
              mb={2}
              borderRadius={2}
              bgcolor="white"
              p={2}
            >
              {G.data.loading ? (
                <Stack height={"37vh"}>
                  {Array(5)
                    .fill(null)
                    .map((_, key) => (
                      <Skeleton height={53} key={key} animation="wave" />
                    ))}
                </Stack>
              ) : (
                dash?.opened &&
                dash?.pending &&
                dash?.resolved && <ChatBots data={data} dash={dash} />
              )}
            </Box>
          </Grid>
          <Grid xs={12} lg={6} sm={6} item>
            <Box
              overflow={"auto"}
              height={"37vh"}
              p={2}
              mt={2}
              mb={2}
              borderRadius={2}
              bgcolor={"white"}
            >
              <Stack direction={"column"} spacing={2}>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Stack alignItems={"center"} direction={"row"} spacing={2}>
                    <QuestionAnswer sx={{ height: 18, width: 18 }} />
                    <Typography variant="body2">{data.totalChats}</Typography>
                  </Stack>
                  <Typography variant="body2">
                    {dash?.totalChats || "🧐"}
                  </Typography>
                </Stack>

                <Divider />

                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Stack alignItems={"center"} direction={"row"} spacing={2}>
                    <SmartToy sx={{ height: 18, width: 18 }} />
                    <Typography variant="body2">
                      {data.totalChatbots}
                    </Typography>
                  </Stack>
                  <Typography variant="body2">
                    {dash?.totalChatbots || "🧐"}
                  </Typography>
                </Stack>

                <Divider />

                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Stack alignItems={"center"} direction={"row"} spacing={2}>
                    <PermContactCalendar sx={{ height: 18, width: 18 }} />
                    <Typography variant="body2">
                      {data.totalContacts}
                    </Typography>
                  </Stack>
                  <Typography variant="body2">
                    {dash?.totalContacts || "🧐"}
                  </Typography>
                </Stack>

                <Divider />

                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Stack alignItems={"center"} direction={"row"} spacing={2}>
                    <AccountTree sx={{ height: 18, width: 18 }} />
                    <Typography variant="body2">{data.totalFlows}</Typography>
                  </Stack>
                  <Typography variant="body2">
                    {dash?.totalFlows || "🧐"}
                  </Typography>
                </Stack>

                <Divider />

                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Stack alignItems={"center"} direction={"row"} spacing={2}>
                    <Podcasts sx={{ height: 18, width: 18 }} />
                    <Typography variant="body2">
                      {data.totalCampaign}
                    </Typography>
                  </Stack>
                  <Typography variant="body2">
                    {dash?.totalBroadcast || "🧐"}
                  </Typography>
                </Stack>

                <Divider />

                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Stack alignItems={"center"} direction={"row"} spacing={2}>
                    <Facebook sx={{ height: 18, width: 18 }} />
                    <Typography variant="body2">
                      {data.totalMetaTemplets}
                    </Typography>
                  </Stack>
                  <Typography variant="body2">
                    {dash?.totalTemplets || "🧐"}
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Dashboard;
