import React from "react";
import { Box, Button, CardActionArea, Stack, Typography } from "@mui/material";
import Slider from "react-slick";
import { GlobalContext } from "../context/GlobalContext";
import { TranslateContext } from "../context/TranslateContext";
import ButtonComp from "../context/ButtonComp";
import {
  AddShoppingCart,
  Cancel,
  CancelOutlined,
  CheckCircle,
  CheckCircleOutline,
  DeleteOutline,
} from "@mui/icons-material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const PlanComponent = ({ admin, bgColor, state, setState }) => {
  const { data } = React.useContext(TranslateContext);
  const [plans, setPlans] = React.useState([]);
  const [web, setWeb] = React.useState({});
  const { hitAxios } = React.useContext(GlobalContext);
  const history = useHistory();

  const settings = {
    dots: true,
    infinite: false,
    speed: 1000,
    slidesToShow: plans ? (plans?.length > 3 ? 3 : plans?.length) : 3,
    slidesToScroll: 2.5,
    autoplay: true,
    autoplaySpeed: 6000,
    prevArrow: null, // Remove the previous arrow
    nextArrow: null, // Remove the next arrow
    responsive: [
      {
        breakpoint: 800, // Set the breakpoint for mobile devices
        settings: {
          slidesToShow: 1, // Set the number of slides to show at a time to 1
          slidesToScroll: 1, // Set the number of slides to scroll at a time to 1
        },
      },
    ],
  };

  async function delPlan(id) {
    if (window.confirm(data.AUS)) {
      const res = await hitAxios({
        path: "/api/admin/del_plan",
        post: true,
        admin: true,
        obj: { id },
      });
      if (res.data.success) {
        // window.location.reload();
        getPlans();
      }
    }
  }

  async function getWebPublic() {
    const res = await hitAxios({
      path: "/api/admin/get_web_public",
      post: false,
      admin: false,
      token_user: "",
    });
    if (res.data.success) {
      setWeb(res.data.data);
    }
  }

  async function getPlans() {
    const res = await hitAxios({
      path: "/api/admin/get_plans",
      post: false,
      admin: false,
      token_user: "",
    });
    if (res.data.success) {
      setPlans(res.data.data);
    }
  }

  React.useEffect(() => {
    getPlans();
    getWebPublic();
  }, []);

  return (
    plans && (
      <Box
        display={"flex"}
        justifyContent={"center"}
        flexWrap={"wrap"}
        gap={"10px"}
      >
        {/* <Slider {...settings}> */}
        {plans.map((i, key) => {
          return (
            // <div >
            // <Box>
            <Box
              key={key}
              minWidth={"270px"}
              mr={2}
              minHeight={400}
              sx={{
                borderLeft: 3,
                borderColor: "#287F27",
                borderTop: 3,
                borderTopColor: "#287F27",
              }}
              borderRadius={3}
              bgcolor={bgColor ? bgColor : "action.hover"}
              p={3}
            >
              <Stack direction={"column"} spacing={2}>
                <Box position={"relative"}>
                  <Box sx={{ bgcolor: (t) => "white" }} p={2} borderRadius={2}>
                    <Stack
                      spacing={1}
                      alignItems={"center"}
                      direction={"column"}
                    >
                      <Typography
                        sx={{ color: (t) => t.palette.primary.main }}
                        variant="h6"
                      >
                        {i.title}
                      </Typography>
                      {/* <Typography
                        align="center"
                        color={"black"}
                        variant="body2"
                      >
                        {i.short_description}
                      </Typography> */}
                    </Stack>
                  </Box>

                  <Box
                    top={4}
                    bottom={0}
                    right={4}
                    left={0}
                    borderRadius={2}
                    border={2}
                    borderColor={"#E3E3E3"}
                    position={"absolute"}
                  />
                </Box>
              </Stack>
              <Typography
                sx={{ color: (t) => t.palette.primary.main }}
                fontSize={"14px"}
                display={"flex"}
                justifyContent={"center"}
              >
                Ai Feature
              </Typography>
              <Box mt={"0.5"} display={"flex"} flexDirection={"column"}>
                {i?.aiFeatures?.map((el) => {
                  return (
                    <Stack
                      alignItems={"center"}
                      direction={"column"}
                      spacing={1}
                    >
                      <Stack
                        alignItems={"center"}
                        direction={"row"}
                        spacing={1}
                      >
                        <Typography
                          variant="body2"
                          fontSize={"16px"}
                          fontWeight={500}
                          display={"flex"}
                          whiteSpace={"nowrap"}
                        >
                          {el.name}
                        </Typography>
                      </Stack>
                    </Stack>
                  );
                })}
              </Box>
              <Typography
                sx={{ color: (t) => t.palette.primary.main }}
                fontSize={"14px"}
                display={"flex"}
                justifyContent={"center"}
                mt={2}
              >
                Whatsapp Feature
              </Typography>
              <Box mt={"0.5"} display={"flex"} flexDirection={"column"}>
                {i?.whatsappFeatures?.map((el) => {
                  return (
                    <Stack
                      alignItems={"center"}
                      direction={"column"}
                      spacing={1}
                    >
                      <Stack
                        alignItems={"center"}
                        direction={"row"}
                        spacing={1}
                      >
                        <Typography
                          variant="body2"
                          fontSize={"16px"}
                          fontWeight={500}
                          display={"flex"}
                          whiteSpace={"nowrap"}
                        >
                          {el.name}
                        </Typography>
                      </Stack>
                    </Stack>
                  );
                })}
              </Box>
              <Typography
                sx={{ color: (t) => t.palette.primary.main }}
                fontSize={"14px"}
                display={"flex"}
                justifyContent={"center"}
                mt={2}
              >
                User Service Features
              </Typography>
              <Box mt={"0.5"} display={"flex"} flexDirection={"column"}>
                {i?.dashboardFeatures?.map((el) => {
                  return (
                    <Stack
                      alignItems={"center"}
                      direction={"column"}
                      spacing={1}
                    >
                      <Stack
                        alignItems={"center"}
                        direction={"row"}
                        spacing={1}
                      >
                        <Typography
                          variant="body2"
                          fontSize={"16px"}
                          fontWeight={500}
                          display={"flex"}
                          whiteSpace={"nowrap"}
                        >
                          {el.name}
                        </Typography>
                      </Stack>
                    </Stack>
                  );
                })}
              </Box>
              <Typography
                sx={{ color: (t) => t.palette.primary.main }}
                fontSize={"14px"}
                display={"flex"}
                justifyContent={"center"}
                mt={2}
              >
                Channels
              </Typography>
              <Box mt={"0.5"} display={"flex"} flexDirection={"column"}>
                {i?.channelsFeatures?.map((el) => {
                  return (
                    <Stack
                      alignItems={"center"}
                      direction={"column"}
                      spacing={1}
                    >
                      <Stack
                        alignItems={"center"}
                        direction={"row"}
                        spacing={1}
                      >
                        <Typography
                          variant="body2"
                          fontSize={"16px"}
                          fontWeight={500}
                          display={"flex"}
                          whiteSpace={"nowrap"}
                        >
                          {el.name}
                        </Typography>
                      </Stack>
                    </Stack>
                  );
                })}
              </Box>
              <Typography
                sx={{ color: (t) => t.palette.primary.main }}
                fontSize={"14px"}
                display={"flex"}
                justifyContent={"center"}
                mt={2}
              >
                Agent
              </Typography>
              <Box mt={"0.5"} display={"flex"} flexDirection={"column"}>
                {i?.agentFeatures?.map((el) => {
                  return (
                    <Stack
                      alignItems={"center"}
                      direction={"column"}
                      spacing={1}
                    >
                      <Stack
                        alignItems={"center"}
                        direction={"row"}
                        spacing={1}
                      >
                        <Typography
                          variant="body2"
                          fontSize={"16px"}
                          fontWeight={500}
                          display={"flex"}
                          whiteSpace={"nowrap"}
                        >
                          {el.name}
                        </Typography>
                      </Stack>
                    </Stack>
                  );
                })}
              </Box>
              <Typography
                sx={{ color: (t) => t.palette.primary.main }}
                fontSize={"14px"}
                display={"flex"}
                justifyContent={"center"}
                mt={2}
              >
                Support
              </Typography>
              <Box mt={"0.5"} display={"flex"} flexDirection={"column"}>
                {i?.supportFeatures?.map((el) => {
                  return (
                    <Stack
                      alignItems={"center"}
                      direction={"column"}
                      spacing={1}
                    >
                      <Stack
                        alignItems={"center"}
                        direction={"row"}
                        spacing={1}
                      >
                        <Typography
                          variant="body2"
                          fontSize={"16px"}
                          fontWeight={500}
                          display={"flex"}
                          whiteSpace={"nowrap"}
                        >
                          {el.name}
                        </Typography>
                      </Stack>
                    </Stack>
                  );
                })}
                <Stack alignItems={"center"} direction={"column"} spacing={2}>
                  <Stack direction={"row"} spacing={1}>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      flexDirection={"column"}
                      justifyContent={"center"}
                      fontSize={"22px"}
                    >
                      <Typography variant="h6" style={{ fontSize: "35px" }}>
                        <span
                          style={{
                            textDecoration: "line-through",
                            color: "gray",
                            fontSize: 20,
                          }}
                        >
                          {/* {web?.currency_symbol} */}
                          {i.price_strike}
                        </span>
                      </Typography>
                      <Box display={"flex"} alignItems={"center"} gap="5px">
                        {i.price > 0 ? web?.currency_symbol : ""}
                        <Typography
                          variant="h6"
                          style={{
                            fontSize: "35px",
                            marginBottom: "0%",
                          }}
                        >
                          {i.price > 0 ? i.price : "Free"}
                        </Typography>
                        /
                        <Typography
                          variant="h6"
                          style={{
                            fontSize: "22px",
                            marginBottom: "0%",
                          }}
                        >
                          {i.plan_duration_in_days == 30
                            ? "Month"
                            : i.plan_duration_in_days}{" "}
                          {i.plan_duration_in_days == 30 ? "" : data.days}
                        </Typography>
                      </Box>
                    </Box>
                  </Stack>
                  {/* <Typography variant='body2' >{data.for} {i.plan_duration_in_days} {data.days}</Typography> */}
                </Stack>
                {admin && (
                  <Box
                    mt={4}
                    display={"flex"}
                    justifyContent={"center"}
                    flexWrap={"wrap"}
                    alignItems={"center"}
                    gap="10px"
                  >
                    <ButtonComp
                      onClick={() => {
                        setState({
                          ...state,
                          planData: i,
                          dialog: true,
                          isEdit: true,
                        });
                      }}
                      bgcolor="gray"
                      startIcon={<DeleteOutline />}
                      title={"Edit"}
                    />
                    <ButtonComp
                      onClick={() => delPlan(i?._id)}
                      bgcolor="#F55D5D"
                      startIcon={<DeleteOutline />}
                      title={data.delPlan}
                    />
                  </Box>
                )}
                {!admin && (
                  <Box mt={3}>
                    <Stack alignItems={"center"}>
                      <ButtonComp
                        onClick={() => {
                          history.push(`/user/checkout?product=${i?._id}`);
                        }}
                        idSuffix="2"
                        startIcon={<AddShoppingCart />}
                        title={data.selectPlan}
                      />
                    </Stack>
                  </Box>
                )}
              </Box>
            </Box>
            // </Box>
            // </div>
          );
        })}
        {/* </Slider> */}
      </Box>
    )
  );
};

export default PlanComponent;
