import React from "react";
import axios from "axios";

const GetTrans = () => {
  const [trans, settrans] = React.useState("");
  async function getData() {
    const code = localStorage.getItem("language");
    if (!code) return;
    const plans = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/web/get-one-translation?code=${code}`
    );
    settrans(plans.data.data);
  }
  React.useEffect(() => {
    getData();
  }, []);
  return { trans };
};

export default GetTrans;
