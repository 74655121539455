import * as React from "react";
import { useDropzone } from "react-dropzone";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { AttachFileOutlined, Close, HouseOutlined } from "@mui/icons-material";
import {
  Box,
  Grid,
  Typography,
  Button,
  OutlinedInput,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Chip,
  Checkbox,
} from "@mui/material";
import { GlobalContext } from "../../context/GlobalContext";

const DashboardStoreForm = (props) => {
  const G = React.useContext(GlobalContext);
  const userData = JSON.parse(localStorage.getItem("myData"));
  const [isCrawling, setIsCrawling] = React.useState(true);
  const [state, setState] = React.useState({
    userId: "",
    xAuthToken: "",
    shopName: "bigcommerce",
  });
  const [uploadedFile, setUploadedFile] = React.useState([]);
  const onDrop = (file) => {
    setUploadedFile(file);
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { "text/plain": [".csv"] },
    capture: "filesystem",
    maxFiles: 1,
    multiple: false,
  });
  const history = useHistory();
  const handleRegiserStoreForm = async () => {
    if (state.shopName != "webCrawler" && isCrawling == false) {
      G.setData({
        ...G.data,
        snack: true,
        snack_msg: "Please allow us to crawl your website",
        snack_success: false,
      });
      return;
    }
    let path = state.shopName;
    const userId = JSON.parse(localStorage.getItem("myData"))?._id;
    if (state.shopName !== "webCrawler") path += "-store";

    let reqParm = {};
    if (state.shopName === "bigcommerce")
      reqParm = {
        storeHash: state.storehash,
        userId,
        xAuthToken: state.xAuthToken,
      };
    else if (state.shopName === "shopify")
      reqParm = {
        shopName: state.storehash,
        userId,
        xAuthToken: state.xAuthToken,
      };
    else if (state.shopName === "custom") {
      reqParm = {
        file: "mayanik",
        userId: JSON.parse(localStorage.getItem("myData"))?._id,
      };
    } else {
      reqParm = { userId };
    }

    const res = await G.hitAxios({
      path: process.env.REACT_APP_BACKEND_BASE_URL + "/backend/" + path,
      post: true,
      admin: false,
      obj: reqParm,
    });

    if (res.data) {
      const resp = await G.hitAxios({
        path:
          process.env.REACT_APP_BACKEND_BASE_URL + "/backend/users/" + userId,
        post: false,
        admin: false,
        token_user: "",
      });
      if (resp.data._id) {
        history.push(`/user?page=pay-history`);
        G.setData({ ...G.data, currentSubPage: "chatbot" });
        // save res.data at local storage
        localStorage.setItem("myData", JSON.stringify({ ...resp.data }));
      }
    }
  };
  const getFormForStore = (val) => {
    switch (state.shopName) {
      case "bigcommerce":
      case "shopify":
        return (
          <Grid container sx={{ flexDirection: "column" }} spacing={2}>
            <Grid item xs={12} sm={8} style={{ padding: "10px 8px 0px 24px" }}>
              <Typography>
                <b>
                  Enter Your Storehash <sup>*</sup>
                </b>
              </Typography>
              <OutlinedInput
                id={state.shopName + "-storehash"}
                fullWidth
                inputProps={{ sx: { height: "0.6em" } }}
                size="small"
                placeholder="storehash"
                value={state.storehash}
                onChange={(event) =>
                  setState({ ...state, storehash: event.target.value })
                }
              />
            </Grid>
            <Grid item xs={12} sm={8} style={{ padding: "10px 8px 0px 24px" }}>
              <Typography>
                <b>
                  Enter Your xAuthToken <sup>*</sup>
                </b>
              </Typography>
              <OutlinedInput
                id={state.shopName + "-xAuthToken"}
                fullWidth
                inputProps={{ sx: { height: "0.6em" } }}
                size="small"
                placeholder="xAuthToken"
                value={state.xAuthToken}
                onChange={(event) =>
                  setState({ ...state, xAuthToken: event.target.value })
                }
              />
            </Grid>
          </Grid>
        );
      case "custom":
        return (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={9} style={{ padding: "10px 8px 0px 24px" }}>
              <Typography color="#252C55">
                <b>Upload Store File</b>
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              style={{
                color: (t) => t.palette.primary.main,
                padding: "10px 8px 0px 24px",
              }}
              align="right"
            >
              <Button
                size="small"
                fullWidth
                variant="outlined"
                startIcon={<HouseOutlined />}
                sx={{ textTransform: "none" }}
              >
                Download Template
              </Button>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography>
                Download our store template, and all the Produts in the sheet,
                and upload instantly
              </Typography>
              <Typography sx={{ color: (t) => t.palette.primary.main }}>
                (Please Download the template before uploading the file.)
              </Typography>
              <Typography>
                We Support only a single CSV or Excel File (with 1 sheet) up to
                5 MB in size.
              </Typography>
              <Grid item xs={12} sm={12}>
                <section className="container">
                  <div
                    {...getRootProps({
                      style: {
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        padding: "20px",
                        borderWidth: "3px",
                        borderRadius: "12px",
                        borderColor: (t) => t.palette.primary.main,
                        borderStyle: "dashed",
                        backgroundColor: "#22a45421",
                        color: "#bdbdbd",
                        outline: "none",
                        transition: "border .24s ease-in-out",
                      },
                    })}
                  >
                    <input {...getInputProps()} />
                    <Typography>
                      Click to upload a File or drag and drop it here
                    </Typography>
                  </div>
                  {uploadedFile.length > 0 && (
                    <aside>
                      {uploadedFile.map((file) => (
                        <Typography
                          key={file.path}
                          sx={{ display: "flex", marginTop: "10px" }}
                        >
                          <Chip
                            label={file.name}
                            onDelete={() => {
                              setUploadedFile([]);
                            }}
                            icon={
                              <AttachFileOutlined
                                sx={{ height: "20px", width: "20px" }}
                              />
                            }
                            deleteIcon={<Close />}
                            sx={{
                              backgroundColor: "#22a45421",
                            }}
                          />
                        </Typography>
                      ))}
                    </aside>
                  )}
                </section>
              </Grid>
            </Grid>
          </Grid>
        );
      case "webCrawler":
        return (
          <React.Fragment>
            <h5 attrH5={{ className: "fw-bold my-1" }}>
              ULAI Scraper is a versatile tool designed to extract valuable
              information from websites effortlessly.{" "}
            </h5>
            <h5 attrH5={{ className: "fw-bold my-1" }}>
              Experience the convenience and efficiency of ULAI Web Scraper for
              your data extraction needs, and unlock a world of insights at your
              fingertips.{" "}
            </h5>
          </React.Fragment>
        );
      default:
        return <Typography>404 Not Found</Typography>;
    }
  };
  return (
    <Box
      mb={4}
      mt={4}
      sx={{
        marginTop: "0px",
        p: 2,
        backgroundColor: "white",
        boxShadow: 3,
        borderRadius: 2,
      }}
      m={3}
      p={2}
      bgcolor={"white"}
      borderRadius={2}
    >
      {getFormForStore()}

      <Grid container sx={{ flexDirection: "column" }} spacing={2}>
        <Grid item xs={12} sm={8} style={{ padding: "25px 8px 0px 24px" }}>
          <Typography id="demo-radio-buttons-group-label">
            <b>Registration Type</b>
          </Typography>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="big-commerce"
              name="radio-buttons-group"
              value={state.shopName}
              row
            >
              <FormControlLabel
                value="shopify"
                control={<Radio />}
                label="Shopify"
                onClick={(e) =>
                  setState({ ...state, shopName: e.target.value })
                }
              />
              <FormControlLabel
                value="bigcommerce"
                control={<Radio />}
                label="Big Commerce"
                onClick={(e) =>
                  setState({ ...state, shopName: e.target.value })
                }
              />
              {/* <FormControlLabel
                value="custom"
                control={<Radio />}
                label="Custom"
                onClick={(e) =>
                  setState({ ...state, shopName: e.target.value })
                }
              /> */}
              <FormControlLabel
                value="webCrawler"
                control={<Radio />}
                label="webCrawler"
                onClick={(e) =>
                  setState({ ...state, shopName: e.target.value })
                }
              />
            </RadioGroup>
          </FormControl>
          <FormControl>
            {state.shopName != "webCrawler" && (
              <Box>
                <label>
                  {" "}
                  <Checkbox
                    style={{ marginLeft: "-12px" }}
                    onChange={(e) => {
                      setIsCrawling(e.target.checked);
                      G.setData({
                        ...G.data,
                        snack: false,
                      });
                    }}
                    checked={isCrawling}
                    // size="small"
                  />{" "}
                  May we have permission to crawl your website,{" "}
                  <span style={{ color: "green" }}>
                    {" "}
                    {userData.websiteLink}
                  </span>
                  , to learn more about you? 😍
                </label>
              </Box>
            )}
          </FormControl>
        </Grid>{" "}
        <Grid item xs={12} sm={1.5} style={{ padding: "10px 8px 0px 24px" }}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            size="small"
            style={{ margin: "3, 0, 2", textTransform: "none" }}
            onClick={handleRegiserStoreForm}
          >
            Register
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashboardStoreForm;
