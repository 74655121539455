import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { GlobalContext } from "../../context/GlobalContext";
import { dateFormate } from "../../utils/DateFormatter";

const TopProducts = ({ data, topProductsData }) => {
  const G = React.useContext(GlobalContext);
  const userData = JSON.parse(localStorage.getItem("myData"));
  const [date, setDate] = useState({ startDate: "", endDate: "" });
  const [chatState, setChartState] = useState({
    option: {
      plotOptions: {
        bar: {
          borderRadius: 4,
          borderRadiusApplication: "end",
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: G.dashboardData["top-products-viewed-total"]?.map(
          (i) => i.productName
        ),
      },
      title: {
        text: "Top viewed products overall",
        align: "left",
      },
    },
    series: [
      {
        name: "Count",
        data: G.dashboardData["top-products-viewed-total"]?.map((i) => i.count),
      },
    ],
  });
  async function getData(startDate, endDate) {
    const res = await G.hitAxiosOnly({
      path:
        process.env.REACT_APP_BACKEND_BASE_URL +
        "/backend/analytics/" +
        userData?._id +
        `/top-products-viewed-total/data?startDate=${startDate}&endDate=${endDate}`,
      post: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.status === 200) {
      setChartState({
        option: {
          plotOptions: {
            bar: {
              borderRadius: 4,
              borderRadiusApplication: "end",
              horizontal: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            categories: res?.data?.data?.map(
              (i) => i.productName
            ),
          },
          title: {
            text: "Top viewed products overall",
            align: "left",
          },
        },
        series: [
          {
            name: "Count",
            data: res?.data?.data?.map(
              (i) => i.count
            ),
          },
        ],
      });
    }
  }
  useEffect(() => {
    setDate({
      startDate: dateFormate(
        new Date(new Date() - 1000 * 60 * 60 * 24 * parseInt(30))
      ),
      endDate: dateFormate(new Date()),
    });
    setChartState({
      option: {
        plotOptions: {
          bar: {
            borderRadius: 4,
            borderRadiusApplication: "end",
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: G.dashboardData["top-products-viewed-total"]?.map(
            (i) => i.productName
          ),
        },
        title: {
          text: "Top viewed products overall",
          align: "left",
        },
      },
      series: [
        {
          name: "Count",
          data: G.dashboardData["top-products-viewed-total"]?.map(
            (i) => i.count
          ),
        },
      ],
    });
  }, [G.dashboardData["top-products-viewed-total"]]);
  const options = {
    plotOptions: {
      bar: {
        borderRadius: 4,
        borderRadiusApplication: "end",
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: topProductsData?.map((i) => i.productName),
    },
    title: {
      text: "Top viewed Products",
      align: "left",
    },
  };

  const series = [
    {
      name: "Count",
      data: topProductsData?.map((i) => i.count),
    },
  ];

  return (
    <Chart
      id="basic-line-chart"
      height={"100%"}
      width={"100%"}
      options={chatState.option}
      series={chatState.series}
      type="bar"
    />
  );
};

export default TopProducts;
