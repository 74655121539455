import React from "react";
import Chart from "react-apexcharts";
import { MenuItem, Select, Typography } from "@mui/material";

const ChatsPerHour = ({ data, chatsEachHourList }) => {
  function convertTo12Hour(time) {
    // Split the time string into hours and minutes
    let [hours, minutes] = time.split(":");

    // Convert hours and minutes to integers
    hours = parseInt(hours, 10);
    minutes = parseInt(minutes, 10);

    // Determine the period (AM/PM)
    let period = "AM";
    if (hours >= 12) {
      period = "PM";
      if (hours > 12) {
        hours -= 12;
      }
    } else if (hours === 0) {
      hours = 12;
    }

    // Pad minutes with leading zero if needed
    minutes = minutes < 10 ? "0" + minutes : minutes;

    // Return the formatted time
    return `${hours}:${minutes} ${period}`;
  }
  const [selectedDate, setSelectedDate] = React.useState(
    chatsEachHourList[0]._id
  );
  const [chatsEachHour, setChatsEachHour] = React.useState(
    chatsEachHourList[0].chatCount
  );
  const options = {
    chart: {
      id: "basic-line-chart",
    },
    xaxis: {
      categories: chatsEachHour?.map((i) => convertTo12Hour(i.hour + ":00")),
    },
    title: {
      text: "No of Sessions. (per hour)",
      align: "left",
    },
    colors: ["#22a454"],
  };

  const series = [
    {
      name: "Number of chats",
      data: chatsEachHour?.map((i) => i.count),
    },
  ];

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Typography>Select Date : &nbsp;</Typography>
        <Select
          size="small"
          value={selectedDate}
          onChange={(e) => {
            setSelectedDate(e.target.value);
            setChatsEachHour(
              chatsEachHourList.filter((itm) => e.target.value === itm._id)[0]
                .chatCount
            );
          }}
          sx={{ borderRadius: "10px" }}
        >
          {chatsEachHourList.map((elm) => (
            <MenuItem value={elm._id}>{elm._id}</MenuItem>
          ))}
        </Select>
      </div>
      <Chart
        id="basic-line-chart"
        height={"100%"}
        width={"100%"}
        options={options}
        series={series}
        type="area"
      />
    </>
  );
};

export default ChatsPerHour;
