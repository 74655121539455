import { Box, Button, Divider, Grid, Switch, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import TextFieldNew from "../../components/TextFieldNew";
import { GlobalContext } from "../../context/GlobalContext";

const Configration = ({ data }) => {
  const CON = useContext(GlobalContext);
  const [configration, setConfigration] = useState({});
  const myData = JSON.parse(localStorage.getItem("myData"));
  const getConfigration = async () => {
    const res = await CON.hitAxios({
      path:
        process.env.REACT_APP_BACKEND_BASE_URL +
        `/backend/order/orderConfig/${myData._id}`,
      post: false,
      admin: false,
    });
    if (res.data.status) {
      // console.log("res", res.data);
      setConfigration(res.data.data);
    }
  };
  const handleSubmitUpdate = async () => {
    const res = await CON.hitAxios({
      path:
        process.env.REACT_APP_BACKEND_BASE_URL +
        `/backend/order/orderConfig/${myData._id}`,
      post: true,
      admin: false,
      obj: configration,
    });
    if (res.data.success) {
      getConfigration();
      // console.log("res", res.data);
    }
  };
  useEffect(() => {
    getConfigration();
  }, []);
  return (
    <Box mt={2} borderRadius={4} bgcolor={"white"} p={1}>
      {" "}
      <Grid container spacing={2} p={1}>
        <Grid xs={12} sm={12} md={12} lg={12} item>
          <Typography sx={{ mt: 2, mb: 1, color: "green" }}>
            Order Cancellation Settings -
          </Typography>
          <Box display={"flex"} width={"100"} flexWrap={"wrap"}>
            <Typography
              sx={{
                mb: 1,
                display: "flex",
                alignItems: "center",
                textWrap: "nowrap",
              }}
            >
              {" "}
              Cancellation allowed ?
              <Switch
                {...{ inputProps: { "aria-label": "Switch demo" } }}
                checked={configration?.cancelAllowed}
                name="askPhone"
                onChange={(e) => {
                  setConfigration({
                    ...configration,
                    cancelAllowed: e.target.checked,
                  });
                }}
              />
            </Typography>
            {configration?.cancelAllowed == true && (
              <>
                {" "}
                <Typography
                  sx={{
                    mb: 1,
                    display: "flex",
                    alignItems: "center",
                    textWrap: "nowrap",
                  }}
                >
                  {" "}
                  Bot can cancel the order ?
                  <Switch
                    {...{ inputProps: { "aria-label": "Switch demo" } }}
                    checked={configration?.autoOrderCancel}
                    name="askPhone"
                    onChange={(e) => {
                      setConfigration({
                        ...configration,
                        autoOrderCancel: e.target.checked,
                      });
                    }}
                  />
                </Typography>
                <TextFieldNew
                  width={"180px"}
                  type="number"
                  value={configration?.cancellationAllowedDays}
                  label={"User can cancel order with in days ?"}
                  onChange={(e) => {
                    setConfigration({
                      ...configration,
                      cancellationAllowedDays: e.target.value,
                    });
                  }}
                />
              </>
            )}
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={12} lg={12} item>
          <Divider />
          <Typography sx={{ mt: 1, mb: 1, color: "green" }}>
            Order Return and Exchange Settings -
          </Typography>
          <Box
            display={"flex"}
            width={"100"}
            flexWrap={"wrap"}
            sx={{
              mt: 2,
            }}
          >
            <Typography
              sx={{
                mb: 1,
                display: "flex",
                alignItems: "center",
                textWrap: "nowrap",
              }}
            >
              {" "}
              Return allowed ?
              <Switch
                {...{ inputProps: { "aria-label": "Switch demo" } }}
                checked={configration?.returnAllowed}
                // name="askPhone"
                onChange={(e) => {
                  setConfigration({
                    ...configration,
                    returnAllowed: e.target.checked,
                  });
                }}
              />
            </Typography>
            {configration?.returnAllowed == true && (
              <TextFieldNew
                width={"180px"}
                type="number"
                value={configration?.returnAllowedDays}
                label={"User can return order with in days ?"}
                onChange={(e) => {
                  setConfigration({
                    ...configration,
                    returnAllowedDays: e.target.value,
                  });
                }}
              />
            )}
          </Box>
          <Box
            display={"flex"}
            width={"100"}
            flexWrap={"wrap"}
            sx={{
              mt: 2,
            }}
          >
            <Typography
              sx={{
                mb: 1,
                display: "flex",
                alignItems: "center",
                textWrap: "nowrap",
              }}
            >
              {" "}
              Exchange allowed ?
              <Switch
                {...{ inputProps: { "aria-label": "Switch demo" } }}
                checked={configration?.exchangeAllowed}
                // name="askPhone"
                onChange={(e) => {
                  setConfigration({
                    ...configration,
                    exchangeAllowed: e.target.checked,
                  });
                }}
              />
            </Typography>
            {configration?.exchangeAllowed == true && (
              <>
                {" "}
                <Typography
                  sx={{
                    mb: 1,
                    display: "flex",
                    alignItems: "center",
                    textWrap: "nowrap",
                  }}
                >
                  {" "}
                  Bot can Exchange the order ?
                  <Switch
                    {...{ inputProps: { "aria-label": "Switch demo" } }}
                    checked={configration?.autoOrderExchange}
                    // name="askPhone"
                    onChange={(e) => {
                      setConfigration({
                        ...configration,
                        autoOrderExchange: e.target.checked,
                      });
                    }}
                  />
                </Typography>
                <TextFieldNew
                  width={"180px"}
                  type="number"
                  value={configration?.exchangeAllowedDays}
                  label={"User can exchange order with in days ?"}
                  onChange={(e) => {
                    setConfigration({
                      ...configration,
                      exchangeAllowedDays: e.target.value,
                    });
                  }}
                />
              </>
            )}
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={12} lg={12} item>
          <Divider />
          <Typography sx={{ mt: 1, mb: 1, color: "green" }}>
            Refund Settings -
          </Typography>
          <Box display={"flex"} width={"100"} flexWrap={"wrap"}>
            <Typography
              sx={{
                mb: 1,
                display: "flex",
                alignItems: "center",
                textWrap: "nowrap",
              }}
            >
              {" "}
              Refund allowed ?
              <Switch
                {...{ inputProps: { "aria-label": "Switch demo" } }}
                checked={configration?.refundAllowed}
                // name="askPhone"
                onChange={(e) => {
                  setConfigration({
                    ...configration,
                    refundAllowed: e.target.checked,
                  });
                }}
              />
            </Typography>
            {configration?.refundAllowed == true && (
              <>
                <Typography
                  sx={{
                    mb: 1,
                    display: "flex",
                    alignItems: "center",
                    textWrap: "nowrap",
                  }}
                >
                  {" "}
                  Bot can refund the order ?
                  <Switch
                    {...{ inputProps: { "aria-label": "Switch demo" } }}
                    checked={configration?.autoOrderRefund}
                    // name="askPhone"
                    onChange={(e) => {
                      setConfigration({
                        ...configration,
                        autoOrderRefund: e.target.checked,
                      });
                    }}
                  />
                </Typography>
                <TextFieldNew
                  width={"180px"}
                  type="number"
                  value={configration?.refundAllowedDays}
                  label={"User can refund order with in days ?"}
                  onChange={(e) => {
                    setConfigration({
                      ...configration,
                      refundAllowedDays: e.target.value,
                    });
                  }}
                />{" "}
              </>
            )}
          </Box>
        </Grid>
      </Grid>
      <Grid xs={12} sm={12} md={12} lg={12} sx={{ mb: 1, mt: 2 }}>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          size="small"
          style={{ margin: "3, 0, 2", textTransform: "none" }}
          // disabled={isError}
          onClick={handleSubmitUpdate}
        >
          Submit
        </Button>
      </Grid>
    </Box>
  );
};
export default Configration;
