import {
  AddOutlined,
  CheckCircleOutline,
  OpenInNewOutlined,
  PhoneOutlined,
} from "@mui/icons-material";
import { Button, Chip, IconButton, Stack } from "@mui/material";
import React from "react";
import TextFieldNew from "../../../../components/TextFieldNew";

const AddAction = ({
  data,
  setActionBtn,
  actionBtn,
  quickReply,
  states,
  setStates,
}) => {
  const [type, setType] = React.useState("CALL");

  function isValidUrl(url) {
    // Regular expression for URL validation
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

    // Test the URL against the regex pattern
    return urlRegex.test(url);
  }

  return (
    <>
      <Stack direction={"row"} spacing={1}>
        {actionBtn?.map((i, key) => {
          return (
            <Chip
              icon={
                i?.type === "URL" ? (
                  <OpenInNewOutlined sx={{ height: 18, width: 18 }} />
                ) : (
                  <PhoneOutlined sx={{ height: 18, width: 18 }} />
                )
              }
              color="primary"
              onDelete={() => {
                const newOne = actionBtn.filter((ii) => ii.text !== i?.text);
                setActionBtn(newOne);
              }}
              label={i?.text}
              key={key}
            />
          );
        })}
      </Stack>

      <Stack spacing={1} direction="row">
        <Chip
          onClick={() => setType("CALL")}
          variant={type === "CALL" ? "filled" : "outlined"}
          icon={<PhoneOutlined sx={{ height: 18, width: 18 }} />}
          label={data.callPhone}
        />
        <Chip
          onClick={() => setType("VISIT")}
          variant={type === "VISIT" ? "filled" : "outlined"}
          icon={<OpenInNewOutlined sx={{ height: 18, width: 18 }} />}
          label={data.visitWebsite}
        />
      </Stack>

      <Stack alignItems={"flex-start"} direction={"row"} spacing={1}>
        <Stack direction={"row"} spacing={1}>
          <TextFieldNew
            label={data.buttonTitle}
            value={states?.btnTitle}
            sx={{
              "& fieldset": { border: "none" },
            }}
            onChange={(e) => {
              const inputText = e.target.value.slice(0, 20);
              setStates({ ...states, btnTitle: inputText });
            }}
            helperText={`${states.btnTitle.length}/${data.allowed20}`}
            bgColor={"#f5f7fb"}
            fullWidth
          />
          {type === "VISIT" && (
            <TextFieldNew
              label={data.enterWebURL}
              value={states.actionUrl}
              sx={{
                "& fieldset": { border: "none" },
              }}
              onChange={(e) => {
                const inputText = e.target.value.slice(0, 2000);
                setStates({ ...states, actionUrl: inputText });
              }}
              helperText={`${states.actionUrl?.length}/${data.allowed2000}`}
              bgColor={"#f5f7fb"}
              fullWidth
              endIcon={
                actionBtn.length + quickReply?.length < 2 && (
                  <IconButton
                    onClick={() => {
                      const obj = {
                        type: "URL",
                        text: states?.btnTitle,
                        url: states.actionUrl,
                        example: [],
                      };
                      setActionBtn([...actionBtn, obj]);
                      setStates({ ...states, phoneNum: "", btnTitle: "" });
                    }}
                    disabled={
                      states.btnTitle && isValidUrl(states?.actionUrl)
                        ? false
                        : true
                    }
                  >
                    <AddOutlined />
                  </IconButton>
                )
              }
            />
          )}

          {type === "CALL" && (
            <TextFieldNew
              value={states.phoneNum}
              type="number"
              sx={{
                "& fieldset": { border: "none" },
              }}
              onChange={(e) => {
                const inputText = e.target.value.slice(0, 20);
                setStates({ ...states, phoneNum: inputText });
              }}
              helperText={`${states.phoneNum?.length}/${data.allowed20} | ${data.numberWithCountryCode}`}
              bgColor={"#f5f7fb"}
              fullWidth
              endIcon={
                actionBtn.length + quickReply?.length < 2 && (
                  <IconButton
                    onClick={() => {
                      const obj = {
                        type: "PHONE_NUMBER",
                        text: states?.btnTitle,
                        phone_number: states?.phoneNum,
                      };
                      setActionBtn([...actionBtn, obj]);
                      setStates({ ...states, phoneNum: "", btnTitle: "" });
                    }}
                    disabled={
                      states.btnTitle && states?.phoneNum ? false : true
                    }
                  >
                    <AddOutlined />
                  </IconButton>
                )
              }
            />
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default AddAction;
