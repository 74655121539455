import React from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import moment from "moment";
import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import { DeleteOutline } from "@mui/icons-material";

const ContactList = ({
  data,
  state,
  getContacts,
  setState,
  screenWidth,
  phoneBookData,
  hitAxios,
  contactData,
  getPhoneBooks,
}) => {
  const [selected, setSelected] = React.useState([]);

  function CustomToolbar() {
    console.log("data.contactList", data.contactList);
    return (
      <GridToolbarContainer>
        <Box width={"100%"}>
          <Grid
            container
            alignItems={"center"}
            justifyContent={"flex-end"}
            spacing={2}
          >
            <Grid item>
              <Stack spacing={2} direction={"row"} alignItems={"center"}>
                <Typography variant="h5">{data.contactList}</Typography>
                <GridToolbarExport />
              </Stack>
            </Grid>
            <Grid item>
              <Stack direction={"row"} alignItems={"center"}>
                {selected.length > 0 && (
                  <Typography color={"gray"} variant="caption">
                    ({selected.length})
                  </Typography>
                )}
                <IconButton
                  onClick={delContact}
                  color="error"
                  disabled={selected.length < 1 ? true : false}
                >
                  <DeleteOutline />
                </IconButton>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </GridToolbarContainer>
    );
  }

  async function delContact(e) {
    if (window.confirm(data.AUS)) {
      const res = await hitAxios({
        path: "/api/phonebook/del_contacts",
        admin: false,
        post: true,
        obj: { selected },
      });
      if (res.data.success) {
        getContacts();
      }
    }
  }

  const handleSelectionChange = (newSelection) => {
    setSelected(newSelection);
  };

  return (
    <Box mt={-3} pr={2} pl={2} pt={2}>
      {contactData ? (
        <Box borderRadius={2} bgcolor={"white"} minHeight="80vh">
          <DataGrid
            getRowId={(row) => row.id}
            rows={[...contactData].reverse()}
            columns={[
              { headerName: data.name, field: "name", flex: 1 },
              { headerName: data.phonebook, field: "phonebook_name", flex: 1 },
              { headerName: data.mobileNum, field: "mobile", flex: 1 },
              { headerName: data.var1, field: "var1", flex: 1 },
              { headerName: data.var2, field: "var2", flex: 1 },
              { headerName: data.var3, field: "var3", flex: 1 },
              { headerName: data.var4, field: "var4", flex: 1 },
              { headerName: data.var5, field: "var5", flex: 1 },
              {
                headerName: data.date,
                field: "createdAt",
                flex: 1,
                renderCell: (data) =>
                  moment(data.row.createdAt).format("DD MMMM YYYY"),
              },
            ]}
            onRowSelectionModelChange={handleSelectionChange}
            pageSize={50}
            rowsPerPageOptions={[50]}
            checkboxSelection={true}
            components={{
              Toolbar: CustomToolbar,
            }}
            sx={{
              boxShadow: 0,
              borderRadius: 4,
              border: 0,
              fontSize: 13,
              padding: 2,
            }}
          />
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
};

export default ContactList;
