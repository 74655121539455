import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import dateFormatter, { dateFormate } from "../../utils/DateFormatter";
// import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { Box, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { GlobalContext } from "../../context/GlobalContext";

const ChatsPerDay = ({ data, chatsPerDay }) => {
  const G = React.useContext(GlobalContext);
  const userData = JSON.parse(localStorage.getItem("myData"));
  const [date, setDate] = useState({ startDate: "", endDate: "" });
  const [chatState, setChartState] = useState({
    option: {
      chart: {
        id: "basic-line-chart",
      },
      xaxis: {
        categories: G.dashboardData["no-of-chat-session-each-day"]?.map((i) =>
          dateFormatter(i._id)
        ),
      },
      title: {
        text: "No of Sessions. (per day)",
        align: "left",
      },
      colors: ["#22a454"],
    },
    series: [
      {
        name: "Chats per day",
        data: G.dashboardData["no-of-chat-session-each-day"]?.map(
          (i) => i.count
        ),
      },
    ],
  });
  async function getData(startDate, endDate) {
    const res = await G.hitAxiosOnly({
      path:
        process.env.REACT_APP_BACKEND_BASE_URL +
        "/backend/analytics/" +
        userData?._id +
        `/no-of-chat-session-each-day/data?startDate=${startDate}&endDate=${endDate}`,
      post: false,
      admin: false,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_user"),
    });
    if (res.status === 200) {
      setChartState({
        options: {
          chart: {
            id: "basic-line-chart",
          },
          xaxis: {
            categories: res?.data?.data?.map((i) => dateFormatter(i._id)),
          },
          title: {
            text: "No of Sessions. (per day)",
            align: "left",
          },
          colors: ["#22a454"],
        },
        series: [
          {
            name: "Chats per day",
            data: res?.data?.data?.map((i) => i.count),
          },
        ],
      });
    }
  }
  useEffect(() => {
    setDate({
      startDate: dateFormate(
        new Date(new Date() - 1000 * 60 * 60 * 24 * parseInt(30))
      ),
      endDate: dateFormate(new Date()),
    });
    setChartState({
      option: {
        chart: {
          id: "basic-line-chart",
        },
        xaxis: {
          categories: G.dashboardData["no-of-chat-session-each-day"]?.map((i) =>
            dateFormatter(i._id)
          ),
        },
        title: {
          text: "No of Sessions. (per day)",
          align: "left",
        },
        colors: ["#22a454"],
      },
      series: [
        {
          name: "Chats per day",
          data: G.dashboardData["no-of-chat-session-each-day"]?.map(
            (i) => i.count
          ),
        },
      ],
    });
  }, [G.dashboardData["no-of-chat-session-each-day"]]);
  return (
    <Box height={"400px"}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box
          components={["DatePicker", "DatePicker"]}
          display={"flex"}
          justifyContent={"flex-end"}
          gap="5px"
        >
          <DatePicker
            label="Start Date"
            inputFormat="YYYY-MM-DD"
            renderInput={(props) => (
              <TextField
                size="small"
                InputProps={{ style: { borderRadius: 10 } }}
                {...props}
              />
            )}
            value={date.startDate}
            onChange={(newValue) => {
              setDate({ ...date, startDate: dateFormate(newValue.$d) });
              getData(dateFormate(newValue.$d), date.endDate);
            }}
          />
          <DatePicker
            label="End Date"
            inputFormat="YYYY-MM-DD"
            renderInput={(props) => (
              <TextField
                size="small"
                InputProps={{ style: { borderRadius: 10 } }}
                {...props}
              />
            )}
            value={date.endDate}
            onChange={(newValue) => {
              setDate({ ...date, endDate: dateFormate(newValue.$d) });
              getData(date.startDate, dateFormate(newValue.$d));
            }}
          />
        </Box>
      </LocalizationProvider>
      <Chart
        id="basic-line-chart"
        height={"100%"}
        // minHeight={"00px"}
        // width={"100%"}
        options={chatState.option}
        series={chatState.series}
        type="area"
      />
    </Box>
  );
};

export default ChatsPerDay;
