import { FacebookOutlined, ScheduleSend } from "@mui/icons-material";
import { Box, Chip, Stack } from "@mui/material";
import React from "react";
import WhatsappIntegration from "../channels/WhatsappIntegration";

const WhatsappIntegrationpage = ({ data }) => {
  return (
    <Box m={3} p={2} bgcolor={"white"} borderRadius={2}>
      <WhatsappIntegration />
    </Box>
  );
};

export default WhatsappIntegrationpage;
