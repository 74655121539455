import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import moment from "moment";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { GlobalContext } from "../../context/GlobalContext";
const ContactUsDetails = ({ data, userData }) => {
  const { hitAxios } = React.useContext(GlobalContext);
  const [contactData, setContactData] = useState([]);
  const [selected, setSelected] = React.useState([]);
  useEffect(() => {
    (async () => {
      const res = await hitAxios({
        path: "/api/contact_us/ger_data",
        post: true,
        admin: false,
        obj: { userId: userData._id },
      });
      if (res.data.success) {
        console.log("dsfsdf_asdas", res.data.data);
        setContactData(res.data.data);
      }
    })();
  }, []);
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Box width={"100%"}>
          <Grid
            container
            alignItems={"center"}
            justifyContent={"flex-end"}
            spacing={2}
          >
            <Grid item>
              <Stack spacing={2} direction={"row"} alignItems={"center"}>
                <Typography variant="h5">{data.contactList}</Typography>
                <GridToolbarExport />
              </Stack>
            </Grid>
            <Grid item>
              <Stack direction={"row"} alignItems={"center"}>
                {selected.length > 0 && (
                  <Typography color={"gray"} variant="caption">
                    ({selected.length})
                  </Typography>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </GridToolbarContainer>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box mt={2} borderRadius={2} bgcolor={"white"} p={2}>
        <Box mt={-3} pr={2} pl={2} pt={2}>
          {contactData ? (
            <Box borderRadius={2} bgcolor={"white"} minHeight="80vh">
              <DataGrid
                getRowId={(row) => row._id}
                rows={[...contactData].reverse()}
                columns={[
                  { headerName: "Full Name", field: "fullName", flex: 1 },
                  { headerName: "Email", field: "email", flex: 1 },
                  { headerName: "Msg", field: "msg", flex: 1 },
                  {
                    headerName: "Date",
                    field: "createdAt",
                    flex: 1,
                    renderCell: (data) =>
                      moment(data.row.createdAt).format("DD MMMM YYYY"),
                  },
                ]}
                pageSize={50}
                rowsPerPageOptions={[50]}
                components={{
                  Toolbar: CustomToolbar,
                }}
                sx={{
                  boxShadow: 0,
                  borderRadius: 4,
                  border: 0,
                  fontSize: 13,
                  padding: 2,
                }}
              />
            </Box>
          ) : (
            ""
          )}
        </Box>
      </Box>
    </Container>
  );
};
export default ContactUsDetails;
