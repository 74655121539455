import React, { useContext, useState, useEffect, useRef } from "react";
import { InboxContext } from "../../context/InboxContext";
import { TranslateContext } from "../../context/TranslateContext";
import { GlobalContext } from "../../context/GlobalContext";
import ChatWindow from "./ChatWindow";
import {
  Box,
  Button,
  CardMedia,
  Container,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import io from "socket.io-client";
import Conversation from "./components/conversations/Conversation";
import { Menu, MenuBookOutlined } from "@mui/icons-material";

function addReactionToMessages(messages, reactionEmoji, metaChatId) {
  return messages.map((message) => {
    // Check if the message has the same metaChatId
    if (message.metaChatId === metaChatId) {
      // Clone the message object to avoid mutating the original object
      const updatedMessage = { ...message };
      // Add or update the reaction property
      updatedMessage.reaction = reactionEmoji;
      return updatedMessage;
    }
    return message; // Return the message as it is if metaChatId doesn't match
  });
}

function updateArrayObject(array, key, value, metaChatId) {
  return array.map((item) => {
    return item.map((el) => {
      if (el.metaChatId === metaChatId) {
        return { ...el, [key]: value };
      }
      return el;
    });
  });
}
const socket = io(process.env.REACT_APP_BASE_URL, {
  reconnection: true, // Enable reconnections (default is true)
  reconnectionAttempts: Infinity, // Unlimited attempts (default is Infinity)
  reconnectionDelay: 1000, // Initial delay before reconnecting (in milliseconds)
  reconnectionDelayMax: 5000, // Maximum delay between reconnections (in milliseconds)
  timeout: 20000, // Connection timeout (in milliseconds)
});
const Inbox = () => {
  const [ranOnce, setRanOnce] = React.useState(false);
  const INBOX = useContext(InboxContext);
  const { chatList, setChatList } = useContext(InboxContext);
  const { data } = useContext(TranslateContext);
  const CON = useContext(GlobalContext);
  const [state, setState] = useState({ drawer: false });
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const audioRef = useRef(null);
  const myData = JSON.parse(localStorage.getItem("myData"));
  //const socket = useRef(io(process.env.REACT_APP_BASE_URL));

  const DRAWER_WIDTH = 799;

  function getChat() {
    return new Promise((resolve, reject) => {
      try {
        const getLocal = localStorage.getItem("chat");
        const chatData = getLocal ? JSON.parse(getLocal) : [];
        INBOX.setChatConvo(chatData);
        resolve(chatData);
      } catch (error) {
        reject(error); // Reject the promise if an error occurs
      }
    });
  }

  function updateChat(data, id) {
    return new Promise((resolve, reject) => {
      try {
        INBOX.setChatConvo(data);
        localStorage.setItem("open_chatId", id);
        localStorage.setItem("chat", JSON.stringify(data));
        resolve(); // Resolve the promise after successfully updating the data
      } catch (error) {
        reject(error); // Reject the promise if an error occurs
      }
    });
  }

  useEffect(() => {
    const updateScreenWidth = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", updateScreenWidth);
    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);

  useEffect(() => {
    console.log("inboxnce");
    socket.on("connect", () => {
      console.log("Connected to the Socket.IO server.");

      socket.emit("user_connected", {
        userId: CON.getMyDetails?.uid,
        userType: myData?.userType,
        userUniqueId: myData?._id,
        userName: myData?.firstName,
        userEmail: myData?.email,
      });
    });
    socket.on("update_chats", ({ chats }) => {
      // console.log("okk", chats);
      // if (chats) {
      //   setChatList(chats);
      //   const foundOpened = chats.find(
      //     (chat) => chat.chat_id === INBOX.data.openedChat?.chat_id
      //   );
      //   INBOX.setData({ ...INBOX.data, openedChat: foundOpened });
      // }
    });

    socket.on("push_new_msg", async ({ msg, chatId }) => {
      let openChatId = localStorage.getItem("open_chatId");
      if (msg && chatId && chatId === openChatId) {
        const chats = await getChat();
        chats[chats.length - 1] = [...chats[chats.length - 1], msg];
        await updateChat(chats, chatId);
        // setChatConvo(prevConvo => [...prevConvo, msg]);
      }
    });

    socket.on("update_delivery_status", async ({ chatId, status, msgId }) => {
      if (chatId && chatId === INBOX.data.openedChat?.chat_id) {
        const chats = await getChat();
        const updateStatus = updateArrayObject(chats, "status", status, msgId);
        await updateChat(updateStatus, chatId);
      }
    });

    socket.on("push_new_reaction", async ({ reaction, chatId, msgId }) => {
      if (chatId && chatId === INBOX.data.openedChat?.chat_id) {
        if (audioRef.current) {
          audioRef?.current?.play();
        }
        const chats = await getChat();
        const updateStatus = updateArrayObject(
          chats,
          "reaction",
          reaction,
          msgId
        );
        await updateChat(updateStatus, chatId);
      }
    });

    socket.on("update_conversations", ({ chats, notificationOff }) => {
      if (Array.isArray(chats)) {
        setChatList(chats);
      }
    });
    socket.on("push_new_notification", ({ msg, Segment }) => {
      console.log(" msg, Segment ", msg, Segment);
    });

    return () => {
      // Clean up socket listeners
      socket.off("update_chats");
      socket.off("push_new_msg");
      socket.off("update_delivery_status");
      socket.off("push_new_reaction");
      socket.off("update_conversations");
    };
  }, []);

  useEffect(() => {
    console.log("ranOnce", ranOnce);
    if (ranOnce) return;
    socket.emit("user_connected", {
      userId: CON.getMyDetails?.uid,
      userType: myData?.userType,
      userUniqueId: myData?._id,
      userName: myData?.firstName,
      userEmail: myData?.email,
    });
    getChatList();
    setRanOnce(true);
  }, []);

  async function getChatList() {
    try {
      const res = await CON.hitAxios({
        path: "/api/inbox/get_chats",
        post: false,
        admin: false,
        token_user: localStorage.getItem(`${process.env.REACT_APP_TOKEN}_user`),
      });
      if (res.data.success) {
        setChatList(res.data.data);
      }
    } catch (error) {
      console.error("Error fetching chat list:", error);
    }
  }

  async function getChatConvo(chat) {
    try {
      const res = await CON.hitAxios({
        path: "/api/inbox/get_convo",
        post: true,
        admin: false,
        obj: {
          chatId: chat?.chat_id,
          unqiueBrowserId: chat.unqiueBrowserId,
          uid: chat?.uid,
        },
      });
      if (res.data.success) {
        // setChatConvo(res.data.data);
        await updateChat(res.data.data, chat?.chat_id);
        INBOX.setIsScroll(true);
        INBOX.setData({ ...INBOX.data, openedChat: chat });
      }
    } catch (error) {
      console.error("Error fetching chat conversation:", error);
    }
  }

  return (
    <div>
      <audio ref={audioRef} src="/notification/interface-124464.mp3" />
      <Stack direction={"row"}>
        <ChatWindow
          // socket={socket.current}
          getChatConvo={getChatConvo}
          chatList={chatList}
          state={state}
          setState={setState}
          data={data}
          hitAxios={CON.hitAxios}
          DRAWER_WIDTH={DRAWER_WIDTH}
          CON={CON}
          INBOX={INBOX}
          // setChatConvo={setChatConvo}
          chatConvo={INBOX.chatConvo}
        />
        {INBOX.chatConvo.length > 0 ? (
          <Conversation
            socket={socket}
            screenWidth={screenWidth}
            chatList={chatList}
            state={state}
            setState={setState}
            data={data}
            hitAxios={CON.hitAxios}
            DRAWER_WIDTH={DRAWER_WIDTH}
            CON={CON}
            INBOX={INBOX}
            setChatList={setChatList}
            getChatList={getChatList}
          />
        ) : (
          <Grid
            spacing={2}
            height={"80vh"}
            alignItems={"center"}
            justifyContent={"center"}
            container
          >
            <Grid item>
              <Container maxWidth="sm">
                <Box p={2}>
                  <Stack alignItems={"center"} direction={"column"} spacing={4}>
                    <CardMedia
                      component={"img"}
                      src="/assets/no-chat-found.svg"
                    />

                    {screenWidth < DRAWER_WIDTH && chatList?.length > 0 && (
                      <Button
                        onClick={() =>
                          setState({ ...state, drawer: !state.drawer })
                        }
                        sx={{ boxShadow: 0, borderRadius: 4 }}
                        variant="contained"
                        startIcon={<MenuBookOutlined />}
                      >
                        {data.openChatList}
                      </Button>
                    )}

                    <Typography align="center" fontWeight={600} variant="h6">
                      {chatList?.length > 0 && data.pleaseClickToStartChat}
                      {chatList.length < 1 && data.notChatListFound}
                    </Typography>
                  </Stack>
                </Box>
              </Container>
            </Grid>
          </Grid>
        )}
      </Stack>
    </div>
  );
};

export default Inbox;
