import { Field, RuleType } from "react-querybuilder";
import { defaultOperators, toFullOption } from "react-querybuilder";
import { musicalInstruments } from "./musicalInstruments";
import { IndianStates, Orderdate, PaymentMode } from "./customoptions";
// console.log("defaultOperators", defaultOperators);
export const fields = [
  {
    name: "data.total_price",
    label: "Order Value",
    placeholder: "1000",
    operators: [
      { label: "Equals to", name: "=", value: "=" },
      { label: "Less than Equals to", name: "<=", value: "<=" },
      { label: "Greater than Equals to", name: ">=", value: ">=" },
      { label: "Greater than ", name: ">", value: ">" },
      { label: "Less than ", name: "<", value: "<" },
    ],
    inputType: "number",
  },
  {
    name: "data.shipping_address.province",
    label: "State",
    placeholder: "Uttar Pradesh",
    operators: defaultOperators.filter((op) => op.name === "in"),
    valueEditorType: "select",
    defaultValue: "Uttar Pradesh",
    values: IndianStates,
    valueEditorType: "multiselect",
  },
  {
    name: "data.created_at",
    label: "Order Date",
    values: Orderdate,
    valueEditorType: "select",
    operators: [
      { label: "Less than", name: "<", value: "<" },
      { label: "Greater than ", name: ">", value: ">" },
      { label: "between", name: "between", value: "between" },
      { label: "Less than Equals to", name: "<=", value: "<=" },
      { label: "Greater than Equals to", name: ">=", value: ">=" },
    ],
  },
  {
    name: "data.paymentmode",
    label: "Payment Mode",
    operators: [{ label: "Equals to", name: "=", value: "=" }],
    valueEditorType: "select",
    defaultValue: "COD",
    values: PaymentMode,
  },
  {
    name: "data.gender",
    label: "Gender",
    operators: defaultOperators.filter((op) => op.name === "="),
    valueEditorType: "radio",
    values: [
      { name: "M", label: "Male" },
      { name: "F", label: "Female" },
      { name: "O", label: "Other" },
    ],
  },
  {
    name: "data.line_items",
    label: "Prodcut Name",
    placeholder: "Enter last name",
    operators: [{ label: "Equals to", name: "=", value: "=" }],
  },
  {
    name: "puchase_sequence",
    label: "Purchase Sequence",
    placeholder: "5",
    operators: [
      { label: "Equals to", name: "=", value: "=" },
      { label: "Less than Equals to", name: "<=", value: "<=" },
      { label: "Greater than Equals to", name: ">=", value: ">=" },
      { label: "Greater than ", name: ">", value: ">" },
      { label: "Less than ", name: "<", value: "<" },
    ],
    inputType: "number",
  },
  {
    name: "data.order_number",
    label: "Order Number",
    operators: [{ label: "Equals to", name: "=", value: "=" }],
    placeholder: "1056",
    inputType: "number",
  },
  // { name: "birthdate", label: "Birth Date", inputType: "date" },

  // { name: "age", label: "Age", inputType: "number" },
  // {
  //   name: "isMusician",
  //   label: "Is a musician",
  //   valueEditorType: "checkbox",
  //   operators: defaultOperators.filter((op) => op.name === "="),
  //   defaultValue: false,
  // },
  // {
  //   name: "instrument",
  //   label: "Primary instrument",
  //   valueEditorType: "select",
  //   values: IndianStates,
  //   defaultValue: "Cowbell",
  //   operators: defaultOperators.filter((op) => op.name === "="),
  // },
  // {
  //   name: "alsoPlays",
  //   label: "Also plays",
  //   valueEditorType: "multiselect",
  //   values: musicalInstruments,
  //   defaultValue: "More cowbell",
  //   operators: defaultOperators.filter((op) => op.name === "in"),
  // },
  // {
  //   name: "gender",
  //   label: "Gender",
  //   operators: defaultOperators.filter((op) => op.name === "="),
  //   valueEditorType: "radio",
  //   values: [
  //     { name: "M", label: "Male" },
  //     { name: "F", label: "Female" },
  //     { name: "O", label: "Other" },
  //   ],
  // },
  // { name: "height", label: "Height" },
  // { name: "job", label: "Job" },
  // { name: "description", label: "Description", valueEditorType: "textarea" },
  // { name: "birthdate", label: "Birth Date", inputType: "date" },
  // { name: "datetime", label: "Show Time", inputType: "datetime-local" },
  // { name: "alarm", label: "Daily Alarm", inputType: "time" },
  // {
  //   name: "groupedField1",
  //   label: "Grouped Field 1",
  //   comparator: "groupNumber",
  //   groupNumber: "group1",
  //   valueSources: ["field", "value"],
  // },
  // {
  //   name: "groupedField2",
  //   label: "Grouped Field 2",
  //   comparator: "groupNumber",
  //   groupNumber: "group1",
  //   valueSources: ["field", "value"],
  // },
  // {
  //   name: "groupedField3",
  //   label: "Grouped Field 3",
  //   comparator: "groupNumber",
  //   groupNumber: "group1",
  //   valueSources: ["field", "value"],
  // },
  // {
  //   name: "groupedField4",
  //   label: "Grouped Field 4",
  //   comparator: "groupNumber",
  //   groupNumber: "group1",
  //   valueSources: ["field", "value"],
  // },
].map((o) => toFullOption(o));
